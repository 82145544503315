import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { PlayerService } from 'src/app/services/player.service';
import { BaseService } from 'src/app/services/base.service';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';

import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-deposit-modal',
  templateUrl: './deposit-modal.component.html',
  styleUrls: ['./deposit-modal.component.scss'],
})
export class DepositModalComponent implements OnInit {
  public faCopy = faCopy;
  public faTimes = faTimes;

  public hermesBankList: any;

  constructor(
    public baseServ: BaseService,
    public playerServ: PlayerService,
    public alertServ: AlertService,
    public generalServ: GeneralService,
    public router: Router,
    public bsRef: BsModalRef,
  ) {}

  async ngOnInit() {
    if (this.baseServ.isLogin) {
      await this.hermesDepositAccDeposit();
    } else {
      this.router.navigateByUrl('login');
    }
  }

  // start hermes deposit
  async hermesDepositAccDeposit() {
    const res = await this.generalServ.HermesDepositAccAsync();
    if (this.baseServ.checkTokenResponseStatus(res)) {
      this.hermesBankList = res.content;
    } else {
      this.alertServ.showAlertMsg('msg.' + res.msg);
    }
  }
  // stop  hermes deposit

  handleCopy() {
    this.alertServ.showAlertMsg('label.copyBankNO');
  }
}
