import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseService } from 'src/app/services/base.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-profile-modal.component',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit {
  public faTimes = faTimes;

  constructor(public bsRef: BsModalRef, public baseServ: BaseService) {}

  ngOnInit() {}
}
