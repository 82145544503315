import { Component, Input, OnInit } from '@angular/core';
import { PaymentGateway } from 'src/app/model/response.v3';

@Component({
  selector: 'app-pg-withdraw',
  templateUrl: './pg-withdraw.component.html',
  styleUrls: ['./pg-withdraw.component.scss']
})
export class PgWithdrawComponent implements OnInit {
  @Input() paymentGatewayDetail: PaymentGateway | undefined = undefined;

  constructor() { }

  ngOnInit() {
  }

}
