export enum HttpHeaderConfig {
  FORM_POST_HEADER = 'application/x-www-form-urlencoded',
  JSON_POST_HEADER = 'application/json',
  TEXT_PLAIN_HEADER = ''
}

export enum StorageKey {
  // Local key
  IP = 'ip',
  App_Basic_Config = 'appBasicConfig',
  // css
  Theme = 'theme',
  // session key
  USER_INFO = 'userInfo',
  WALLET_INFO = 'walletInfo',
  BANNER_CHECKER = 'banner_checker',
}

export enum StorageType {
  LOCAL_STORAGE = 'Local',
  SESSION_STORAGE = 'Session',
}

export enum Devices {
  Mobile,
  Web,
  IphoneWeb,
  Browser,
}
export enum DevicesPlatform {
  IOS = 'ios',
  Android = 'android',
  IOSWeb = 'iosWeb',
  Wechat = 'wechat',
  Browser = 'browser',
}

export enum ResponseCode {
  Normal = 200,
  Error = 500,
}

export enum i {
  d = 'qkun_demo_config_key',
}

export enum ResponseStatus {
  null = 0,
  success = 200,
  unAuthorize = 400,
  error = 500,
}

export enum RankingSetTypeEnum {
  AffliateRank,
  PercentTORank,
  GameCatTORank,
  GameTORank,
  LoseRebateRank,
  AffliateTORank,
  MarketingAffRank,
}

export enum CommissionStatusEnum {
  Regenerate = -2,
  Reject = -1,
  All = 0,
  Pending = 1,
  Handling = 2,
  Approve = 3,
  ReadySettle = 4,
  Payout = 5,
  Forward = 6,
  Setteled = 7,
  RunningApprove = 8,
  RunningAutoPay = 9,
}

export enum AffiliateTypeEnum {
  All = 0,
  WinLose = 1,
  TurnOver = 2,
  Member = 3,
  Marketing = 4,
  None = 99,
}

export enum UserTypeEnum {
  NewRegistration = 1,
  DuplicateAccount = 2,
  Blacklist = 3,
  ForbidWithdraw = 4,
  ForbidGame = 5,
  TestUser = 6,
  MarketingUser = 7,
}

export enum RegisterTabEnum {
  PhoneNumber = 1,
  BankDetail = 2,
  AccountAndPassword = 3,
}

export enum PlayerStatusEnum {
  normal = 1,
  freeze = -1,
  abNormal = -2,
}

export enum PaymentGatewayEnum {
  None = -1,
  Manual = 'MANUAL',
  Hermes = 19,
  PapayaPay = 24,
  MTPay = 25,
  CloudPay = 26,
  ThaiKingPay = 27,
  WaterPay = 28,
}

export enum ProposalTypeEnum {
  Unknow = -111,
  All = 0,
  Deposit = 111,
  Withdraw = 222,
  GameDeposit = 333,
  GameWithdraw = 444,
  CreditAdjustment = 555,
  Promotion = 666,
  ReturnLostCredit = 999,
}

export enum PromotionCategoryEnum {
  All = 0,
  Deposit = 1,
  Rebate = 2,
  LoseRebate = 3,
  FirstTimeRegister = 4,
  Sportbook = 5,
  SportDeposit = 6,
  MissionFreeCredit = 9,
  Other = 99,
  NoPromotion = -1,
}

export enum PromotionStatusEnum {
  Cancel = -1,
  All = 0,
  Pending = 1,
  Approve = 2,
  Complete = 3,
  Expired = 4,
  Reject = 5,
  Handling = 6,
  ReadyForSettle = 7,
  ApprovePayout = 8,
  PendingComplete = 9,
}

export enum GameCategoryEnum {
  Unknown = -2,
  All = -1,
  PromotionAllGame = 0,
  LiveCasino = 1,
  Slot = 2,
  SportBooks = 3,
  Lottery = 4,
  Esport = 6,
  Adult = 7,
  ChessGame = 8,
  CockFight = 9,
  Racing = 10,
  Mouse = 11,
  Fishing = 12,
  Others = 999,
}

export enum GameLanguageEnum {
  EN,
  CN,
  TW,
  VN,
  ID,
  TH,
  JP,
  KR,
  ES,
  PT,
  MA,
  KH,
  MY,
  LO,
  'en' = EN,
  'cn' = CN,
  'tw' = TW,
  'vn' = VN,
  'id' = ID,
  'th' = TH,
  'jp' = JP,
  'kr' = KH,
  'es' = ES,
  'pt' = PT,
  'ma' = MA,
  'kh' = KH,
  'my' = MY,
  'lo' = LO,
}

export enum PopupBannerContentTypeEnum {
  Normal = 0,
  About = 1,
  Terms = 2,
  Privacy = 3,
  BeforeLogin = 4,
  AfterLogin = 5,
  Transaction = 6,
  PromoPage = 7,
  WalletPage = 8,
  AffliatePage = 9,
  WithdrawPage = 10,
  GamePage = 11,
  CommissionPage = 12,
  Deposit = 13,
  Profile = 14,
  Article = 15,
  History_deposit = 16,
  History_withdraw = 17,
  History_promotion = 18,
  Referral_summary = 19,
  Contact_us = 20,
  Depo_BankToBank = 21,
  Others = 99,
}
