import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-size-filter',
  template: `
    <select (change)="onPageChange($event)" [value]="pageSize">
      <option
        *ngFor="let pageNum of pageSizeList"
        [value]="pageNum"
        [selected]="pageSize === pageNum"
      >
        {{ pageNum }} {{ 'label.row' | translate }}
      </option>
    </select>
  `,
  styles: [
    `
      select {
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--color-primary);
        color: black;
        outline: unset;
        cursor: pointer;
        box-shadow: 0 0 1px #0000000a, 0 4px 12px #00000014;
      }

      ::placeholder {
        color: var(--color-primary);
      }
    `,
  ],
})
export class PageSizeFilterComponent {
  @Input() pageSize = 10;
  @Output() pageSizeChange = new EventEmitter<number>();

  public readonly pageSizeList = [5, 10, 15, 20, 25, 30];

  onPageChange(selectedPageSize: any) {
    this.pageSizeChange.emit(Number(selectedPageSize.target.value));
  }
}
