import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';
import { PromotionStatusEnum, ResponseStatus } from 'src/app/model/enums';
import {
  ManualWithdrawRequest,
  PromotionSubscribeListRequest,
} from 'src/app/model/request.v3';
import { PromotionSubscribeList } from 'src/app/model/response.v3';
import { AlertService } from 'src/app/services/alert.service';
import { BaseService } from 'src/app/services/base.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PlayerService } from 'src/app/services/player.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { TableService } from 'src/app/services/table.service';
import { BindPlayerBankModalComponent } from '../../bind-player-bank-modal/bind-player-bank-modal.component';

@Component({
  selector: 'app-manual-withdraw',
  templateUrl: './manual-withdraw.component.html',
  styleUrls: ['./manual-withdraw.component.scss'],
})
export class ManualWithdrawComponent implements OnDestroy {
  @ViewChild('submitButton', { static: false })
  submitButton: ElementRef<HTMLButtonElement>;

  public isSubmit: boolean = false;
  public withdrawAmount: { paymentAmount: number | null } = {
    paymentAmount: null,
  };

  public isBindBank: boolean = false;
  public turnOverBetTarget: string;
  public turnOverClaimable: boolean;

  public minAmount = new BehaviorSubject<number>(0);
  public maxAmount = new BehaviorSubject<number>(0);
  private readonly defaultAmountTab = [20, 50, 100, 300, 500];
  public amountTabList$ = combineLatest([this.minAmount]).pipe(
    map(([minAmount]) =>
      this.defaultAmountTab.map((amount, index) => {
        if (index === 0) return minAmount > amount ? minAmount : amount;

        return minAmount + amount;
      }),
    ),
  );

  public unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _paymentService: PaymentService,
    private readonly _baseService: BaseService,
    private readonly _playerService: PlayerService,
    private readonly _modalService: BsModalService,
    private readonly _alertService: AlertService,
    private readonly _promotionService: PromotionService,
    private readonly _router: Router,
    private readonly _tableServ: TableService<
      PromotionSubscribeListRequest,
      PromotionSubscribeList
    >,
  ) {
    this.isBindBank = _baseService.playerInfo.bankAccountNo ? true : false;

    // set min and max withdrawal
    const playerInfo = _baseService.playerInfo;
    this.minAmount.next(playerInfo.withdrawalMinimum);
    this.maxAmount.next(playerInfo.withdrawalMaximum);

    this._tableServ.initialize({
      StartDate: new Date(
        new Date().setDate(new Date().getDate() - 30),
      ).toDateString(),
      EndDate: new Date().toDateString(),
      PromotionStatus: PromotionStatusEnum.All,
      PageNum: 1,
      PageSize: 25,
      Ip: '',
      TimeSpan: 0,
      Signature: '',
    });

    this._tableServ
      .getList(
        this._promotionService.getPromotionSubscribeList.bind(
          _promotionService,
        ),
      )
      .pipe(
        tap(() => (this._baseService.isLoading = true)),
        map((response) => response.CurrentTurnOver),
        finalize(() => (this._baseService.isLoading = false)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((turnOverDetail) => {
        if (!turnOverDetail) {
          this.turnOverBetTarget = '0/0';
          this.turnOverClaimable = false;
        }

        this.turnOverBetTarget = `${turnOverDetail.CurrentTurnOver || 0}/${
          turnOverDetail.TargetTurnOver || 0
        }`;
        this.turnOverClaimable =
          turnOverDetail.CurrentTurnOver >= turnOverDetail.TargetTurnOver;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get balance() {
    return this._baseService.playerInfo.amount || 0;
  }
  get bankCode() {
    return this._baseService.playerInfo.bankCode;
  }
  get accountNo() {
    return this._baseService.playerInfo.bankAccountNo;
  }
  get accountName() {
    return this._baseService.playerInfo.accountName;
  }

  public openBindBankModal() {
    const modalRef: BsModalRef = this._modalService.show(
      BindPlayerBankModalComponent,
      {
        ignoreBackdropClick: true,
        class: 'modal-bind-bank modal-dialog-centered',
      },
    );

    // determine if bank is bind or not
    modalRef.content.onClose.subscribe((result) => {
      this.isBindBank = result;
    });
  }

  public onClickQuickAmount(amount: number) {
    this.withdrawAmount.paymentAmount = amount;
  }

  public onSubmit(form: NgForm) {
    if (!this.turnOverClaimable) {
      this._alertService.showAlertMsg('msg.GetTurnOverFailed');
      return;
    }

    this.isSubmit = true;
    if (form.valid) {
      if (this.submitButton) {
        this.submitButton!.nativeElement.disabled = true;
      }

      const request: ManualWithdrawRequest = {
        Amount: this.withdrawAmount.paymentAmount.toString(),
        SumRateAmount: this.withdrawAmount.paymentAmount.toString(),
        Currency: 'THB',
        Rate: '1.0',
        Remark: '',
        ThirdPartyPaymentType: 1,
        TransPassword: '123456',
        UploadFile: '',
      };

      if (
        this._baseService.playerInfo.amount < this.withdrawAmount.paymentAmount
      ) {
        this._alertService.showAlertMsg('msg.ErrorAmount');
      } else {
        this._paymentService
          .SubmitManualWithdraw(request)
          .pipe(
            tap(() => (this._baseService.isLoading = true)),
            finalize(() => {
              if (this.submitButton) {
                this.submitButton!.nativeElement.disabled = true;
              }
              this._baseService.isLoading = false;
            }),
          )
          .subscribe((res) => {
            if (res.Status === ResponseStatus.success) {
              this._router.navigateByUrl('transaction').then(() => {
                this.getPlayerDetail();
                this._alertService.showAlertMsg('msg.WithdrawSuccess');
              });
            } else {
              this._baseService.isLoading = false;
              this._alertService.showAlertMsgCenter(`msg.${res.Msg}`);
            }
          });
      }

      this.isSubmit = false;
      if (this.submitButton) {
        this.submitButton!.nativeElement.disabled = false;
      }
    }
  }

  async getPlayerDetail() {
    const data = await this._playerService.getPlayerDetailAsync();
    if (this._baseService.checkTokenResponseStatus(data)) {
      this._baseService.setAllDetail(data);
    }
  }
}
