import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseService } from 'src/app/services/base.service';
import { Router } from '@angular/router';
import { SmsModel, SMSResetPlayerPasswordModel } from 'src/app/model/request';
import { PlayerService } from 'src/app/services/player.service';
import { AlertService } from 'src/app/services/alert.service';
import {
  faMobileAlt,
  faKey,
  faTimes,
  faLock,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-forgot-password-modal.component',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent implements OnInit {
  public faTimes = faTimes;
  public smsModel = new SmsModel();
  public forgotPassActive = false;
  public DomainUrl = window.location.origin;
  public countTime = 0;
  public resetPassModel = new SMSResetPlayerPasswordModel();

  public faMobileAlt = faMobileAlt;
  public faKey = faKey;
  public faLock = faLock;

  constructor(
    public bsRef: BsModalRef,
    public baseServ: BaseService,
    public router: Router,
    public playerServ: PlayerService,
    public alertServ: AlertService,
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.resetPassModel.PlayerName = localStorage.getItem('user');
      this.resetPassModel.OTPCode = localStorage.getItem('otp');

      localStorage.removeItem('user');
      localStorage.removeItem('otp');

      this.forgotPassActive = true;
    } else {
      this.bsRef.hide();
    }
  }

  async handleForgotPass() {
    this.baseServ.isLoading = true;

    this.smsModel.SMSType = 2;
    this.smsModel.CallBackLink = this.DomainUrl + '/reset-password';

    const response = await this.playerServ.CallSms(this.smsModel);

    if (this.baseServ.checkTokenResponseStatus(response)) {
      this.countTime = response.content.retry * 60;
      setInterval(() => {
        if (this.countTime > 0) {
          this.countTime = this.countTime - 1;
        } else {
          this.countTime = 0;
        }
      }, 1000);
      this.alertServ.showAlertMsg('msg.' + response.msg);
    } else {
      this.playerServ.baseServ.isLoading = false;
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }

    this.baseServ.isLoading = false;
  }

  async handleForgotPassVerify() {
    this.baseServ.isLoading = true;

    const response = await this.playerServ.SMSResetPlayerPassword(
      this.resetPassModel,
    );

    if (this.baseServ.checkTokenResponseStatus(response)) {
      this.alertServ.showAlertMsg('msg.' + response.msg);

      this.router.navigateByUrl('home');
      this.bsRef.hide();
    } else {
      this.playerServ.baseServ.isLoading = false;
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }

    this.baseServ.isLoading = false;
  }
}
