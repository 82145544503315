import {
  Component,
  ComponentFactoryResolver,
  HostListener,
  Inject,
  ViewChild,
  ViewContainerRef,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { BaseService } from './services/base.service';
import { DevicesPlatform, Devices } from './model/enums';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { splitAtColon } from '@angular/compiler/src/util';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { PlayerService } from './services/player.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('navbar', { read: ViewContainerRef, static: true })
  navbarContainerRef: ViewContainerRef;
  @ViewChild('footer', { read: ViewContainerRef, static: true })
  footerContainerRef: ViewContainerRef;
  navbarFactory: any;
  footerFactory: any;

  constructor(
    public baseServ: BaseService,
    public playerServ: PlayerService,
    private router: Router,
    private route: ActivatedRoute,
    private cfr: ComponentFactoryResolver,
    // tslint:disable-next-line: variable-name
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // console.log(event);
        window.scrollTo(0, 0);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const device = this.baseServ.checkDeviceType();
    if (
      window.outerWidth < 1025 &&
      this.baseServ.currentSiteConfig.previousWidth > 1025
    ) {
      this.baseServ.currentSiteConfig.previousWidth = window.outerWidth;
      if (device.platform === DevicesPlatform.IOSWeb) {
        this.baseServ.currentSiteConfig.deviceType = Devices.IphoneWeb;
      } else {
        this.baseServ.currentSiteConfig.deviceType = Devices.Mobile;
      }
      this.baseServ.currentSiteConfig.switchDevice = true;
    } else if (
      this.baseServ.currentSiteConfig.previousWidth < 1025 &&
      window.outerWidth > 1025
    ) {
      this.baseServ.currentSiteConfig.previousWidth = window.outerWidth;
      if (device.platform === DevicesPlatform.IOSWeb) {
        this.baseServ.currentSiteConfig.deviceType = Devices.IphoneWeb;
      } else {
        this.baseServ.currentSiteConfig.deviceType = Devices.Web;
      }
      this.baseServ.currentSiteConfig.switchDevice = true;
    }

    if (this.baseServ.currentSiteConfig.switchDevice) {
    }
  }

  initalPageLayout() {
    this.navbarContainerRef.clear();
    this.footerContainerRef.clear();

    this.navbarFactory = this.cfr.resolveComponentFactory(NavbarComponent);
    this.navbarContainerRef.createComponent(this.navbarFactory);
    this.footerFactory = this.cfr.resolveComponentFactory(FooterComponent);
    this.footerContainerRef.createComponent(this.footerFactory);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  async ngOnInit() {
    this.baseServ.isLoading = true;

    await this.baseServ.onInit();
    await this.initalPageLayout();

    if (this.baseServ.isLogin) {
      await this.getPlayerDetail();
    }

    if (localStorage.getItem('loadingUrl') === 'loading') {
      this.router.navigateByUrl('loading');
      localStorage.removeItem('loadingUrl');
    }

    this.baseServ.isLoading = false;
  }

  async getPlayerDetail() {
    const data = await this.playerServ.getPlayerDetailAsync();
    if (this.playerServ.baseServ.checkTokenResponseStatus(data)) {
      this.playerServ.baseServ.setAllDetail(data);
    }
  }

  defineFontStyle() {
    switch (this.baseServ.currentSiteConfig.languange.value) {
      case 'cn':
        this._document.getElementsByTagName('body')[0].style.fontFamily =
          'roboto-regular';
        break;
      case 'en':
        this._document.getElementsByTagName('body')[0].style.fontFamily =
          'roboto-regular';
        break;
    }

    const body = document.getElementsByTagName('html')[0];
    body.setAttribute('lang', this.baseServ.currentSiteConfig.languange.value);
  }
}

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[preventCutCopyPaste]' })
export class CopyDirective {
  constructor(el: ElementRef, renderer: Renderer2) {
    const events = 'cut copy paste';
    events.split(' ').forEach((e) =>
      renderer.listen(el.nativeElement, e, (event) => {
        event.preventDefault();
      }),
    );
  }
}
