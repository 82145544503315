import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-game-modal',
  templateUrl: './game-modal.component.html',
  styleUrls: ['./game-modal.component.scss'],
})
export class GameModalComponent implements OnInit {
  @Input() gameName;
  @Input() gamelink;
  content: any;

  gameMessage = 'msg.GameMsg';

  constructor(public bsRef: BsModalRef) {}

  async ngOnInit() {
    this.content = {
      gameName: this.gameName,
      gamelink: this.gamelink,
    };
  }
}
