import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'register/:affiliate',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'reset-password/:user/:otp',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'member-center',
    loadChildren: () =>
      import('./pages/member-center/member-center.module').then(
        (m) => m.MemberCenterModule,
      ),
  },
  {
    path: 'affiliate',
    loadChildren: () =>
      import('./pages/affiliate/affiliate.module').then(
        (m) => m.AffiliateModule,
      ),
  },
  {
    path: 'lose-rebate',
    loadChildren: () =>
      import('./pages/lose-rebate/lose-rebate.module').then(
        (m) => m.LoseRebateModule,
      ),
  },
  {
    path: 'promotion',
    loadChildren: () =>
      import('./pages/promotion/promotion.module').then(
        (m) => m.PromotionModule,
      ),
  },
  {
    path: 'history',
    loadChildren: () =>
      import('./pages/history/history.module').then((m) => m.HistoryModule),
  },
  {
    path: 'transaction',
    loadChildren: () =>
      import('./pages/transaction/transaction.module').then(
        (m) => m.TransactionModule,
      ),
  },
  {
    path: 'loading',
    loadChildren: () =>
      import('./pages/loading/loading.module').then((m) => m.LoadingModule),
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
