import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppConfig } from '../app-config';
import { defaultLanguage, IAppConfig } from '../app-config-model';
import { GameModalComponent } from '../components/game-modal/game-modal.component';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';
import { PostGameModalComponent } from '../components/post-game-modal/post-game-modal.component';
import {
  Devices,
  DevicesPlatform,
  HttpHeaderConfig,
  ResponseCode,
  StorageKey,
  StorageType,
} from '../model/enums';
import { JwtTokenModel, MainPageContentModel } from '../model/request';
import {
  CurrentSiteConfig,
  DeviceProperty,
  PlayerInfo,
  ResponseMessage,
} from '../model/response';
import { RequestApiLink } from '../model/static';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  playerInfo: PlayerInfo;
  currentSiteConfig: CurrentSiteConfig;
  appConfig: IAppConfig;
  isLoading = false;
  // tslint:disable-next-line: no-inferrable-types
  isLogin: boolean = false;

  public get timeSpan(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  public get getIp(): any {
    const tmpIp = ToolsService.tryParseJson(localStorage.getItem('ClientIp'));
    if (tmpIp) {
      return JSON.parse(localStorage.getItem('ClientIp'));
    } else {
      return null;
    }
  }

  constructor(
    public http: HttpClient,
    public translateServ: TranslateService,
    private router: Router,
    public modalServ: BsModalService,
  ) {}

  e(z: string): string[] {
    let j = [];
    switch (z) {
      case '0':
        j = [
          // dev
          // '6','8','n','X','R','5','z','E','j','G','V','n','t','R','l','z','E','m','=','C','D','8','A','p','c','u','T','x','b','7','n','A',

          //tko
          // 'n','H','R','Y','G','F','2','l','S','D','a','F','s','G','p','a','E','g','m','o','n','4','r','9','L','g','2','C','r','D','2','1',

          //prod
          'p',
          'y',
          'j',
          '4',
          'o',
          'U',
          'J',
          '6',
          'Z',
          'Q',
          'X',
          'z',
          'S',
          'u',
          '1',
          'r',
          'g',
          'e',
          'M',
          'P',
          '2',
          'B',
          'r',
          'e',
          'S',
          'k',
          'S',
          'e',
          'S',
          'D',
          'H',
          'B',
        ];
        break;
      case '1':
        j = [
          //dev
          // '@','j','U','M','s','6','5','1','d','3','C','v','Q','!','i','M',

          //tko
          // 'f','7','E','G','h','u','e','m','Y','5','U','x','a','D','x','X',

          //prod
          'C',
          '5',
          'J',
          't',
          'k',
          'y',
          'y',
          'm',
          'z',
          'f',
          'B',
          '2',
          'V',
          'g',
          'w',
          'x',
        ];
        break;
      case '2':
        j = ['x', 'x', 'x'];
        break;
      case '3':
        j = ['x', 'x', 'x'];
        break;
    }

    return j;
  }

  languagesTypeEnum(lang: string) {
    let result = 0;
    switch (lang) {
      case 'EN':
        result = 0;
        break;
      case 'CN':
        result = 1;
        break;
      case 'TW':
        result = 2;
        break;
      case 'VN':
        result = 3;
        break;
      case 'ID':
        result = 4;
        break;
      case 'TH':
        result = 5;
        break;
      case 'JP':
        result = 6;
        break;
      case 'KR':
        result = 7;
        break;
      case 'ES':
        result = 8;
        break;
      case 'PT':
        result = 9;
        break;
      case 'MA':
        result = 10;
        break;
      case 'KH':
        result = 11;
        break;
      case 'MY':
        result = 12;
        break;
    }

    return result;
  }

  categoryTypeEnum(name: string) {
    let result = 0;
    switch (name) {
      case 'LiveCasino':
        result = 1;
        break;
      case 'Slot':
        result = 2;
        break;
      case 'SportBooks':
        result = 3;
        break;
      case 'Lottery':
        result = 4;
        break;
      case 'ESport':
        result = 6;
        break;
      case 'Adult':
        result = 7;
        break;
      case 'ChessGame':
        result = 8;
        break;
      case 'CockFight':
        result = 9;
        break;
      case 'Racing':
        result = 10;
        break;
      case 'Mouse':
        result = 11;
        break;
      case 'Fishing':
        result = 12;
        break;
    }

    return result;
  }

  GameTypeEnum(name: string) {
    let result = 0;
    switch (name) {
      case 'Habanero':
        result = 5;
        break;
      case 'QTech':
        result = 9;
        break;
      case 'Spadegaming':
        result = 10;
        break;
      case 'SportBooksWW':
        result = 13;
        break;
      case 'SportBooksWFTTHB':
        result = 14;
        break;
      case 'EbetTest':
        result = 17;
        break;
      case 'EbetMy':
        result = 18;
        break;
      case 'EbetId':
        result = 19;
        break;
      case 'EbetCn':
        result = 20;
        break;
      case 'EbetTh':
        result = 21;
        break;
      case 'EbetVn':
        result = 22;
        break;
      case 'EbetUs':
        result = 23;
        break;
      case 'SportBooksWWTh':
        result = 26;
        break;
      case 'SportBooksWWUs':
        result = 27;
        break;
      case 'SportBooksWWMy':
        result = 28;
        break;
      case 'SportBooksWWId':
        result = 29;
        break;
      case 'SportBooksWWVn':
        result = 30;
        break;
      case 'SportBooksWWCn':
        result = 31;
        break;
      case 'SportBooksSBO':
        result = 34;
        break;
      case 'Joker':
        result = 35;
        break;
      case 'SportBooksCMD':
        result = 36;
        break;
      case 'SAGaming':
        result = 37;
        break;
      case 'Chess761RMB':
        result = 39;
        break;
      case 'SportBooksWFTMYR':
        result = 40;
        break;
      case 'SportBooksWFTIDR':
        result = 41;
        break;
      case 'SportBooksWFTVND':
        result = 42;
        break;
      case 'SportBooksWFTRMB':
        result = 43;
        break;
      case 'JokerMYR':
        result = 44;
        break;
      case 'JokerCNY':
        result = 45;
        break;
      case 'VirtualSportsSBO':
        result = 46;
        break;
      case 'AllBetTHB':
        result = 48;
        break;
      case 'AllBetCNY':
        result = 49;
        break;
      case 'AllBetMYR':
        result = 50;
        break;
      case 'AllBetVND':
        result = 51;
        break;
      case 'AllBetIDR':
        result = 52;
        break;
      case 'AllBetUSD':
        result = 53;
        break;
      case 'CasinoSBO':
        result = 54;
        break;
      case 'SportBooksSBOSeamless':
        result = 55;
        break;
      case 'CockFightTHB':
        result = 56;
        break;
      case 'CockFightMYR':
        result = 57;
        break;
      case 'CockFightIDN':
        result = 58;
        break;
      case 'HorseRacingTHB':
        result = 59;
        break;
      case 'HorseRacingMYR':
        result = 60;
        break;
      case 'HorseRacingIDN':
        result = 61;
        break;
      case 'VirtualSportsSBOSeamless':
        result = 62;
        break;
      case 'CasinoSBOSeamless':
        result = 63;
        break;
      case 'IdnPokerVND':
        result = 64;
        break;
      case 'IdnPokerTHB':
        result = 65;
        break;
      case 'IdnPokerIDR':
        result = 66;
        break;
      case 'IdnPokerMYR':
        result = 67;
        break;
      case 'IdnPokerRMB':
        result = 68;
        break;
      case 'KyCard':
        result = 69;
        break;
      case 'Cq9CNY':
        result = 70;
        break;
      case 'PlayTechMYR':
        result = 72;
        break;
      case 'PlayTechIDR':
        result = 73;
        break;
      case 'PlayTechCNY':
        result = 74;
        break;
      case 'PlayTechTHB':
        result = 75;
        break;
      case 'PlayTechVND':
        result = 76;
        break;
      case 'JokerUSD':
        result = 77;
        break;
      case 'JokerIDR':
        result = 78;
        break;
      case 'MgMYR':
        result = 79;
        break;
      case 'MgCNY':
        result = 80;
        break;
      case 'MgUSD':
        result = 81;
        break;
      case 'MgIDR':
        result = 82;
        break;
      case 'MgVND':
        result = 83;
        break;
      case 'AgMYR':
        result = 84;
        break;
      case 'AgCNY':
        result = 85;
        break;
      case 'AgUSD':
        result = 86;
        break;
      case 'AgIDR':
        result = 87;
        break;
      case 'AgVND':
        result = 88;
        break;
      case 'AgTHB':
        result = 89;
        break;
      case 'CockFightVND':
        result = 90;
        break;
      case 'Cq9SlotMYR':
        result = 91;
        break;
      case 'Cq9SlotIDR':
        result = 92;
        break;
      case 'Cq9SlotVND':
        result = 93;
        break;
      case 'Cq9SlotUSD':
        result = 94;
        break;
      case 'Cq9SlotTHB':
        result = 95;
        break;
      case 'AllBetNTD':
        result = 96;
        break;
      case 'IgCNY':
        result = 97;
        break;
      case 'VrCNY':
        result = 98;
        break;
      case 'WmCNY':
        result = 99;
        break;
      case 'MgTHB':
        result = 100;
        break;
      case 'SexyBaccaratTHB':
        result = 101;
        break;
      case 'SexyBaccaratCNY':
        result = 102;
        break;
      case 'SexyBaccaratIDR':
        result = 103;
        break;
      case 'SexyBaccaratVND':
        result = 104;
        break;
      case 'SexyBaccaratUSD':
        result = 105;
        break;
      case 'SexyBaccaratMYR':
        result = 106;
        break;
      case 'DreamGaming':
        result = 107;
        break;
      case 'LcCardCNY':
        result = 108;
        break;
      case 'LcCardTHB':
        result = 109;
        break;
      case 'LcCardMYR':
        result = 110;
        break;
      case 'LcCardIDR':
        result = 111;
        break;
      case 'LcCardVND':
        result = 112;
        break;
      case 'LcCardUSD':
        result = 113;
        break;
      case 'CockFightCNY':
        result = 114;
        break;
      case 'HorseRacingCNY':
        result = 115;
        break;
      case 'Rtg':
        result = 116;
        break;
      case 'WmMYR':
        result = 117;
        break;
      case 'WmTHB':
        result = 118;
        break;
      case 'WmIDR':
        result = 119;
        break;
      case 'WmVND':
        result = 120;
        break;
      case 'WmUSD':
        result = 121;
        break;
      case 'Clo888THB':
        result = 122;
        break;
      case 'WmTWD':
        result = 123;
        break;
      case 'AvSlotCNY':
        result = 124;
        break;
      case 'AvSlotTHB':
        result = 125;
        break;
      case 'AvSlotMYR':
        result = 126;
        break;
      case 'AvSlotUSD':
        result = 127;
        break;
      case 'AvSlotIDR':
        result = 128;
        break;
      case 'AvSlotVND':
        result = 129;
        break;
      case 'MikiTest':
        result = 130;
        break;
      case 'MikiTHB':
        result = 131;
        break;
      case 'MikiVND':
        result = 132;
        break;
      case 'MikiCNY':
        result = 133;
        break;
      case 'MikiIDR':
        result = 134;
        break;
      case 'MikiMYR':
        result = 135;
        break;
      case 'MikiUSD':
        result = 136;
        break;
      case 'IgSlotTHB':
        result = 141;
        break;
      case 'IgSlotMYR':
        result = 142;
        break;
      case 'IgSlotUSD':
        result = 143;
        break;
      case 'IgSlotVND':
        result = 144;
        break;
      case 'IgSlotCNY':
        result = 145;
        break;
      case 'IgSlotIDR':
        result = 146;
        break;
      case 'GsPlayTechTHB':
        result = 147;
        break;
      case 'GsPlayTechMYR':
        result = 148;
        break;
      case 'Cq9LiveCasinoMYR':
        result = 149;
        break;
      case 'Cq9LiveCasinoIDR':
        result = 150;
        break;
      case 'Cq9LiveCasinoVND':
        result = 151;
        break;
      case 'Cq9LiveCasinoUSD':
        result = 152;
        break;
      case 'Cq9LiveCasinoTHB':
        result = 153;
        break;
      case 'OneBookSportBooksTHB':
        result = 154;
        break;
      case 'GsPragmaticPlayMYR':
        result = 156;
        break;
      case 'GsPragmaticPlayTHB':
        result = 157;
        break;
      case 'GsMgSlotMYR':
        result = 158;
        break;
      case 'GsMgSlotTHB':
        result = 159;
        break;
      case 'GsMgLiveCasinoTHB':
        result = 160;
        break;
      case 'GsMgLiveCasinoMYR':
        result = 161;
        break;
      case 'GsVivoMYR':
        result = 162;
        break;
      case 'GsVivoTHB':
        result = 163;
        break;
      case 'Gs918KissTHB':
        result = 164;
        break;
      case 'Gs918KissMYR':
        result = 165;
        break;
      case 'GsAgSlotMYR':
        result = 166;
        break;
      case 'GsAgSlotTHB':
        result = 167;
        break;
      case 'GsAgLiveCasinoTHB':
        result = 168;
        break;
      case 'GsAgLiveCasinoMYR':
        result = 169;
        break;
      case 'GsPrettyGamingMYR':
        result = 170;
        break;
      case 'GsPrettyGamingTHB':
        result = 171;
        break;
      case 'OneBookSportBooksMYR':
        result = 172;
        break;
      case 'OneBookSportBooksIDR':
        result = 173;
        break;
      case 'OneBookSportBooksCNY':
        result = 174;
        break;
      case 'OneBookSportBooksVND':
        result = 175;
        break;
      case 'GsPgSlotTHB':
        result = 176;
        break;
      case 'GsGeLiveTHB':
        result = 177;
        break;
      case 'GsN2LiveTHB':
        result = 186;
        break;
      case 'PrettyGamingTHB':
        result = 188;
        break;
      case 'PragmaticPlayTHB':
        result = 191;
        break;
      case 'GsIaEsportTHB':
        result = 199;
        break;
      case 'GsEzugiTHB':
        result = 201;
        break;
      case 'GsQtechTHB':
        result = 203;
        break;
      case 'AgSlot':
        result = 205;
        break;
      case 'AgLiveCasino':
        result = 206;
        break;
      case 'SportRing99SportBooks':
        result = 207;
        break;
      case 'KingMaker':
        result = 208;
        break;
      case 'EvoTHB':
        result = 209;
        break;
      case 'GsRedTigerTHB':
        result = 224;
        break;
      case 'WorldMatchSBO':
        result = 226;
        break;
      case 'FunkyGamesSBO':
        result = 227;
        break;
      case 'GreenDragonSBO':
        result = 228;
        break;
      case 'FlowGamingSBO':
        result = 229;
        break;
      case 'YGG':
        result = 230;
        break;
      case 'Relax':
        result = 233;
        break;
      case 'BgTHB':
        result = 236;
        break;
      case 'IonTHB':
        result = 237;
        break;
      case 'FlowGamingNETENTSBO':
        result = 238;
        break;
      case 'FlowGamingKIRONSBO':
        result = 239;
        break;
      case 'FlowGamingPLAYNGOSBO':
        result = 240;
        break;
      case 'FlowGamingQUICKSPINSBO':
        result = 241;
        break;
      case 'GsSlotXOTHB':
        result = 244;
        break;
      case 'Gamatron':
        result = 245;
        break;
      case 'PgSoftTHB':
        result = 247;
        break;
      case 'JokerSingleTHB':
        result = 248;
        break;
      case 'PragmaticPlayLiveCasinoTHB':
        result = 251;
        break;
      case 'AdvantPlay':
        result = 254;
        break;
      case 'SimplePlay':
        result = 260;
        break;
      case 'CgTHB':
        result = 267;
        break;
      case 'DreamGamingSingle':
        result = 270;
        break;
      case 'IAESport':
        result = 271;
        break;
      case 'JILI':
        result = 274;
        break;
      case 'AmbSlotTHB':
        result = 286;
        break;
      case 'TcgLottoTHB':
        result = 296;
        break;
      case 'AFBSBO':
        result = 307;
        break;
      case 'AFBSlotSBO':
        result = 308;
        break;
      case 'MGPlusTHB':
        result = 321;
        break;
      case 'YeeBetTHB':
        result = 326;
        break;
      case 'MGPlusLiveCasinoTHB':
        result = 331;
        break;
      case 'BluePrintTHB':
        result = 336;
        break;
      case 'PgSoftNewTHB':
        result = 340;
        break;
      case 'XE88THB':
        result = 345;
        break;
      case 'LIVE22THB':
        result = 349;
        break;
      case 'RsgTHB':
        result = 352;
        break;
      case 'QMRedtigerTHB':
        result = 372;
        break;
      case 'JdbTHB':
        result = 374;
        break;
      case 'BngTHB':
        result = 378;
        break;
      case 'FachaiTHB':
        result = 383;
        break;
      case 'NlcTHB':
        result = 392;
        break;
      case 'AwcGsSexyBaccaratTHB':
        result = 412;
        break;
      case 'NextSpinTHB':
        result = 423;
        break;
      case 'TgpHistory':
        result = 10000;
        break;
    }

    return result;
  }

  async onInit() {
    this.appConfig = AppConfig.appSettings;

    if (location.hostname === 'localhost') {
      this.appConfig.apiDomain =
        'https:' + '//' + this.appConfig.apiDomain + '/';
    } else {
      this.appConfig.apiDomain =
        location.protocol + '//' + this.appConfig.apiDomain + '/';
    }
    this.currentSiteConfig = new CurrentSiteConfig();

    // tslint:disable-next-line: variable-name
    const tmpAppBasic_Config: CurrentSiteConfig = this.getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.App_Basic_Config,
    );
    if (tmpAppBasic_Config) {
      if (this.getIp === null) {
        await this.getClientIpApi();
      }
      // checkVersion
      console.log('local Storage has set');
      if (tmpAppBasic_Config.version !== this.appConfig.version) {
        this.setOrRemoveItemStorage(
          false,
          StorageType.LOCAL_STORAGE,
          StorageKey.App_Basic_Config,
        );
      } else {
        this.currentSiteConfig = tmpAppBasic_Config;
      }
      this.translateServ.setDefaultLang(this.currentSiteConfig.languange.value);
      this.currentSiteConfig.previousWidth = window.innerWidth;
      this.currentSiteConfig.switchDevice = false;
      const tmpResult = this.checkDeviceType();
      this.currentSiteConfig.deviceType = tmpResult.deviceType;
      this.currentSiteConfig.platform = tmpResult.platform;
      // this.currentSiteConfig.clientIp = await this.getClientIp();
      this.currentSiteConfig.clientIp = this.getIp;

      this.setPlayerInfoLogin();
      this.setOrRemoveItemStorage(
        true,
        StorageType.LOCAL_STORAGE,
        StorageKey.App_Basic_Config,
        this.currentSiteConfig,
      );
    } else {
      // first Init
      await this.getClientIpApi();
      // setDefaultLanguage;
      this.currentSiteConfig.languange = this.appConfig.languangeList[0];
      this.translateServ.setDefaultLang(this.currentSiteConfig.languange.value);

      const tmpResult = this.checkDeviceType();
      this.currentSiteConfig.deviceType = tmpResult.deviceType;
      this.currentSiteConfig.platform = tmpResult.platform;
      this.currentSiteConfig.version = this.appConfig.version;
      this.currentSiteConfig.previousWidth = window.innerWidth;
      // this.currentSiteConfig.clientIp = await this.getClientIp();
      this.currentSiteConfig.clientIp = this.getIp;
      this.setPlayerInfoLogin();

      // for setting the scss of template
      this.setOrRemoveItemStorage(
        true,
        StorageType.LOCAL_STORAGE,
        StorageKey.App_Basic_Config,
        this.currentSiteConfig,
      );
    }

    this.getContentDetailAsync('all');
  }

  user() {
    return this.getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );
  }

  setPlayerInfoLogin() {
    this.playerInfo = this.user();
    if (this.playerInfo) this.isLogin = true;
    else {
      this.isLogin = false;
      this.playerInfo = new PlayerInfo();
    }
    // tslint:disable-next-line: semicolon
  }

  checkDeviceType() {
    const tmpDeviceType = new DeviceProperty();

    const ua = navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(ua)) {
      tmpDeviceType.deviceType = Devices.Mobile;
      tmpDeviceType.platform = DevicesPlatform.IOS;
    } else if (/Android/i.test(ua)) {
      tmpDeviceType.deviceType = Devices.Mobile;
      tmpDeviceType.platform = DevicesPlatform.Android;
    } else if (/Macintosh/i.test(ua)) {
      tmpDeviceType.deviceType = Devices.IphoneWeb;
      tmpDeviceType.platform = DevicesPlatform.IOSWeb;
    } else if (/MicroMessenger/i.test(ua)) {
      tmpDeviceType.deviceType = Devices.Mobile;
      tmpDeviceType.platform = DevicesPlatform.Wechat;
    } else {
      tmpDeviceType.deviceType = Devices.Web;
      tmpDeviceType.platform = DevicesPlatform.Browser;
    }

    return tmpDeviceType;
  }

  setOrRemoveItemStorage(
    isSet: boolean,
    storageType: StorageType,
    storageKey: StorageKey,
    item?: any,
  ) {
    if (isSet) {
      const tmpDetail = JSON.stringify(item);
      switch (storageType) {
        case StorageType.LOCAL_STORAGE:
          localStorage.setItem(storageKey, tmpDetail);
          break;
        case StorageType.SESSION_STORAGE:
          sessionStorage.setItem(storageKey, tmpDetail);
          break;
      }
    } else {
      switch (storageType) {
        case StorageType.LOCAL_STORAGE:
          localStorage.removeItem(storageKey);
          break;
        case StorageType.SESSION_STORAGE:
          sessionStorage.removeItem(storageKey);
          break;
      }
    }
  }

  async RefreshJWTToken() {
    const tmpUserInfo: PlayerInfo = this.getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );

    if (tmpUserInfo) {
      const model: JwtTokenModel = new JwtTokenModel();
      model.PlayerId = tmpUserInfo.playerId;
      model.PlayerName = tmpUserInfo.playerName;
      model.TimeSpan = ToolsService.getTimeSpan();
      model.Ip = this.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          this.currentSiteConfig.clientIp.ip +
          model.PlayerName +
          model.PlayerId +
          this.e('1').join(''),
        this.e('0').join(''),
      );
      try {
        const response: ResponseMessage = await this.http
          .post<any>(
            this.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_RETRIEVE_JWT_TOKEN,
            model,
            {
              headers: this.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                false,
              ),
            },
          )
          .toPromise();
        if (this.checkTokenResponseStatus(response)) {
          tmpUserInfo.authToken = response.content.authToken;
          tmpUserInfo.getTokenTime = new Date();
          this.setOrRemoveItemStorage(
            true,
            StorageType.LOCAL_STORAGE,
            StorageKey.USER_INFO,
            tmpUserInfo,
          );
        } else {
          console.log('System UnderMaintaince');
        }
        return response;
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      console.log('RefreshJWTToken Error');
    }
  }

  CheckTokenTimeIsValid(tokenExpired: number): boolean {
    let isValid = false;
    try {
      const detail: PlayerInfo = this.user();

      if (detail) {
        if (this.checkStringHasValue(detail.authToken)) {
          const timeResponse = new Date();
          console.log('now time not convert== ' + timeResponse);
          console.log('now time convert== ' + new Date(timeResponse));
          console.log('now time== ' + new Date(timeResponse).valueOf());
          console.log('Token get not convert== ' + detail.getTokenTime);
          console.log('Token get convert== ' + new Date(detail.getTokenTime));
          console.log(
            'Token get == ' + new Date(detail.getTokenTime).valueOf(),
          );

          const resultTime =
            timeResponse.valueOf() - new Date(detail.getTokenTime).valueOf();
          console.log('duration===' + resultTime);
          const minutes = Math.round(resultTime / 60000);
          console.log('minutes== ' + minutes);

          if (minutes <= tokenExpired) {
            console.log('valid');
            isValid = true;
          } else {
            console.log('Not valid');
            isValid = false;
          }
        }
      }
    } catch {
      isValid = false;
      return isValid;
    }
    console.log('valid result==' + isValid);
    return isValid;
  }

  getDetailFromStorage(storageType: StorageType, storageKey: StorageKey) {
    const response =
      storageType === StorageType.LOCAL_STORAGE
        ? localStorage.getItem(storageKey)
        : sessionStorage.getItem(storageKey);

    if (response) return JSON.parse(response);

    return response;
  }

  // TODO: Remove due to refactor?
  postBuildHeader(
    headerConfig: HttpHeaderConfig,
    isAuthorize: boolean = false,
  ): HttpHeaders {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isAuthorize) {
      const userInfo: PlayerInfo = this.user();
      header = header.append('Authorization', 'Bearer ' + userInfo.authToken);
    }

    return header;
  }

  // TODO: Remove due to refactor?
  getBuildHeader(encryption?: string): HttpHeaders {
    const userInfo: PlayerInfo = this.user();
    let header = new HttpHeaders({
      Authorization: 'Bearer ' + userInfo.authToken,
    });

    if (encryption) {
      header = header.append('GetParams', encryption);
    }

    return header;
  }

  async getClientIp() {
    const response = await this.http
      .get<any>(
        'https://api.ipstack.com/check?access_key=596873bf1547c3c0d54f55c481d167ce',
      )
      .toPromise();
    if (response) {
      localStorage.setItem('ClientIp', JSON.stringify(response));
    }
    return response;
  }

  async getClientIpApi() {
    const response = await this.http
      .get<any>(
        this.appConfig.apiDomain + RequestApiLink.GET_IP + this.timeSpan,
      )
      .toPromise();
    if (response) {
      const tmpIP = {
        ip: response.content[2],
      };
      localStorage.setItem('ClientIp', JSON.stringify(tmpIP));
    }

    return response;
  }

  checkTokenResponseStatus(response: ResponseMessage): boolean {
    let status = false;
    if (response.status === ResponseCode.Normal) {
      status = true;
    }

    return status;
  }

  checkStringHasValue = (str) => {
    if (str == null) {
      return false;
    } else if (str === '') {
      return false;
    } else {
      return true;
    }
    // tslint:disable-next-line: semicolon
  };

  getDeviceType() {
    if (this.currentSiteConfig.deviceType === Devices.Mobile) {
      return true;
    } else {
      return false;
    }
  }

  getGameLanguange() {
    let value = '0';
    switch (this.currentSiteConfig.languange.value) {
      case 'en':
        value = '0';
        break;
      case 'cn':
        value = '1';
        break;
      case 'tw':
        value = '1';
        break;
      case 'vn':
        value = '3';
        break;
      case 'th':
        value = '5';
        break;
    }
    return value;
  }

  selectLanguange(item: defaultLanguage) {
    if (item.value !== this.currentSiteConfig.languange.value) {
      this.currentSiteConfig.languange = item;
      this.translateServ.setDefaultLang(item.value);
      this.setOrRemoveItemStorage(
        true,
        StorageType.LOCAL_STORAGE,
        StorageKey.App_Basic_Config,
        this.currentSiteConfig,
      );
      window.location.reload();
    }
  }

  setAllDetail(data) {
    const tmpUserInfo: PlayerInfo = this.getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );

    tmpUserInfo.amount = data.content.amount;
    tmpUserInfo.birth = data.content.birth;
    tmpUserInfo.mobile = data.content.mobile;
    tmpUserInfo.email = data.content.email;
    tmpUserInfo.playerId = data.content.id;
    tmpUserInfo.playerName = data.content.playerName;
    tmpUserInfo.currency = data.content.currency;
    tmpUserInfo.rate = data.content.rate;
    tmpUserInfo.realName = data.content.realName;
    tmpUserInfo.ownReferCode = data.content.ownReferCode;
    tmpUserInfo.referralUrl = data.content.referralUrl;
    tmpUserInfo.userTypeList = data.content.userTypeList;
    tmpUserInfo.withdrawalMaximum = data.content.withdrawalMaximum;
    tmpUserInfo.withdrawalMinimum = data.content.withdrawalMinimum;
    // tmpUserInfo.pinCodeSet = data.content.pinCodeSet;

    this.setOrRemoveItemStorage(
      true,
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
      tmpUserInfo,
    );
    this.playerInfo = tmpUserInfo;
  }

  showGameModal(content) {
    const objectMsg = content;
    this.modalServ.show(GameModalComponent, {
      ignoreBackdropClick: true,
      initialState: objectMsg,
    });
  }

  showFormPostGameModal(content) {
    const objectMsg = content;
    this.modalServ.show(PostGameModalComponent, {
      ignoreBackdropClick: true,
      initialState: objectMsg,
    });
  }

  setWalletDetail(content) {
    const tmpUserInfo: PlayerInfo = this.getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );
    tmpUserInfo.amount = content;
    this.setOrRemoveItemStorage(
      true,
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
      tmpUserInfo,
    );
    this.playerInfo = tmpUserInfo;
  }

  // Modal Pop out Function
  showAlert = (title: string, message: string) => {
    const objectMsg = { title, message };
    this.modalServ.show(MessageModalComponent, {
      ignoreBackdropClick: true,
      initialState: objectMsg,
      class: 'modal-alert',
    });
    // tslint:disable-next-line: semicolon
  };

  clearLogin(isMaintaince?: boolean) {
    this.isLogin = false;
    this.setOrRemoveItemStorage(
      false,
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );
    this.playerInfo = new PlayerInfo();
    sessionStorage.removeItem('Verify');
    this.showAlert('msg.System', 'msg.Logout');
    this.router.navigate(['home']);

    if (!isMaintaince) {
      // this.showAlertMsg('msg.Logout');
    }
  }

  redirectPage(pageUrl): string {
    const urlpage =
      location.protocol + '//' + location.hostname + '/' + pageUrl;

    return urlpage;
  }

  async getContentDetailAsync(type: string): Promise<ResponseMessage> {
    let response: any;
    try {
      const model = new MainPageContentModel();
      model.Language = this.languagesTypeEnum(
        this.currentSiteConfig.languange.value.toUpperCase(),
      );
      model.LanguageStr = this.currentSiteConfig.languange.value;
      model.TimeSpan = this.timeSpan;
      model.Ip = this.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan + model.Ip + model.LanguageStr + this.e('1').join(''),
        this.e('0').join(''),
      );

      response = await this.http
        .post<any>(
          this.appConfig.apiDomain + RequestApiLink.V2_MAIN_PAGE_CONTENT,
          model,
          {
            headers: this.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();

      localStorage.setItem('mainContent', JSON.stringify(response.content));
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }
}
