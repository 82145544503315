import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormPostLogin, PostElement } from 'src/app/model/request';
@Component({
  selector: 'app-post-game-modal',
  templateUrl: './post-game-modal.component.html',
  styleUrls: ['./post-game-modal.component.scss'],
})
export class PostGameModalComponent implements OnInit {
  @Input() gameName;
  @Input() gamelink;
  gameMessage = 'msg.GameMsg';
  formPostLogin: FormPostLogin;
  content: any;
  constructor(public bsRef: BsModalRef) {}

  ngOnInit() {
    if (this.gameName === 'R128 Horse Racing') {
      this.gameMessage = 'msg.HrMsg';
    } else {
      this.gameMessage = 'msg.GameMsg';
    }
    this.splitdomain();
    this.content = {
      gameName: this.gameName,
      gamelink: this.gamelink,
    };
  }

  splitdomain() {
    console.log(this.gamelink);
    const indexEnd = this.gamelink.indexOf('?');
    this.formPostLogin = new FormPostLogin();
    this.formPostLogin.postElement = [];
    this.formPostLogin.domainName = this.gamelink.substring(0, indexEnd);

    const paramsStr = this.gamelink.substring(
      indexEnd + 1,
      this.gamelink.length,
    );
    const splitElement = paramsStr.split('&');
    const index = 0;

    const tmpformPostLogin = this.formPostLogin;
    splitElement.forEach((value) => {
      const param = value.split(/=(.*)/s, 2);
      const tmpPostElement = new PostElement();

      tmpPostElement.key = param[0];
      tmpPostElement.value = param[1];
      tmpformPostLogin.postElement.push(tmpPostElement);
    });
  }
}
