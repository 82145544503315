import { inject, Injectable } from '@angular/core';
import { TokenRequestModel } from '../model/request';
import {
  ItemRequest,
  PromotionListRequest,
  PromotionSubscribeListRequest,
  PromotionSubscribeRequest,
} from '../model/request.v3';
import {
  BaseResponseModel,
  Promotion,
  PromotionAndCategory,
  PromotionSubscribe,
  PromotionSubscribeList,
  V3PlayerInfo,
} from '../model/response.v3';
import { BaseService } from './base.service';
import { EncryptionService } from './encryption.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  private readonly _http = inject(HttpService);
  private readonly baseServ = inject(BaseService);
  private readonly encryptServ = inject(EncryptionService);

  constructor() {}

  public getPromotionList(model: PromotionListRequest) {
    return this._http.sendGet<
      PromotionListRequest,
      BaseResponseModel<PromotionAndCategory>
    >('/v3/Promotion/GetCategoryAndPromotionList', model);
  }

  public getPromotionDetail(id: string) {
    const request = new ItemRequest();
    request.ItemId = id;

    return this._http.sendGet<ItemRequest, BaseResponseModel<Promotion>>(
      '/v3/Promotion/GetPromotionItem',
      request,
    );
  }

  public getPromotionSubscribeList(model: PromotionSubscribeListRequest) {
    console.log('hmm')
    return this._http.sendPost<
      PromotionSubscribeListRequest,
      BaseResponseModel<PromotionSubscribeList>
    >('/v3/Promotion/GetSubscribeList', model);
  }

  public subscribeNoneDepositPromotion(model: PromotionSubscribeRequest) {
    return this._http.sendPost<
      PromotionSubscribeRequest,
      BaseResponseModel<PromotionSubscribe>
    >('/v3/Promotion/SubscribeNoneDepositPromotion', model);
  }

  public subscribeFirstTimePromotion() {
    const request = new TokenRequestModel();
    request.PlayerId = this.baseServ.playerInfo.playerId || '';
    request.PlayerName = this.baseServ.playerInfo.playerName || '';

    return this._http.sendPost<TokenRequestModel, BaseResponseModel<V3PlayerInfo>>('/v3/Promotion/SubscribeFirsTimePromotion', request);
  }
}
