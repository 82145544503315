export class SecretKey {
  static webSiteConfigKey = 'ezy888_version_1.0.1';
  static tokenRefreshKey = 'MasterXQkunXB';
}

export class RequestApiLink {
  // v1
  public static CHECK_MAINTAINCE = 'v1/Master/CheckMaintenance';
  public static AUTH_TOKEN = 'v1/Player/GetApiToken2';
  public static GET_USER_DETAIL = 'v1/player/getDetail';
  public static LOGIN = 'v1/player/login';
  public static REGISTER = 'v1/Register2';
  public static GET_BANNER_LIST = 'v1/Master/BannerList/';
  public static GET_ANNOUCEMENT_LIST = 'v1/Master/AnnouncementList/';
  public static GET_SLOT_LIST = 'v1/Game/GetGameSlotList';
  public static GET_PROMOTION_CATEGORY = 'v1/Game/GetPromotionType';
  public static GET_PROMOTION_LIST = 'v1/Promotion/List';
  public static GET_PROMOTION_LIST_V1 = 'v1/Game/GetPromotionList';
  public static GET_GAME_BALANCE = 'v1/Game/GetBalanceCurrent/';
  public static LOGIN_GAME = 'v1/Game/Login';
  public static GAME_TRANSACTION = 'v1/Game/Transaction';
  public static UPDATE_PLAYER_DETAIL = 'v1/player/updateDetail';
  public static CHANGE_PASSWORD = 'v1/player/resetPassword';
  public static GET_BANK_DETAIL = 'v1/player/GetPlayerBankAccount';
  public static BIND_BANK_DETAIL = 'v1/player/bindBankAccount';
  public static GET_GAME_CATEGORY_LIST = 'v1/Game/GetGameCategoryList/';
  public static GET_GAME_BALANCE_LIST = 'v1/Game/GetGameBalanceList/';
  public static GET_GAME_RECORD_LIST = 'v1/Game/GetBetHistory';
  public static GENERATE_DEPOSIT = 'v1/payment/Deposit';
  public static GET_DEPOSIT_BANK_LIST = 'v1/payment/BankList';
  public static GET_DEPOSIT_ACCOUNT = 'v1/payment/GetBankAccount';
  public static GENERATE_WITHDRAW = 'v1/payment/withdraw';
  public static GET_TRANSACTION_RECORD = 'v1/payment/TransactionHistory';
  public static GET_CONTENT_DETAIL = 'v1/Master/GetContentList';
  public static GET_FAQ_LIST = 'v1/Master/GetFaqList';

  // v1 new
  public static GET_RANK_LIST = 'v1/Player/RankList';
  public static GET_DOWNLINE_LIST = 'v1/Player/DownLineList';
  public static GET_COMMISSION_LIST = 'v1/Player/CommissionList';

  public static GET_COMMISSION = 'v1/Player/GetCommission';
  public static GET_MONTH_COMMISSION = 'v1/Player/GetThisMonthCommission';
  public static COMMISSION_CLAIM = 'v1/Player/CommissionClaim';
  public static GET_LATEST_SETTLEMENT = 'v1/Player/GetLatestSettlement';

  // Payment GateWay
  public static GET_PAYMENT_GATEWAY_LIST = 'v1/Master/PaymentList';
  public static MAKE_ORDER_PAYMENT_GATEWAY = 'v1/Payment/ThirdPartyPayment';

  // v2
  public static GET_PROMOTION_CATEGORY_V2 = 'v2/promotion/gamecategory/list';
  public static GET_PROMOTION_LIST_V2 = 'v2/promotion/list/';
  public static SUBSCRIBE_PROMOTION = 'v2/promotion/subscribe';
  public static GET_SUBSCRIBE_PROMOTION_LIST = 'v2/promotion/subscribe/List';
  public static GET_ALLGAMEBALANCE = 'v1/Game/GetAllGameBalance';
  public static GET_TURNOVER = 'v2/promotion/getturnover';
  public static REBATE_SUBSCRIBE = 'v2/Promotion/SubscribeRebate';
  public static FREE_CREDIT_SUBSCRIBE = 'v2/Promotion/SubscribeFreeCredit';
  public static GET_SLOT_LIST_V2 = 'v2/Games/gameslotlist';
  public static GET_IP = 'v2/GetIp/';

  // v2 redeem
  public static GET_REDEEM_PROMOTION_LIST = 'v2/promotion/list2/';
  public static CLAIM_LOSE_REBATE = 'v2/promotion/ClaimLoseRebate';

  // tslint:disable-next-line: variable-name
  public static EEZiEPAY_DEPOSIT = 'v2/Payment/DepositEeziePay';
  // tslint:disable-next-line: variable-name
  public static EEZiEPAY_WITHDRAW = 'v2/Payment/WithdrawEeziePay';
  public static BANKCODE_LIST = 'v2/Payment/BankListThai';

  // version 2.0.0
  // player
  public static V2_PLAYER_REGISTER = 'V2/Player/Register';
  public static V2_PLAYER_LOGIN = 'V2/Player/Login';
  public static V2_PLAYER_RETRIEVE_JWT_TOKEN = 'V2/Player/RetrieveJwtToken';
  public static V2_PLAYER_DETAIL = 'V2/Player/GetDetail';
  public static V2_PLAYER_UPDATE_DETAIL = 'V2/Player/UpdateDetail';
  public static V2_PLAYER_RESET_PASSWORD = 'V2/Player/ResetPassword';
  public static V2_PLAYER_BIND_BANK_ACCOUNT = 'V2/Player/BindBankAccount';
  public static V2_PLAYER_CHECK_PHONE_NUMBER =
    'V2/Player/CheckPhoneNumberExist';
  public static V2_PLAYER_GET_TURNOVER = 'V2/Player/GetPlayerTurnOver';
  public static V2_PLAYER_CLAIM_TURNOVER = 'V2/Player/ClaimTurnOver';
  public static V2_PLAYER_LOGIN_SSO = 'V2/Player/LoginSSO';
  public static V2_PLAYER_PIN_CODE = 'V2/Player/PlayerPinCode';

  public static V2_PLAYER_SMS = 'V2/Player/SMS';
  public static V2_PLAYER_VERIFY_REGIS_OTP = 'V2/Player/VerifyRegisterOTP';
  public static V2_PLAYER_SMS_RESET_PASS = 'V2/Player/SMSResetPlayerPassword';

  // player lose rebate
  public static V2_PLAYER_GET_LOSE_REBATE = 'v2/PlayerLoseRebate/GetLoseRebate';
  public static V2_PLAYER_CLAIM_LOSE_REBATE =
    'v2/PlayerLoseRebate/ClaimLoseRebate';

  // content
  public static V2_MAIN_PAGE_CONTENT = 'V2/MainPageContent';
  public static V2_MAIN_PAGE_CONTENT_RANKING =
    'V2/MainPageContent/GetRankingList';

  // transaction
  public static V2_TRANSACTION_BANK_LIST = 'V2/Transaction/GetBankList';
  public static V2_TRANSACTION_BANK_ACCOUNT = 'V2/Transaction/GetBankAccount';
  public static V2_TRANSACTION_DEPOSIT = 'V2/Transaction/Deposit';
  public static V2_TRANSACTION_WITHDRAW = 'V2/Transaction/Withdraw';
  public static V2_TRANSACTION_HISTORY = 'V2/Transaction/GetTransactionHistory';
  public static V2_TRANSACTION_OPTION_BANK_LIST =
    'V2/Transaction/GetOptionBankList';

  // games
  public static V2_GAMES_CATEGORY_LIST = 'V2/Games/GetGameCategoryList';
  public static V2_GAMES_BALANCE_LIST = 'V2/Games/GetGameBalanceList';
  public static V2_GAMES_HISTORY_LIST = 'V2/Games/GetGameHistoryList';
  public static V2_GAMES_SLOT_LIST = 'V2/Games/GetGameSlotList';
  public static V2_GAMES_LOGIN = 'V2/Games/GameLogin';
  public static V2_GAMES_TRANSFER_OUT = 'V2/Games/TransferGameCreditOut';

  // promotion
  public static V2_PROMOTION_SUBSCRIBE_LIST = 'V2/Promotion/GetSubscribeList';
  public static V2_PROMOTION_LIST = 'V2/Promotion/GetCategoryAndPromotionList';
  public static V2_PROMOTION_SUBSCRIBE_NONE_DEPOSIT =
    'V2/Promotion/SubscribeNoneDepositPromotion';

  public static V2_PROMOTION_SUBSCRIBE_FIRST_TIME =
    'V2/Promotion/SubscribeFirsTimePromotion';

  // affiliate
  public static V2_AFFILIATE_CURRENT_COM_RANK =
    'V2/Affiliate/GetCurrentCommisionAndRankingSet';
  public static V2_AFFILIATE_CURRENT_COM_RANK_V2 =
    'V2/Affiliate/GetCurrentCommisionAndRankingSetV2';
  public static V2_AFFILIATE_AFF_COM_SETTLEMENT =
    'V2/Affiliate/GetAffCommissionSettlementList';
  public static V2_AFFILIATE_PLAYER_COM_DETAIL =
    'V2/Affiliate/GetPlayerCommissionDetail';
  public static V2_AFFILIATE_CLAIM_COM = 'V2/Affiliate/ClaimCommission';
  public static V2_AFFILIATE_DOWNLINE_LIST = 'V2/Affiliate/GetAffDownlineList';
  public static V2_AFFILIATE_DOWNLINE_TRANSACTION_LIST =
    'V2/Affiliate/GetDownLineTransactionList';

  // otp xxx
  public static SKYSPORT_OTP = 'https://xxx.payduty.net/v1/sms/otp_request';
  public static SKYSPORT_OTP_VERIFY =
    'https://xxx.payduty.net/v1/sms/otp_verify';

  // bank
  public static PAYDUTY_BANK_LIST = 'https://xxx.payduty.net/v1/bank/list';
  public static PAYDUTY_BANK_VERIFY = 'https://xxx.payduty.net/v1/bank/verify';

  // Payduty
  public static V2_PAYDUTY_WITHDRAW = 'V2/Payduty/Withdraw';
  public static V2_PAYDUTY_REGISTER = 'V2/Payduty/Register';
  public static V2_PAYDUTY_UPDATE = 'V2/Payduty/Update';
  public static V2_PAYDUTY_CHECK_TMP_PROPOSAL = 'V2/Payduty/CheckTmpProposal';
  public static V2_PAYDUTY_SUBSCRIBE_PROMOTION =
    'V2/Payduty/SubscribePromotion';

  // testimonial
  public static V2_CREATE_REVIEWS = 'V2/MainPageContent/CreateAndUpdateReviews';
  public static V2_GET_REVIEWS_LIST = 'V2/MainPageContent/GetReviewList';

  // Hermes paymeny gateway
  public static V2_HERMES_BANKLIST = 'v2/Hermes/BankList';
  public static V2_HERMES_DEPOSIT_ACC = 'v2/Hermes/DepositAccount';
  public static V2_HERMES_REGISTER = 'v2/Hermes/Register';
  public static V2_HERMES_VERIFY_BANKACC = 'v2/Hermes/VerifyBankAccount';
  public static V2_HERMES_UPDATEACC = 'v2/Hermes/UpdateAccount';
  public static V2_HERMES_WITHDRAW = 'v2/Hermes/Withdraw';
}
