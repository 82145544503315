import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pagination',
  template: `
    <ul>
      <li *ngIf="showFirstPage" class="first">
        <button type="button" class="button" (click)="onClickPageNum(1)">
          {{ 1 }}
        </button>
      </li>
      <li *ngFor="let n of limitedPages; let i = index">
        <button
          type="button"
          class="button"
          [attr.data-active]="currentPageNumSubject.value == n"
          (click)="onClickPageNum(n)"
        >
          {{ n }}
        </button>
      </li>

      <li *ngIf="showLastPage" class="last">
        <button
          type="button"
          class="button"
          (click)="onClickPageNum(lastPageNum)"
        >
          {{ lastPageNum }}
        </button>
      </li>
    </ul>
  `,
  styles: [
    `
      ul {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0px;

        button {
          border-radius: 8px;
          font-size: var(--body-small);
          background: #fff;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #fff;
          width: 32px;
          height: 32px;
          font-weight: 700;
          color: var(--color-primary-3);
          border: 1px solid var(--color-primary-3);
        }

        button[data-active='true'] {
          color: #fff;
          border: unset;
          background: var(--color-primary);
        }

        li.first:after {
          content: '...';
          padding-left: 10px;
          font-size: var(--body-large);
        }

        li.last:before {
          content: '...';
          padding-right: 10px;
          font-size: var(--body-large);
        }
      }
    `,
  ],
})
export class PaginationComponent implements OnInit, OnDestroy {
  @Input() set maxPageNumShow(value: number) {
    this.maxPageNumShowSubject.next(value);
  }
  @Input() set pagePerView(value: number) {
    this.pagePerViewSubject.next(value);
  }
  @Input() set totalCount(value: number) {
    this.totalCountSubject.next(value);
  }
  @Input() set currentPageNum(value: number) {
    this.currentPageNumSubject.next(value);
  }
  @Output() clickPageNum = new EventEmitter<number>();

  private readonly maxPageNumShowSubject = new BehaviorSubject<number>(5);
  private readonly pagePerViewSubject = new BehaviorSubject<number>(10);
  private readonly totalCountSubject = new BehaviorSubject<number>(0);
  public readonly currentPageNumSubject = new BehaviorSubject<number>(1);
  private readonly _buffer = 3;

  pages = new BehaviorSubject<number[]>([1]);
  lastPageNum: number = 1;
  limitedPages: number[] = [];
  showFirstPage: boolean = true;
  showLastPage: boolean = true;

  private unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    // pageSize
    combineLatest([this.totalCountSubject, this.pagePerViewSubject])
      .pipe(
        map(([totalCount, pagePerView]) =>
          Array.from(
            { length: Math.ceil(totalCount / pagePerView) },
            (_, i) => i + 1,
          ),
        ),
      )
      .subscribe((x) => {
        this.pages.next(x);
      });

    // lastPage
    this.lastPageNum$
      .pipe(
        tap((maxPage) => (this.lastPageNum = maxPage)),
        switchMap(() =>
          // limitedPage
          this.limitedPages$.pipe(
            tap((x) => {
              this.limitedPages = x;
              this.showFirstPage =
                (this.limitedPages[0] || Number.MAX_VALUE) > 1;
              this.showLastPage =
                (this.limitedPages[this.limitedPages.length - 1] || 0) <
                this.lastPageNum;
            }),
          ),
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  readonly lastPageNum$ = combineLatest([
    this.totalCountSubject,
    this.pagePerViewSubject,
  ]).pipe(
    map(([totalCount, pagePerView]) => Math.ceil(totalCount / pagePerView)),
  );

  readonly limitedPages$ = combineLatest([
    this.currentPageNumSubject,
    this.maxPageNumShowSubject,
  ]).pipe(
    map(([currentPage, maxPageNumShow]) => {
      const start = Math.max(currentPage - this._buffer, 0);
      return this.pages.value.slice(
        start,
        Math.min(start + maxPageNumShow, this.lastPageNum),
      );
    }),
  );

  public onClickPageNum(n: number) {
    this.clickPageNum.emit(n);
  }
}
