import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { PlayerService } from 'src/app/services/player.service';
import {
  SubscribePromotionListModel,
  WithdrawProposalModel,
  HermesWithdrawModel,
} from 'src/app/model/request';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

import { PaymentService } from 'src/app/services/payment.service';
import { PaymentGateway } from 'src/app/model/response.v3';

@Component({
  selector: 'app-hermes-withdraw',
  templateUrl: './hermes-withdraw.component.html',
  styleUrls: ['./hermes-withdraw.component.scss'],
})
export class HermesWithdrawComponent implements OnInit {
  @Input() paymentGatewayDetail: PaymentGateway | undefined = undefined;

  public withdrawModel = new WithdrawProposalModel();
  public subscribtionModelReq = new SubscribePromotionListModel();
  public TurnOver: any;
  public TurnOverStatus: string;
  public TurnOverBet: string;
  public TurnOverRemask: string;
  public PromotionType: number;


  public disabledWithdrawBtn = false;

  public hermesWithdrawModel = new HermesWithdrawModel();

  constructor(
    public baseServ: BaseService,
    public playerServ: PlayerService,
    public alertServ: AlertService,
    public generalServ: GeneralService,
    public router: Router,

    private paymentServ: PaymentService,
  ) {
    this.subscribtionModelReq.DaySelected = 30;
    this.subscribtionModelReq.PromotionStatusEnum = 'All';

    this.checkCurrentCurrency();
    this.getSubscribtionListAsync();
  }

  async ngOnInit() {
    if (!this.baseServ.isLogin) {
      this.router.navigateByUrl('login');
    } else {
      this.withdrawModel.Currency = this.baseServ.playerInfo.currency;
      this.withdrawModel.Rate = this.baseServ.appConfig.currenyRate;
      this.withdrawModel.TransPassword = '121231';

      this.hermesWithdrawModel.Currency = this.baseServ.playerInfo.currency;
      this.hermesWithdrawModel.Rate = this.baseServ.appConfig.currenyRate;
      this.hermesWithdrawModel.TransPassword = '121231';
    }
  }

  async getSubscribtionListAsync() {
    const response = await this.playerServ.getSubscribePromotionListAsync(
      this.subscribtionModelReq,
    );

    this.TurnOver = response.content.currentTurnOver;
    this.TurnOverStatus = this.TurnOver.promotionName;

    if (this.TurnOver != null) {
      this.PromotionType = this.TurnOver.promotionType;
      this.TurnOverBet =
        this.TurnOver.currentTurnOver + '/' + this.TurnOver.targetTurnOver;

      if (this.TurnOver.currentTurnOver >= this.TurnOver.targetTurnOver) {
        this.TurnOverRemask = 'label.yes';
      } else {
        this.TurnOverRemask = 'label.no';
      }
    }

    this.getPlayerDetail();
  }

  async getPlayerDetail() {
    this.baseServ.isLoading = true;
    const data = await this.playerServ.getPlayerDetailAsync();
    if (this.baseServ.checkTokenResponseStatus(data)) {
      this.baseServ.setAllDetail(data);

      this.withdrawModel.BankName = data.content.bankName;
      this.withdrawModel.AccountName = data.content.accountName;
      this.withdrawModel.AccountNo = data.content.bankAccountNo;
      this.withdrawModel.ICardNo = data.content.iCardNo;
      this.withdrawModel.IsbankCard = true;

      if (this.PromotionType === 9) {
        this.withdrawModel.SumRateAmount =
          this.playerServ.baseServ.playerInfo.amount;
        this.withdrawModel.Amount = this.playerServ.baseServ.playerInfo.amount;
      }

      this.withdrawModel.Balance = this.baseServ.playerInfo.amount;

      this.hermesWithdrawModel.BankCode = data.content.bankCode;
      this.hermesWithdrawModel.ToAccountName = data.content.accountName;
      this.hermesWithdrawModel.ToAccountNumber = data.content.bankAccountNo;
    }

    this.baseServ.isLoading = false;
  }

  checkCurrentCurrency() {
    this.withdrawModel.Currency = 'THB';
    this.withdrawModel.Rate = 1.0;

    this.hermesWithdrawModel.Currency = 'THB';
    this.hermesWithdrawModel.Rate = 1.0;
  }

  amountChange() {
    this.withdrawModel.SumRateAmount = Math.trunc(
      this.withdrawModel.Amount * this.withdrawModel.Rate,
    );

    this.hermesWithdrawModel.SumRateAmount = Math.trunc(
      this.hermesWithdrawModel.Amount * this.hermesWithdrawModel.Rate,
    );
  }

  async hermesWithdraw() {
    this.disabledWithdrawBtn = true;

    if (this.TurnOver != null) {
      if (this.TurnOver.currentTurnOver >= this.TurnOver.targetTurnOver) {
        if (
          this.hermesWithdrawModel.Amount > 1 &&
          this.withdrawModel.Balance >= this.hermesWithdrawModel.Amount
        ) {
          this.baseServ.isLoading = true;

          const res = await this.generalServ.HermesWithdrawAsync(
            this.hermesWithdrawModel,
          );

          if (this.baseServ.checkTokenResponseStatus(res)) {
            this.hermesWithdrawModel = new HermesWithdrawModel();
            this.getPlayerDetail();
            this.baseServ.showAlert(
              'msg.System',
              'msg.ProposalWithdrawSuccess',
            );
          } else {
            this.baseServ.showAlert('msg.System', 'msg.' + res.msg);
          }

          this.baseServ.isLoading = false;
        } else {
          this.baseServ.showAlert('msg.System', 'msg.ErrorAmount');
        }
      } else {
        this.alertServ.showAlertMsg('label.turn_over_msg');
      }
    } else {
      this.alertServ.showAlertMsg('msg.GetTurnOverFailed');
    }

    this.disabledWithdrawBtn = false;
  }
}
