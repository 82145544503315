import { Pipe, PipeTransform } from '@angular/core';

const BANKCODE_LIST = ['STCB','BAAC','TBANK','LHB','UOB','UOBT','KKB','GSB','CIMBT','TMB','SCB','KTB','KBANK','BBL','BAY','KSAB','IBANK','KIB','TTB',
];

@Pipe({
  name: 'banklist',
})
export class BanklistPipe implements PipeTransform {
  transform(value: string, type: string): string {
    let result = '';
    switch (type) {
      case 'ico':
        result = this.defineIco(value);
        break;
      case 'name':
        result = this.defineBankName(value);
        break;
      case 'nameEn':
        result = this.defineBankNameEn(value);
        break;
      case 'nameTh':
        result = this.defineBankNameTh(value);
        break;
      case 'icoManual':
        result = this.defineManualBankIco(value);
        break;
      case 'nameEnManual':
        result = this.defineManualBankEN(value);
        break;
      case 'nameThManual':
        result = this.defineManualBankTh(value);
        break;
      case 'images':
        result = this.defineImages(value);
        break;
    }

    return result;
  }

  defineImages(value): string {
    let img: string;
    switch (value) {
      case 'STCB':
        img = '../../../../assets/imgs/bank/logo.png';
        break;
      case 'BAAC':
        img = '../../../../assets/imgs/bank/baac.png';
        break;
      case 'TBANK':
        img = '../../../../assets/imgs/bank/thanachart.png';
        break;
      case 'LHB':
        img = '../../../../assets/imgs/bank/logo.png';
        break;
      case 'UOB':
        img = '../../../../assets/imgs/bank/uob.png';
        break;
      case 'UOBT':
        img = '../../../../assets/imgs/bank/uob.png';
        break;
      case 'KKB':
        img = '../../../../assets/imgs/bank/logo.png';
        break;
      case 'GSB':
        img = '../../../../assets/imgs/bank/gsb.png';
        break;
      case 'CIMBT':
        img = '../../../../assets/imgs/bank/cimb.png';
        break;
      case 'TMB':
        img = '../../../../assets/imgs/bank/tmb.png';
        break;
      case 'SCB':
        img = '../../../../assets/imgs/bank/scb.png';
        break;
      case 'KTB':
        img = '../../../../assets/imgs/bank/ktb.png';
        break;
      case 'KBANK':
        img = '../../../../assets/imgs/bank/kasikorn.png';
        break;
      case 'BBL':
        img = '../../../../assets/imgs/bank/bankkok.png';
        break;
      case 'BAY':
        img = '../../../../assets/imgs/bank/kurngsri.png';
        break;
      case 'KSAB':
        img = '../../../../assets/imgs/bank/logo.png';
        break;
      case 'IBANK':
        img = '../../../../assets/imgs/bank/is.png';
        break;
      case 'KIB':
        img = '../../../../assets/imgs/bank/kib.png';
        break;
      case 'TTB':
        img = '../../../../assets/imgs/bank/ttb.png';
        break;
      case 'test':
        img = '../../../../assets/imgs/bank/logo.png';
        break;
    }

    return img;
  }

  defineBankName(value: string): string {
    if (BANKCODE_LIST.includes(value)) {
      return `label.${value}`;
    } else {
      return;
    }
  }

  defineBankNameEn(value): string {
    let name: string;

    switch (value) {
      case 'TMB':
        name = 'label.tmb_bank_en';
        break;
      case 'SCB':
        name = 'label.scb_bank_en';
        break;
      case 'KTB':
        name = 'label.ktb_bank_en';
        break;
      case 'KBANK':
        name = 'label.k_bank_en';
        break;
      case 'BAY':
        name = 'label.bay_bank_en';
        break;
      case 'BBL':
        name = 'label.bbl_bank_en';
        break;
      case 'STCB':
        name = 'label.stcb_bank_en';
        break;
      case 'BAAC':
        name = 'label.baac_bank_en';
        break;
      case 'THCB':
        name = 'label.thcb_bank_en';
        break;
      case 'LHB':
        name = 'label.lhb_bank_en';
        break;
      case 'UOB':
        name = 'label.uob_bank_en';
        break;
      case 'GSB':
        name = 'label.gsb_bank_en';
        break;
      case 'CIMBT':
        name = 'label.cimb_bank_en';
        break;
      case 'QR':
        name = 'label.qr_bank_en';
        break;
    }

    return name;
  }

  defineBankNameTh(value): string {
    let name: string;

    switch (value) {
      case 'TMB':
        name = 'label.tmb_bank_th';
        break;
      case 'SCB':
        name = 'label.scb_bank_th';
        break;
      case 'KTB':
        name = 'label.ktb_bank_th';
        break;
      case 'KBANK':
        name = 'label.k_bank_th';
        break;
      case 'BAY':
        name = 'label.bay_bank_th';
        break;
      case 'BBL':
        name = 'label.bbl_bank_th';
        break;
      case 'STCB':
        name = 'label.stcb_bank_th';
        break;
      case 'BAAC':
        name = 'label.baac_bank_th';
        break;
      case 'THCB':
        name = 'label.thcb_bank_th';
        break;
      case 'LHB':
        name = 'label.lhb_bank_th';
        break;
      case 'UOB':
        name = 'label.uob_bank_th';
        break;
      case 'GSB':
        name = 'label.gsb_bank_th';
        break;
      case 'CIMBT':
        name = 'label.cimb_bank_th';
        break;
      case 'QR':
        name = 'label.qr_bank_th';
        break;
    }

    return name;
  }

  defineIco(value): string {
    let sccsClass: string;
    switch (value) {
      case 'TMB':
        sccsClass = 'crop bank-ico-tmb';
        break;
      case 'SCB':
        sccsClass = 'crop bank-ico-scb';
        break;
      case 'KTB':
        sccsClass = 'crop bank-ico-ktb';
        break;
      case 'KBANK':
        sccsClass = 'crop bank-ico-kbank';
        break;
      case 'BBL':
        sccsClass = 'crop bank-ico-bbl';
        break;
      case 'BAY':
        sccsClass = 'crop bank-ico-bay';
        break;
      case 'STCB':
        sccsClass = 'crop bank-ico-stcb';
        break;
      case 'BAAC':
        sccsClass = 'crop bank-ico-baac';
        break;
      case 'THCB':
        sccsClass = 'crop bank-ico-thcb';
        break;
      case 'LHB':
        sccsClass = 'crop bank-ico-lhb';
        break;
      case 'UOB':
        sccsClass = 'crop bank-ico-uob';
        break;
      case 'GSB':
        sccsClass = 'crop bank-ico-gsb';
        break;
      case 'CIMBT':
        sccsClass = 'crop bank-ico-cimb';
        break;
      case 'QR':
        sccsClass = 'crop bank-ico-qr';
        break;
    }

    return sccsClass;
  }

  defineManualBankIco(value) {
    let sccsClass: string;
    switch (value) {
      case '5da819699e54f12edcad12e2':
        sccsClass = 'crop bank-ico-ktb';
        break;

      case '5d909ff19e54f12edcad125a':
        sccsClass = 'crop bank-ico-kbank';
        break;

      case '5db9cf8c9e54f12edcad13f8':
        sccsClass = 'crop bank-ico-bay';
        break;

      case '5da819299e54f12edcad12de':
        sccsClass = 'crop bank-ico-scb';
        break;

      case 'KKB':
        sccsClass = 'crop bank-ico-kbank';
        break;

      case 'VCB':
        sccsClass = 'crop  bank-ico-vcb';
        break;

      case 'BKB':
        sccsClass = 'crop bank-ico-bbl';
        break;
      case 'SAB':
        sccsClass = 'crop bank-ico-sab';
        break;

      case 'KTB':
        sccsClass = 'crop bank-ico-ktb';
        break;
      case 'TCB':
        sccsClass = 'crop bank-ico-tcb';
        break;

      case 'SCB':
        sccsClass = 'crop bank-ico-scb';
        break;

      case 'ACB':
        sccsClass = 'crop bank-ico-acb';
        break;

      case 'DAB':
        sccsClass = 'crop bank-ico-dab';
        break;

      case 'KSAB':
        sccsClass = 'crop bank-ico-bay';
        break;

      case 'VTB':
        sccsClass = 'crop bank-ico-vtb';
        break;

      case 'GSB':
        sccsClass = 'crop bank-ico-gsb';
        break;

      case 'BIDVB':
        sccsClass = 'crop bank-ico-bidvb';
        break;
      case 'TMB':
        sccsClass = 'crop bank-ico-tmb';
        break;
      case 'EXB':
        sccsClass = 'crop bank-ico-exb';
        break;

      case 'CIMB':
        sccsClass = 'crop bank-ico-cimb';
        break;

      case 'KIB':
        sccsClass = 'crop bank-ico-kib';
        break;
    }
    return sccsClass;
  }

  defineManualBankEN(value) {
    let name: string;
    switch (value) {
      case '5da819699e54f12edcad12e2':
        name = 'label.ktb_bank_en';
        break;

      case '5d909ff19e54f12edcad125a':
        name = 'label.k_bank_en';
        break;

      case '5db9cf8c9e54f12edcad13f8':
        name = 'label.bay_bank_en';
        break;

      case '5da819299e54f12edcad12de':
        name = 'label.scb_bank_en';
        break;

      case 'KKB':
        name = 'label.k_bank_en';
        break;

      case 'VCB':
        name = 'label.vcb_bank_en';
        break;

      case 'BKB':
        name = 'label.bbl_bank_en';
        break;
      case 'SAB':
        name = 'label.sab_bank_en';
        break;

      case 'KTB':
        name = 'label.ktb_bank_en';
        break;
      case 'TCB':
        name = 'label.tcb_bank_en';
        break;

      case 'SCB':
        name = 'label.scb_bank_en';
        break;

      case 'ACB':
        name = 'label.acb_bank_en';
        break;

      case 'DAB':
        name = 'label.dab_bank_en';
        break;

      case 'KSAB':
        name = 'label.bay_bank_en';
        break;

      case 'VTB':
        name = 'label.vtb_bank_en';
        break;

      case 'GSB':
        name = 'label.gsb_bank_en';
        break;

      case 'BIDVB':
        name = 'label.bidvb_bank_en';
        break;
      case 'TMB':
        name = 'label.tmb_bank_en';
        break;
      case 'EXB':
        name = 'label.exb_bank_en';
        break;

      case 'CIMB':
        name = 'label.cimb_bank_en';
        break;

      case 'KIB':
        name = 'label.kib_bank_en';
        break;
    }
    return name;
  }

  defineManualBankTh(value) {
    let name: string;
    switch (value) {
      case '5da819699e54f12edcad12e2':
        name = 'label.ktb_bank_th';
        break;

      case '5d909ff19e54f12edcad125a':
        name = 'label.k_bank_th';
        break;

      case '5db9cf8c9e54f12edcad13f8':
        name = 'label.bay_bank_th';
        break;

      case '5da819299e54f12edcad12de':
        name = 'label.scb_bank_th';
        break;

      case 'KKB':
        name = 'label.k_bank_th';
        break;

      case 'VCB':
        name = 'label.vcb_bank_th';
        break;

      case 'BKB':
        name = 'label.bbl_bank_th';
        break;
      case 'SAB':
        name = 'label.sab_bank_th';
        break;

      case 'KTB':
        name = 'label.ktb_bank_th';
        break;
      case 'TCB':
        name = 'label.tcb_bank_th';
        break;

      case 'SCB':
        name = 'label.scb_bank_th';
        break;

      case 'ACB':
        name = 'label.acb_bank_th';
        break;

      case 'DAB':
        name = 'label.dab_bank_th';
        break;

      case 'KSAB':
        name = 'label.bay_bank_th';
        break;

      case 'VTB':
        name = 'label.vtb_bank_th';
        break;

      case 'GSB':
        name = 'label.gsb_bank_th';
        break;

      case 'BIDVB':
        name = 'label.bidvb_bank_th';
        break;
      case 'TMB':
        name = 'label.tmb_bank_th';
        break;
      case 'EXB':
        name = 'label.exb_bank_th';
        break;

      case 'CIMB':
        name = 'label.cimb_bank_th';
        break;

      case 'KIB':
        name = 'label.kib_bank_th';
        break;
    }
    return name;
  }
}
