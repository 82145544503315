import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promotion',
})
export class PromotionPipe implements PipeTransform {
  transform(value: any, type: any): any {
    let result = '';
    switch (type) {
      case 'category':
        result = this.getPromotionCategoryName(value);
        break;
      case 'transFrom':
        result = this.defineTransactionFrom(value);
        break;

      case 'transTo':
        result = this.defineTransactionTo(value);
        break;
      case 'transStatus':
        result = this.defineTransStatus(value);
        break;

      case 'transType':

        result = this.defineTransactionType(value);

        break;
      case 'promotionStatus':
        result = this.definePromotionStatus(value);
        break;

    }

    return result;
  }

  getPromotionCategoryName(value: string) {
    let result = '';
    switch (value) {
      case 'All':
        result = 'label.all';
        break;
      case 'Racing':
        result = 'label.horse_racing';
        break;
      case 'CockFight':
        result = 'label.horse_racing';
        break;
      case 'ChessGame':
        result = '';
        break;
      case 'SportBooks':
        result = 'label.sportsbook';
        break;
      case 'Slot':
        result = 'label.slots_game';
        break;
      case 'LiveCasino':
        result = 'label.live_casino';
        break;
    }

    return result;
  }

  defineTransactionFrom(value: string) {
    let result = '';
    switch (value) {
      case 'Deposit':
        result = '-';
        break;
      case 'Withdraw':
        result = 'main wallet';
        break;
      case 'CreditAdjustment':
        value = 'main wallet';
        break;
    }

    return result;
  }

  defineTransactionTo(value: string) {
    let result = '';
    switch (value) {
      case 'Deposit':
        result = 'main wallet';
        break;
      case 'Withdraw':
        result = '-';
        break;
      case 'CreditAdjustment':
        value = 'main wallet';
        break;
    }

    return result;
  }

  defineTransactionType(value: string) {
    let result = '';
    switch (value) {
      case 'Deposit':
        result = 'label.deposit_tab';
        break;
      case 'Withdraw':
        result = 'label.withdraw_tab';
        break;
      case 'CreditAdjustment':
        value = 'Adjustment';
        break;
    }

    return result;
  }

  defineTransStatus = (value: string) => {
    let result = '';
    switch (value) {
      case 'Pending':
        result = 'label.audit';
        break;
      case 'Approve':
        result = 'label.success';
        break;
      case 'Cancel':
        result = 'label.failed';
        break;

      default:
        result = "aaa";
        break;
    }

    return result;
  };

  definePromotionStatus(value: number) {
    let result = '';
    switch (value) {
      case -1:
        result = 'label.cancel';
        break;
      case 1:
        result = 'label.audit';
        break;
      case 2:
        result = 'label.success';
        break;
      case 3:
        result = 'label.complete';
        break;
      case 4:
        result = 'label.expired';
        break;
      case 5:
        result = 'label.reject';
        break;
    }

    return result;
  }
}
