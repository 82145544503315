import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PasswordReqModel } from 'src/app/model/request';
import { BaseService } from 'src/app/services/base.service';
import { PlayerService } from 'src/app/services/player.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-change-password-modal.component',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
  public passwordModel = new PasswordReqModel();
  public faTimes = faTimes;

  constructor(
    public bsRef: BsModalRef,
    public baseServ: BaseService,
    public playerServ: PlayerService,
  ) {}

  ngOnInit() {}

  async changePwd() {
    if (this.passwordModel.NewPassword === this.passwordModel.ConfirmPassword) {
      this.bsRef.hide();
      this.baseServ.isLoading = true;
      const data = await this.playerServ.changePasswordAsync(
        this.passwordModel,
      );
      this.passwordModel = new PasswordReqModel();
      if (this.baseServ.checkTokenResponseStatus(data)) {
        this.baseServ.showAlert('msg.System', 'msg.ChangePasswordSuccess');
      } else {
        this.baseServ.showAlert('msg.System', 'msg.' + data.msg);
      }
      this.baseServ.isLoading = false;
    } else {
      this.baseServ.showAlert('msg.System', 'msg.PasswordNoMatch');
    }
  }
}
