import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gamesNameFilter',
})
export class GamesNameFilterPipe implements PipeTransform {
  transform(value: any): string {
    let result = '';
    switch (value) {
      case 'SportBooksWFTTHB':
        result = 'SportBooksWFT';
        break;
      case 'EbetTest':
        result = 'Ebet';
        break;
      case 'EbetMy':
        result = 'Ebet';
        break;
      case 'EbetId':
        result = 'Ebet';
        break;
      case 'EbetCn':
        result = 'Ebet';
        break;
      case 'EbetTh':
        result = 'Ebet';
        break;
      case 'EbetVn':
        result = 'Ebet';
        break;
      case 'EbetUs':
        result = 'Ebet';
        break;
      case 'SportBooksWWTh':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWWUs':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWWMy':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWWId':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWWVn':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWWCn':
        result = 'SportBooksWW';
        break;
      case 'SportBooksWFTMYR':
        result = 'SportBooksWFT';
        break;
      case 'SportBooksWFTIDR':
        result = 'SportBooksWFT';
        break;
      case 'SportBooksWFTVND':
        result = 'SportBooksWFT';
        break;
      case 'SportBooksWFTRMB':
        result = 'SportBooksWFT';
        break;
      case 'JokerMYR':
        result = 'Joker';
        break;
      case 'JokerCNY':
        result = 'Joker';
        break;
      case 'AllBetTHB':
        result = 'AllBet';
        break;
      case 'AllBetCNY':
        result = 'AllBet';
        break;
      case 'AllBetMYR':
        result = 'AllBet';
        break;
      case 'AllBetVND':
        result = 'AllBet';
        break;
      case 'AllBetIDR':
        result = 'AllBet';
        break;
      case 'AllBetTHB':
        result = 'AllBet';
        break;
      case 'AllBetUSD':
        result = 'AllBet';
        break;
      case 'SportBooksSBOSeamless':
        result = 'SportBooksSBO';
        break;
      case 'CockFightTHB':
        result = 'CockFight';
        break;
      case 'CockFightMYR':
        result = 'CockFight';
        break;
      case 'CockFightIDN':
        result = 'CockFight';
        break;
      case 'HorseRacingTHB':
        result = 'HorseRacing';
        break;
      case 'HorseRacingMYR':
        result = 'HorseRacing';
        break;
      case 'HorseRacingIDN':
        result = 'HorseRacing';
        break;
      case 'VirtualSportsSBOSeamless':
        result = 'VirtualSportsSBO';
        break;
      case 'CasinoSBOSeamless':
        result = 'CasinoSBO';
        break;
      case 'IdnPokerVND':
        result = 'IdnPoker';
        break;
      case 'IdnPokerTHB':
        result = 'IdnPoker';
        break;
      case 'IdnPokerIDR':
        result = 'IdnPoker';
        break;
      case 'IdnPokerMYR':
        result = 'IdnPoker';
        break;
      case 'IdnPokerRMB':
        result = 'IdnPoker';
        break;
      case 'Cq9CNY':
        result = 'Cq9';
        break;
      case 'PlayTechMYR':
        result = 'PlayTech';
        break;
      case 'PlayTechIDR':
        result = 'PlayTech';
        break;
      case 'PlayTechCNY':
        result = 'PlayTech';
        break;
      case 'PlayTechTHB':
        result = 'PlayTech';
        break;
      case 'PlayTechVND':
        result = 'PlayTech';
        break;
      case 'JokerUSD':
        result = 'Joker';
        break;
      case 'JokerIDR':
        result = 'Joker';
        break;
      case 'MgMYR':
        result = 'Mg';
        break;
      case 'MgCNY':
        result = 'Mg';
        break;
      case 'MgUSD':
        result = 'Mg';
        break;
      case 'MgIDR':
        result = 'Mg';
        break;
      case 'MgVND':
        result = 'Mg';
        break;
      case 'AgMYR':
        result = 'Ag';
        break;
      case 'AgCNY':
        result = 'Ag';
        break;
      case 'AgUSD':
        result = 'Ag';
        break;
      case 'AgIDR':
        result = 'Ag';
        break;
      case 'AgVND':
        result = 'Ag';
        break;
      case 'AgTHB':
        result = 'Ag';
        break;
      case 'CockFightVND':
        result = 'CockFight';
        break;
      case 'Cq9SlotMYR':
        result = 'Cq9Slot';
        break;
      case 'Cq9SlotIDR':
        result = 'Cq9Slot';
        break;
      case 'Cq9SlotVND':
        result = 'Cq9Slot';
        break;
      case 'Cq9SlotUSD':
        result = 'Cq9Slot';
        break;
      case 'Cq9SlotTHB':
        result = 'Cq9Slot';
        break;
      case 'AllBetNTD':
        result = 'AllBet';
        break;
      case 'IgCNY':
        result = 'Ig';
        break;
      case 'VrCNY':
        result = 'Vr';
        break;
      case 'WmCNY':
        result = 'Wm';
        break;
      case 'MgTHB':
        result = 'Mg';
        break;
      case 'SexyBaccaratTHB':
        result = 'SexyBaccarat';
        break;
      case 'SexyBaccaratCNY':
        result = 'SexyBaccarat';
        break;
      case 'SexyBaccaratIDR':
        result = 'SexyBaccarat';
        break;
      case 'SexyBaccaratVND':
        result = 'SexyBaccarat';
        break;
      case 'SexyBaccaratUSD':
        result = 'SexyBaccarat';
        break;
      case 'SexyBaccaratMYR':
        result = 'SexyBaccarat';
        break;
      case 'LcCardCNY':
        result = 'LcCard';
        break;
      case 'LcCardTHB':
        result = 'LcCard';
        break;
      case 'LcCardMYR':
        result = 'LcCard';
        break;
      case 'LcCardIDR':
        result = 'LcCard';
        break;
      case 'LcCardVND':
        result = 'LcCard';
        break;
      case 'LcCardUSD':
        result = 'LcCard';
        break;
      case 'CockFightCNY':
        result = 'CockFight';
        break;
      case 'HorseRacingCNY':
        result = 'HorseRacing';
        break;
      case 'WmMYR':
        result = 'Wm';
        break;
      case 'WmTHB':
        result = 'Wm';
        break;
      case 'WmIDR':
        result = 'Wm';
        break;
      case 'WmVND':
        result = 'Wm';
        break;
      case 'WmUSD':
        result = 'Wm';
        break;
      case 'Clo888THB':
        result = 'Clo888';
        break;
      case 'WmTWD':
        result = 'Wm';
        break;
      case 'AvSlotCNY':
        result = 'AvSlot';
        break;
      case 'AvSlotTHB':
        result = 'AvSlot';
        break;
      case 'AvSlotMYR':
        result = 'AvSlot';
        break;
      case 'AvSlotUSD':
        result = 'AvSlot';
        break;
      case 'AvSlotIDR':
        result = 'AvSlot';
        break;
      case 'AvSlotVND':
        result = 'AvSlot';
        break;
      case 'MikiTest':
        result = 'Miki';
        break;
      case 'MikiTHB':
        result = 'Miki';
        break;
      case 'MikiVND':
        result = 'Miki';
        break;
      case 'MikiCNY':
        result = 'Miki';
        break;
      case 'MikiIDR':
        result = 'Miki';
        break;
      case 'MikiMYR':
        result = 'Miki';
        break;
      case 'MikiUSD':
        result = 'Miki';
        break;
      case 'IgSlotTHB':
        result = 'IgSlot';
        break;
      case 'IgSlotMYR':
        result = 'IgSlot';
        break;
      case 'IgSlotUSD':
        result = 'IgSlot';
        break;
      case 'IgSlotVND':
        result = 'IgSlot';
        break;
      case 'IgSlotCNY':
        result = 'IgSlot';
        break;
      case 'IgSlotIDR':
        result = 'IgSlot';
        break;
      case 'GsPlayTechTHB':
        result = 'PlayTech';
        break;
      case 'GsPlayTechMYR':
        result = 'PlayTech';
        break;
      case 'Cq9LiveCasinoMYR':
        result = 'Cq9LiveCasino';
        break;
      case 'Cq9LiveCasinoIDR':
        result = 'Cq9LiveCasino';
        break;
      case 'Cq9LiveCasinoVND':
        result = 'Cq9LiveCasino';
        break;
      case 'Cq9LiveCasinoUSD':
        result = 'Cq9LiveCasino';
        break;
      case 'Cq9LiveCasinoTHB':
        result = 'Cq9LiveCasino';
        break;
      case 'OneBookSportBooksTHB':
        result = 'SABA SportBooks';
        break;
      case 'GsPragmaticPlayMYR':
        result = 'PragmaticPlay';
        break;
      case 'GsPragmaticPlayTHB':
        result = 'PragmaticPlay';
        break;
      case 'GsMgSlotMYR':
        result = 'MgSlot';
        break;
      case 'GsMgSlotTHB':
        result = 'MgSlot';
        break;
      case 'GsMgLiveCasinoTHB':
        result = 'MgLiveCasino';
        break;
      case 'GsMgLiveCasinoMYR':
        result = 'MgLiveCasino';
        break;
      case 'GsVivoMYR':
        result = 'Vivo';
        break;
      case 'GsVivoTHB':
        result = 'Vivo';
        break;
      case 'Gs918KissTHB':
        result = '918Kiss';
        break;
      case 'Gs918KissMYR':
        result = '918Kiss';
        break;
      case 'GsAgSlotMYR':
        result = 'AgSlot';
        break;
      case 'GsAgSlotTHB':
        result = 'AgSlot';
        break;
      case 'GsAgLiveCasinoTHB':
        result = 'AgLiveCasino';
        break;
      case 'GsAgLiveCasinoMYR':
        result = 'AgLiveCasino';
        break;
      case 'GsPrettyGamingMYR':
        result = 'PrettyGaming';
        break;
      case 'GsPrettyGamingTHB':
        result = 'PrettyGaming';
        break;
      case 'OneBookSportBooksMYR':
        result = 'SABA SportBooks';
        break;
      case 'OneBookSportBooksIDR':
        result = 'SABA SportBooks';
        break;
      case 'OneBookSportBooksCNY':
        result = 'SABA SportBooks';
        break;
      case 'OneBookSportBooksVND':
        result = 'SABA SportBooks';
        break;
      default:
        result = value;
        break;
    }

    return result;
  }
}
