import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {
  SlotOptionRequestModel,
  GameLoginReqModel,
  BalanceTransferReqModel,
  GameRecordRequestModel,
  GetGameCategoryListModel,
  GetGameBalanceListModel,
} from '../model/request';
import { ResponseMessage } from '../model/response';
import { HttpParams } from '@angular/common/http';
import { HttpHeaderConfig, ResponseCode } from '../model/enums';
import { ToolsService } from './tools.service';
import { RequestApiLink } from '../model/static';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  public tmpGamePage: any;

  constructor(public baseServ: BaseService) {}

  async getSlotGameListAsync(
    model: SlotOptionRequestModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      model.Language = this.baseServ.languagesTypeEnum(
        this.baseServ.currentSiteConfig.languange.value.toUpperCase(),
      );
      model.LangStr = this.baseServ.currentSiteConfig.languange.value;
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        this.baseServ.timeSpan +
          model.Ip +
          model.LangStr +
          model.GameTypeString +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_GAMES_SLOT_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getAllGameBalanceAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.append(
          'Ip',
          this.baseServ.currentSiteConfig.clientIp.ip,
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_ALLGAMEBALANCE,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getAllGameBalanceAsync();
      }
    }

    return response;
  }

  async getBalanceBeforeGameAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .get<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_GAME_BALANCE +
              this.baseServ.currentSiteConfig.clientIp.ip,
            { headers: this.baseServ.getBuildHeader() },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getBalanceBeforeGameAsync();
      }
    }

    return response;
  }

  async loginGameAsync(model: GameLoginReqModel): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        if (model.SlotCode === 'CA01') {
          model.Language = 0;
        } else {
          model.Language = this.baseServ.languagesTypeEnum(
            this.baseServ.currentSiteConfig.languange.value.toUpperCase(),
          );
        }

        model.IsMobile = this.baseServ.getDeviceType();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.GameTypeString +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.V2_GAMES_LOGIN,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.loginGameAsync(model);
      }
    }

    return response;
  }

  async gameTransactionAsync(
    model: BalanceTransferReqModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.Amount +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_GAMES_TRANSFER_OUT,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.gameTransactionAsync(model);
      }
    }

    return response;
  }

  async getGameCategoryListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      const model = new GetGameCategoryListModel();
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.PlayerId = this.baseServ.playerInfo.playerId;
      model.PlayerName = this.baseServ.playerInfo.playerName;
      model.Signature = ToolsService.signature(
        this.baseServ.timeSpan +
          model.Ip +
          model.PlayerName +
          model.PlayerId +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_GAMES_CATEGORY_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getGameBalanceListAsync(category): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const model = new GetGameBalanceListModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Category = this.baseServ.categoryTypeEnum(category);
        model.CategoryString = category;
        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.CategoryString +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_GAMES_BALANCE_LIST,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getGameBalanceListAsync(category);
      }
    }

    return response;
  }

  async getGameRecordAsync(
    model: GameRecordRequestModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.StartDate = ToolsService.calculateDateBetween(
          new Date(),
          false,
          model.selectedDate,
        );
        model.EndDate = ToolsService.calculateDateBetween(new Date(), true);
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.CategoryTypeString +
            model.GameTypeString +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_GAMES_HISTORY_LIST,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getGameRecordAsync(model);
      }
    }

    return response;
  }
}
