import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'htmlfilter'
})
export class HtmlfilterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {

  }

  transform(value: string, type: string): any {
    if (type == "html") {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

  }

}
