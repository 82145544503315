import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router, NavigationExtras } from '@angular/router';
import { BaseService } from 'src/app/services/base.service';
import { ToolsService } from 'src/app/services/tools.service';
import { PlayerService } from 'src/app/services/player.service';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { DevicesPlatform, Devices } from 'src/app/model/enums';

@Component({
  selector: 'app-promotion-detail-modal',
  templateUrl: './promotion-detail-modal.component.html',
  styleUrls: ['./promotion-detail-modal.component.scss'],
})
export class PromotionDetailModalComponent implements OnInit {
  @Input() promotionName;
  @Input() description;
  @Input() imageLink;
  @Input() endDate;

  constructor(
    public bsRef: BsModalRef,
    public baseServ: BaseService,
    public generalServ: GeneralService,
    public playerServ: PlayerService,
    public alertServ: AlertService,
    private router: Router,
  ) {
    // console.log(this.baseServ.checkDeviceType());
  }

  ngOnInit() {}

  makeDeposit(item) {
    this.bsRef.hide();
    if (this.generalServ.baseServ.isLogin) {
      const navigationExtras: NavigationExtras = {
        state: {
          data: item,
        },
      };
    } else {
      this.alertServ.showAlertMsg('msg.LoginGameWithoutLogin');
    }
  }

  countDate(item) {
    const now1 = new Date();
    const expiredDate = new Date(item);
    const time = now1.getTime() + now1.getTimezoneOffset() * 60000;
    const distance = expiredDate.getTime() - time;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (this.baseServ.currentSiteConfig.languange.value === 'th') {
      if (days >= 0) {
        return (
          days + ' วัน ' + ToolsService.formatDateString(hours) + ' ชั่วโมง'
        );
      } else {
        return 'หมดอายุ';
      }
    } else {
      if (days >= 0) {
        return (
          days + ' Day(s) ' + ToolsService.formatDateString(hours) + ' hours(s)'
        );
      } else {
        return 'Expired';
      }
    }
  }
}
