import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getDetailFromStorage, log } from '../lib/util';
import { API } from '../model/api';
import { StorageKey, StorageType } from '../model/enums';
import { BaseRequestModel } from '../model/request';
import { BaseResponseModelV2, PlayerInfo } from '../model/response';
import { BaseResponseModel } from '../model/response.v3';
import { BaseService } from './base.service';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  _ipProviderUrl: string;
  _clientIp: string;
  _baseUrl: string;

  private readonly _httpClient = inject(HttpClient);
  private readonly _encryptionService = inject(EncryptionService);
  private readonly _baseService = inject(BaseService);

  constructor() {
    this._baseUrl = environment.apiUrl;
    this._ipProviderUrl = environment.ipProviderUrl;
  }

  public sendGet<
    TRequest extends BaseRequestModel,
    TResponse extends BaseResponseModel,
  >(path: API, model: TRequest): Observable<TResponse> {
    const encryptedRequest = this.encryptBaseRequest(model);

    return this._httpClient
      .get<TResponse>(this._baseUrl + path, {
        headers: this.getBuildHeader(encryptedRequest),
      })
      .pipe(
        tap((x) => log(`[sendGet][Response][${path}]`, x)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(
            new BaseResponseModel(
              err.status,
              `System Error: ${err.status}`,
            ) as TResponse,
          );
        }),
      );
  }

  public sendGetWithoutEncrypt<TResponse extends BaseResponseModelV2>(
    path: API,
    additionalPath: string = '',
  ): Observable<TResponse> {
    const fullPath = path.concat(additionalPath);

    return this._httpClient
      .get<TResponse>(this._baseUrl + fullPath, {
        headers: this.getBuildHeader(),
      })
      .pipe(
        tap((x) => log(`[sendGetWithoutEncrypt][Response][${path}]`, x)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(
            new BaseResponseModelV2(
              err.status,
              `System Error: ${err.status}`,
            ) as TResponse,
          );
        }),
      );
  }

  public sendPost<
    TRequest extends BaseRequestModel,
    TResponse extends BaseResponseModel,
  >(path: API, model: TRequest): Observable<TResponse> {
    const encryptedRequest = this.encryptBaseRequest(model);

    return this._httpClient
      .post<TResponse>(this._baseUrl + path, encryptedRequest, {
        headers: this.postBuildHeader(),
      })
      .pipe(
        tap((x) => log(`[sendPost][Response][${path}]`, x)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(
            new BaseResponseModel(
              err.status,
              `System Error: ${err.status}`,
            ) as TResponse,
          );
        }),
      );
  }

  public sendPostV2<TRequest, TResponse extends BaseResponseModelV2>(
    path: API,
    model: TRequest,
  ): Observable<TResponse> {
    return this._httpClient
      .post<TResponse>(this._baseUrl + path, model, {
        headers: this.postBuildHeaderV2(),
      })
      .pipe(
        tap((x) => log(`[sendPostNoEncryptPost][Response][${path}]`, x)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(
            new BaseResponseModelV2(
              err.status,
              `System Error: ${err.status}`,
            ) as TResponse,
          );
        }),
      );
  }

  private encryptBaseRequest<TRequest extends BaseRequestModel>(
    model: TRequest,
  ) {
    model.Ip = this._baseService.currentSiteConfig.clientIp.ip;
    model.TimeSpan = this._baseService.timeSpan;
    model.Signature = this._encryptionService.generateSignature(model);

    log('Request', model);
    return this._encryptionService.AESEncrypt(JSON.stringify(model));
  }

  getBuildHeader(encryptedRequest?: string): HttpHeaders {
    let header = new HttpHeaders();
    const userInfo: PlayerInfo = getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );

    if (userInfo) {
      header = header.append('Authorization', `Bearer ${userInfo.authToken}`);
    }

    if (encryptedRequest) {
      header = header.append('GetParams', encryptedRequest);
    }

    return header;
  }

  postBuildHeader(): HttpHeaders {
    let header = new HttpHeaders({
      'Content-Type': 'text/plain',
    });
    const userInfo: PlayerInfo = getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );

    if (userInfo) {
      header = header.append('Authorization', `Bearer ${userInfo.authToken}`);
    }

    return header;
  }

  postBuildHeaderV2(): HttpHeaders {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const userInfo: PlayerInfo = getDetailFromStorage(
      StorageType.LOCAL_STORAGE,
      StorageKey.USER_INFO,
    );

    if (userInfo) {
      header = header.append('Authorization', `Bearer ${userInfo.authToken}`);
    }

    return header;
  }
}
