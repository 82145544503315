import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-range-filter',
  template: `
    <input
      [owlDateTime]="dt1"
      [owlDateTimeTrigger]="dt1"
      selectMode="range"
      [ngModel]="[defaultDate[0], defaultDate[1]]"
      (dateTimeChange)="onSelectDateRange($event)"
      readonly
      placeholder="Select a range"
    />
    
    <owl-date-time
      #dt1
      pickerMode="dialog"
      pickerType="calendar"
    ></owl-date-time>
  `,
  styles: [`
    input {
        padding: 12px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid var(--color-primary);
        color: black;
        outline: unset;
        cursor: pointer;
        box-shadow: 0 0 1px #0000000a,0 4px 12px #00000014;
    }

    ::placeholder {
        color: var(--color-primary);
    }
  `],
})
export class DateRangeFilterComponent {
  @Input() defaultDate: [Date, Date] = [new Date(), new Date()];
  @Input() maxDatePreserved = 99;
  @Output() dateTimeChange = new EventEmitter<[Date, Date]>();

  onSelectDateRange(selectedDetail: any) {
    if (selectedDetail.value) {
      const daysBetweenDate = this.checkDaysBetweenByDate(
        selectedDetail.value[0],
        selectedDetail.value[1],
      );

      if (daysBetweenDate <= this.maxDatePreserved) {
        this.dateTimeChange.emit([
          selectedDetail.value[0],
          selectedDetail.value[1],
        ]);
      } else {
        const endDate = new Date();
        endDate.setDate(
          new Date(selectedDetail.value[0]).getDate() + this.maxDatePreserved,
        );

        this.dateTimeChange.emit([selectedDetail.value[0], endDate]);
      }
    }
  }

  checkDaysBetweenByDate(date1: Date, date2: Date) {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
  }
}
