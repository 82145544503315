import { IAppConfig } from './app-config-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable()
export class AppConfig {
  static appSettings: IAppConfig;

  constructor(private http: HttpClient) {}

  async load() {
    // const encrytredTextFilePath = 'assets/config/appconfig.enrypt.txt';
    const encrytredTextFilePath = 'assets/config/appconfig.json';

    const response: any = await this.http
      .get(encrytredTextFilePath, { responseType: 'text' })
      .toPromise();
    AppConfig.appSettings = JSON.parse(response);

    // let decryptResponse = ToolsService.RSADecryption(
    //   response,
    //   TemplatePublicKey.DEMO_APP_CofingKey,
    // );
    // if (decryptResponse) {
    //   AppConfig.appSettings = JSON.parse(decryptResponse);
    //   console.log(AppConfig.appSettings);
    // } else {
    //   console.log('error config');
    // }
  }
}
