import { CommissionStatusEnum } from "./enums";

export class BaseRequestModel {
  TimeSpan? = 0;
  Ip? = '';
  Signature? = '';
}

export class TokenRequestModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
  IsGameRecord = false;
  PhoneNumber = '';
}

export class PromotionListReqModel extends BaseRequestModel {
  PromotionCategory = 0;
  GameCategory = 0;
  Language = 0;
  LanguageStr = '';
}

export class SlotOptionRequestModel extends BaseRequestModel {
  GameName = '';
  Language = 0;
  LangStr = '';
  PageNum = 1;
  PageSize = 0;
  Type = 0;
  GameTypeString = '';
  Game = '';
  TotalCount = 0;
  Pagination = 0;
}

export class GameLoginReqModel extends BaseRequestModel {
  GameType = 0;
  GameTypeString = '';
  GameCategory = 0;
  Language = 0;
  SlotCode = '';
  IsMobile = false;
}

export class BalanceTransferReqModel extends BaseRequestModel {
  Amount = 0;
  TransferAll = false;
}

export class GameRecordRequestModel extends BaseRequestModel {
  PageNum = 1;
  StartDate = '';
  EndDate = '';
  totalCount = 0;
  pagination = 0;
  pageSize = 25;
  selectedDate = 3;
  CategoryType = 0;
  CategoryTypeString = '';
  GameType = 0;
  GameTypeString = '';
}

export class LoginRequestModel extends BaseRequestModel {
  PlayerName = '';
  Password = '';
}

export class MainPageContentModel extends BaseRequestModel {
  Language: any;
  LanguageStr: any;
}

export class RegisterReqModel extends BaseRequestModel {
  PlayerName = '';
  Password = '';
  TransPassword = '';
  ReferCode = '';
  Email = '';
  Mobile = '';
  RealName = '';
  CurrencyCode = '';
  WeChat = '';
  Sex = '';
  Birth = '';
  Remark = '';
  ReferUrl = '';
  Source = '';
  BankName = '';
  BankAccountNo = '';
  AccountName = '';
  ICardNo = '';
  BankCode = '';
  AffliateType = '';
}

export class PasswordReqModel extends BaseRequestModel {
  Password = '';
  ConfirmPassword = '';
  NewPassword = '';
}

export class EditUserInfoReqModel extends BaseRequestModel {
  birth = '';
  currency = '';
  email = '';
  mobile = '';
  playerName = '';
  rate = 0;
  realName = '';
  sex = '';
  isRealNameBind = false;
  isEmailBind = false;
  isMobileBind = false;
}

export class BankDetailReqModel {
  bankName = '';
  bankAccountNo = '';
  accountName = '';
  iCardNo = '';
  currency = '';
  branch = '';
  city = '';
  province = '';
  remark = '';
  countryCode = '';
  bankNameIsBind = false;
  bankAccountNoIsBind = false;
  accountNameIsBind = false;
  icardNoIsBind = false;
  branchIsBind = false;
  cityIsBind = false;
  provinceIsBind = false;
}

export class DepositRequestModel extends BaseRequestModel {
  Id = '';
  TransPassword = '';
  bankId = '';
  bankName = '';
  bankAccNo = '';
  bankAccName = '';
  Amount = 0;
  ReferNo = '';
  currency = '';
  Rate = 0;
  UploadFile = '';
  SumRateAmount = 0;
  Remark = '';
  PromotionId = '';
  isAccountAvaiable = false;
  BankSlipDate = '';
}

export class WithdrawProposalModel extends BaseRequestModel {
  TransPassword = '';
  ICardNo = '';
  Amount = 0;
  Balance = 0;
  Currency = '';
  Remark = '';
  Rate = 0;
  UploadFile = '';
  SumRateAmount = 0;
  BankName = '';
  AccountName = '';
  AccountNo = '';
  IsbankCard = false;
  ThirdPartyPaymentType = 0;
}

export class TransactionRecordRequestModel extends BaseRequestModel {
  Type = 0;
  SelectedDate = 3;
  ProposalTypeString = '';
  Status = 1;
  PageNum = 1;
  PageSize = 25;
  StartDate = '';
  EndDate = '';
  TotalCount = 0;
  Pagination = 0;
}

export class SubscribePromotionModel {
  promotionId = '';
  referNo = '';
  promotionLock = false;
  isManualPromotionSubscribe = false;
}

export class SubscribePromotionListModel extends BaseRequestModel {
  PromotionStatusEnum = 'All';
  PageSize = 25;
  PageNum = 1;
  StartDate = '';
  EndDate = '';
  DaySelected = 3;
  Pagination = 0;
  TotalCount = 0;
}

export class PaymentOrderModel {
  PlayerBankAccountNo = '';
  Amount = 0;
  PageUrl = '';
  ThirdPartyPaymentType = 0;
  IsMobile = false;
  Currency = '';
  Rate = 1;
  SumRateAmount = 0;
  BankCode = '';
  CardHolderName = '';
}

export class FormPostLogin {
  domainName = '';
  postElement: PostElement[];
}

export class PostElement {
  key = '';
  value = '';
}

export class GameCategory {
  name = '';
  categoryType = 0;
  gameCategoryName = '';
  gameLists: GameDetail[];
  gameTypeCodeList: GameDetail[];
}

export class GameDetail {
  gameName = '';
  gameType = 0;
}

export class JwtTokenModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class GetGameCategoryListModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class GetGameBalanceListModel extends BaseRequestModel {
  Category = 0;
  CategoryString = '';
}

export class PlayerDetailModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class BankListModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class GetBankAccountModel extends BaseRequestModel {
  BankId = '';
}

export class RebateSubscribeReqModel extends BaseRequestModel {
  PromotionId = '';
  IsManualPromotionSubscribe = true;
}

export class DownLineListModel {
  startDate: string;
  endDate: string;
  pageNumber = 1;
  pageSize = 25;
  totalCount = 0;
  pagination = 0;
}

export class CommissionListModel {
  startDate: string;
  endDate: string;
  pageNumber = 1;
  pageSize = 25;
  totalCount = 0;
  pagination = 0;
}

export class CommissionClaimModel {
  RewardID: string;
  RewardNo: string;
  DetailNo: string;
  Currency: string;
  Rate: number;
  Ip: string;
}

export class CurrentCommisionAndRankingModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
  IsGameRecord = false;
}

export class AffLevelSettlementList extends BaseRequestModel {
  CommissionStatus: CommissionStatusEnum
  PageNum = 1;
  PageSize = 5;
  OrderBy = '';
  Asc = false;
}

export class AffiliateLevelDetailModel extends BaseRequestModel {
  AffliateComId = '';
}

export class AffCommissionItemModel extends BaseRequestModel {
  AffCommissionId = '';
}

export class AffiliatePlayerListModel extends BaseRequestModel {
  StartDate = '';
  EndDate = '';
  AffPlayerName = '';
  PageNum = 1;
  PageSize = 15;
  OrderBy = '';
  Asc = false;
}

export class OtpModel {
  phone = '';
  appid = '';
  timestamp = 0;
  signcode = '';
}

export class OtpVerifyModel {
  phone = '';
  otpkey = '';
  otptoken = '';
  appid = '';
  timestamp = 0;
  signcode = '';
}

export class PaydutyWithdrawModel extends BaseRequestModel {
  Currency = '';
  Rate = 0;
  Amount = 0;
  TransPassword = '';
  SumRateAmount = 0;
  Remark = '';
  ThirdPartyPaymentType = 0;
}

export class PaydutyRegisterModel extends BaseRequestModel {
}

export class PaydutyUpdateModel extends BaseRequestModel {
}

export class PaydutyCheckTmpProposalModel extends BaseRequestModel {
  Language = 0;
  LanguageStr = '';
}

export class PaydutySubscribePromotionModel extends BaseRequestModel {
  PCode = '';
  PromotionId = '';
}

export class PaydutyBankListModel {
  appid = '';
  timestamp = 0;
  signcode = '';
}

export class CheckPhoneNumberModel extends BaseRequestModel {
  PhoneNumber = '';
}

export class PaydutyBankVerifyModel {
  appid = '';
  timestamp = 0;
  signcode = '';
  bank = '';
  // tslint:disable-next-line: variable-name
  bank_no = '';
}

export class GetPlayerTurnOverModel extends BaseRequestModel {
  StartDate = '';
  EndDate = '';
  PageNum = 1;
  totalCount = 0;
  pagination = 0;
  pageSize = 15;
}

export class ClaimTurnOverModel extends BaseRequestModel {
  TurnOverId = '';
  PlayerName = '';
}

export class PlayerLoginSSO extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class PinCodeModel extends BaseRequestModel {
  PlayerName = '';
  PinCode = '';
  Action = '';
}

export class SmsModel extends BaseRequestModel {
  RequestID = '';
  SMSType = 0;
  CallBackLink = '';
}

export class VerifyRegisterOtpModel extends BaseRequestModel {
  PhoneNumber = '';
  SessionToken = '';
  OTPCode = '';
}

export class SMSResetPlayerPasswordModel extends BaseRequestModel {
  PlayerName = '';
  OTPCode = '';
  ConfirmPassword = '';
  NewPassword = '';
}

export class SubscribeFirsTimePromotionModel extends BaseRequestModel {
  PlayerId = '';
  PlayerName = '';
}

export class CreateAndUpdateReviewsModel extends BaseRequestModel {
  Reviews = '';
  Rate = 0;
}

export class ReviewListModel extends BaseRequestModel {
  PageNum = 1;
  totalCount = 0;
  pagination = 0;
  PageSize = 15;
}

export class GetLoseRebateModel extends BaseRequestModel {
  StartDate = '';
  EndDate = '';
  PageNum = 1;
  totalCount = 0;
  pagination = 0;
  pageSize = 15;
}

export class ClaimLoseRebateModel extends BaseRequestModel {
  TurnOverId = '';
  PlayerName = '';
}

export class HermesRegisterModel extends BaseRequestModel {
  BankId = 0;
  BankAccountNumber = '';
  BankAccountName = '';
}

export class HermesVerifyBankAccountModel extends BaseRequestModel {
  BankId = 0;
  BankAccountNumber = '';
}

export class HermesUpdateAccountModel extends BaseRequestModel {
  BankId = 0;
  BankAccountNumber = '';
  BankAccountName = '';
  TelephoneNumber = '';
}

export class HermesWithdrawModel extends BaseRequestModel {
  BankCode = '';
  ToAccountName = '';
  ToAccountNumber = '';
  ICardNo = '';
  TransPassword = '';
  Currency = '';
  Rate = 0;
  SumRateAmount = 0;
  Amount = 0;
  Remark = '';
}

export class HermesAccountModel {
  BankId = 0;
  BankAccountNumber = '';
  BankAccountName = '';
  TelephoneNumber = '';
}
