import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { AppConfig } from './app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CopyDirective } from './app.component';
import { AuthModalComponent } from './components/auth-modal/auth-modal.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { DepositModalComponent } from './components/deposit-modal/deposit-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password-modal.component';
import { GameModalComponent } from './components/game-modal/game-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PopupComponent } from './components/popup/popup.component';
import { PostGameModalComponent } from './components/post-game-modal/post-game-modal.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';
import { PromotionDetailModalComponent } from './components/promotion-detail-modal/promotion-detail-modal.component';
import { SlotModalComponent } from './components/slot-modal/slot-modal.component';
import { WithdrawModalComponent } from './components/withdraw-modal/withdraw-modal.component';
import { ShareModule } from './share/share.module';

@NgModule({
  declarations: [
    AppComponent,
    CopyDirective,
    MessageModalComponent,
    GameModalComponent,
    PostGameModalComponent,
    PromotionDetailModalComponent,
    NavbarComponent,
    FooterComponent,
    ForgotPasswordModalComponent,
    ChangePasswordModalComponent,
    ProfileModalComponent,
    SlotModalComponent,
    AuthModalComponent,
    DepositModalComponent,
    WithdrawModalComponent,
    PopupComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ShareModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    ClipboardModule,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageModalComponent,
    GameModalComponent,
    PostGameModalComponent,
    PromotionDetailModalComponent,
    FooterComponent,
    NavbarComponent,
    ForgotPasswordModalComponent,
    ChangePasswordModalComponent,
    ProfileModalComponent,
    SlotModalComponent,
    AuthModalComponent,
    DepositModalComponent,
    WithdrawModalComponent,
    PopupComponent,
  ],
})
export class AppModule {}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
