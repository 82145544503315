import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  GameLoginReqModel,
  SlotOptionRequestModel,
  PaydutySubscribePromotionModel,
} from 'src/app/model/request';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { BaseService } from 'src/app/services/base.service';
import { GameService } from 'src/app/services/game.service';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-slot-modal',
  templateUrl: './slot-modal.component.html',
  styleUrls: ['./slot-modal.component.scss'],
})
export class SlotModalComponent implements OnInit {
  @Input() data;
  @Input() model;

  public slotOptionRequestModel = new SlotOptionRequestModel();
  public slotGameList: any;
  public PaydutySubscribePromotionModel = new PaydutySubscribePromotionModel();

  public faAngleDoubleLeft = faAngleDoubleLeft;
  public faAngleDoubleRight = faAngleDoubleRight;
  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;
  public faTimes = faTimes;
  public headImage = '';
  public DomainUrl = window.location.origin;

  constructor(
    public bsRef: BsModalRef,
    public baseServ: BaseService,
    public gameServ: GameService,
    public alertServ: AlertService,
    public GeneralServ: GeneralService,
  ) {}

  ngOnInit() {
    this.slotOptionRequestModel = this.model;
    this.slotGameList = this.data;

    if (this.slotOptionRequestModel.Game === 'JokerSingleTHB') {
      this.headImage = '../../../assets/imgs/banner/joker.webp';
    } else if (this.slotOptionRequestModel.Game === 'PragmaticPlayTHB') {
      this.headImage = '../../../assets/imgs/banner/pp.webp';
    } else if (this.slotOptionRequestModel.Game === 'KingMaker') {
      this.headImage = '../../../assets/imgs/banner/km.webp';
    } else if (this.slotOptionRequestModel.Game === 'Relax') {
      this.headImage = '../../../assets/imgs/banner/relax.webp';
    } else if (this.slotOptionRequestModel.Game === 'YGG') {
      this.headImage = '../../../assets/imgs/banner/ygg.webp';
    } else if (this.slotOptionRequestModel.Game === 'Gamatron') {
      this.headImage = '../../../assets/imgs/banner/gamatron.webp';
    } else if (this.slotOptionRequestModel.Game === 'AdvantPlay') {
      this.headImage = '../../../assets/imgs/banner/adp.webp';
    } else if (this.slotOptionRequestModel.Game === 'AmbSlotTHB') {
      this.headImage = '../../../assets/imgs/banner/amb.webp';
    } else if (this.slotOptionRequestModel.Game === 'MGPlusTHB') {
      this.headImage = '../../../assets/imgs/banner/mg.webp';
    } else if (this.slotOptionRequestModel.Game === 'BluePrintTHB') {
      this.headImage = '../../../assets/imgs/banner/bp.webp';
    } else if (this.slotOptionRequestModel.Game === 'NlcTHB') {
      this.headImage = '../../../assets/imgs/banner/nlc.jpg';
    } else if (this.slotOptionRequestModel.Game === 'QMRedtigerTHB') {
      this.headImage = '../../../assets/imgs/banner/red.png';
    }
  }

  gameLogin(item, loadingUrl: string, e?) {
    if (this.baseServ.isLogin) {
      localStorage.setItem('loadingUrl', loadingUrl);

      if (e) {
        if (this.gameServ.tmpGamePage) {
          this.gameServ.tmpGamePage.close();
          this.gameServ.tmpGamePage = window.open(
            this.DomainUrl + '/loading',
            'gamepage',
          );
        } else {
          this.gameServ.tmpGamePage = window.open(
            this.DomainUrl + '/loading',
            'gamepage',
          );
        }
      } else {
        if (this.gameServ.tmpGamePage) {
          this.gameServ.tmpGamePage.close();
        }
      }

      this.openGameInNewTab(item);
    } else {
      this.alertServ.showAlertMsg('msg.LoginGameWithoutLogin');
      window.scrollTo(0, 0);
    }
  }

  async openGameInNewTab(item) {
    this.baseServ.isLoading = true;

    if (item.type === 'Cq9THB') {
      item.type = 'Cq9SlotTHB';
    }
    const gameModal = new GameLoginReqModel();
    gameModal.GameCategory = this.baseServ.categoryTypeEnum('Slot');
    gameModal.GameType = this.baseServ.GameTypeEnum(item.type);
    gameModal.GameTypeString = item.type;
    gameModal.SlotCode = item.gameCode;
    const data = await this.gameServ.loginGameAsync(gameModal);
    if (this.baseServ.checkTokenResponseStatus(data)) {
      const amount = 0;
      this.baseServ.setWalletDetail(amount);
      const tmpContent = {
        gameName: item.gameName,
        gamelink: data.content.loginUrl,
      };
      if (item.type.includes('Joker')) {
        this.baseServ.showFormPostGameModal(tmpContent);
        this.bsRef.hide();
      } else {
        this.gameServ.tmpGamePage = window.open(
          data.content.loginUrl,
          'gamepage',
        );
        this.bsRef.hide();
      }
    } else {
      this.gameServ.tmpGamePage.close();
      this.baseServ.showAlert('msg.System', 'msg.' + data.msg);
    }

    setTimeout(() => {
      localStorage.removeItem('loadingUrl');
    }, 1000);

    this.baseServ.isLoading = false;
  }

  async getSlotGameList(isSeachGame?: boolean) {
    if (!isSeachGame) {
      this.baseServ.isLoading = true;
    }
    const response = await this.gameServ.getSlotGameListAsync(
      this.slotOptionRequestModel,
    );
    this.baseServ.isLoading = false;
    if (this.baseServ.checkTokenResponseStatus(response)) {
      if (response.content.content.length > 0) {
        this.slotGameList = response.content.content;
        this.slotOptionRequestModel.TotalCount = response.content.totalCount;
        this.slotOptionRequestModel.Pagination = Math.ceil(
          this.slotOptionRequestModel.TotalCount /
            this.slotOptionRequestModel.PageSize,
        );
      }
    }
  }

  handleHideModal() {
    this.bsRef.hide();
    localStorage.removeItem('loadingUrl');
  }
}
