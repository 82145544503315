import {
  GameCategoryEnum,
  GameLanguageEnum,
  PaymentGatewayEnum,
  PopupBannerContentTypeEnum,
  PromotionCategoryEnum,
  PromotionStatusEnum,
} from './enums';
import { BaseRequestModel } from './request';

export abstract class ListBaseRequest extends BaseRequestModel {
  PageNum: number = 1;
  PageSize: number = 5;
}

export class ManualWithdrawRequest extends BaseRequestModel {
  Amount: string;
  UploadFile: string;
  TransPassword: string;
  Currency: string;
  Rate: string;
  SumRateAmount: string;
  Remark: string;
  ThirdPartyPaymentType: number;
}

export class PaymentGatewayWithdrawRequest extends BaseRequestModel {
  GateWayId: string;
  GatewayCode: PaymentGatewayEnum;
  BankCode: string;
  AccountName: string;
  AccountNumber: string;
  Amount: number;
  Rate: number;
  Remark: string;
  PrefixNo: string = ''; // internal use
  CrytoCode: string = ''; // virtual currency
  ReceiveAddress: string = '';
  TransPass: string = '123456';
}

export class PromotionListRequest extends ListBaseRequest {
  PromotionCategory: PromotionCategoryEnum;
  GameCategory: GameCategoryEnum;
  Language: GameLanguageEnum;
  LanguageStr: string;
}

export class ItemRequest extends BaseRequestModel {
  ItemId: string;
}

export class PromotionSubscribeRequest extends BaseRequestModel {
  PromotionId: string;
  IsFirstTimeMode: boolean;
  Category: PromotionCategoryEnum;
  IsManualPromotionSubscribe: boolean = false;
  PlayerRespModel: any; // TblPlayer
  ConfigList: any[] = []; // IEnumberable<TblConfig>
}

export class PromotionSubscribeListRequest extends ListBaseRequest {
  PromotionStatus: PromotionStatusEnum;
  StartDate: string;
  EndDate: string;
}

export class BindBankRequest extends BaseRequestModel {
  BankName: string;
  BankCode: string;
  BankAccountNo: string;
  AccountName: string;
  ICardNo: string = '';
  CountryCode: string = 'THB';
}

export class PopUpBannerRequest extends BaseRequestModel {
  PositionType: PopupBannerContentTypeEnum;
  TimeZone: number = 7;
  ImageLink: string = '';
}
