import { HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  HttpHeaderConfig,
  ResponseCode,
  StorageKey,
  StorageType,
} from '../model/enums';
import {
  AffCommissionItemModel,
  AffiliateLevelDetailModel,
  AffiliatePlayerListModel,
  AffLevelSettlementList,
  CheckPhoneNumberModel,
  ClaimLoseRebateModel,
  ClaimTurnOverModel,
  CommissionClaimModel,
  CommissionListModel,
  CurrentCommisionAndRankingModel,
  DepositRequestModel,
  DownLineListModel,
  EditUserInfoReqModel,
  GetLoseRebateModel,
  GetPlayerTurnOverModel,
  LoginRequestModel,
  OtpModel,
  OtpVerifyModel,
  PasswordReqModel,
  PaydutyBankListModel,
  PaydutyBankVerifyModel,
  PaymentOrderModel,
  PinCodeModel,
  PlayerDetailModel,
  PlayerLoginSSO,
  RebateSubscribeReqModel,
  RegisterReqModel,
  SmsModel,
  SMSResetPlayerPasswordModel,
  SubscribeFirsTimePromotionModel,
  SubscribePromotionListModel,
  SubscribePromotionModel,
  TokenRequestModel,
  TransactionRecordRequestModel,
  VerifyRegisterOtpModel,
  WithdrawProposalModel,
} from '../model/request';
import { BindBankRequest } from '../model/request.v3';
import {
  BankListResponse,
  BankVerifyResponse,
  OtpResponse,
  PlayerInfo,
  ResponseMessage,
} from '../model/response';
import {
  AffiliateList,
  AffPlayerList,
  BaseResponseModel,
  CurrentNewAffDetail,
  NewAffiliateSummary,
  V3PlayerInfo,
} from '../model/response.v3';
import { RequestApiLink } from '../model/static';
import { BaseService } from './base.service';
import { EncryptionService } from './encryption.service';
import { HttpService } from './http.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  public baseServ = inject(BaseService);
  public _http = inject(HttpService);
  public encryptServ = inject(EncryptionService);

  constructor() {}

  async CheckPlayerNameExist(playerName: string): Promise<BaseResponseModel> {
    const request = new TokenRequestModel();
    request.PlayerName = playerName;

    return this._http
      .sendGet<TokenRequestModel, BaseResponseModel>(
        '/v3/Player/CheckPlayerNameExist',
        request,
      )
      .toPromise();
  }

  async CheckPhoneNumberExist(phoneNo: string): Promise<BaseResponseModel> {
    const request = new TokenRequestModel();
    request.PhoneNumber = phoneNo;

    return this._http
      .sendGet<TokenRequestModel, BaseResponseModel>(
        '/v3/Player/CheckPhoneNumberExist',
        request,
      )
      .toPromise();
  }

  async CheckRegisterVerifyBank(): Promise<BaseResponseModel<boolean>> {
    const request = new TokenRequestModel();
    request.PlayerId = this.baseServ.playerInfo.playerId || '';
    request.PlayerName = this.baseServ.playerInfo.playerName || '';

    return this._http
      .sendGet<TokenRequestModel, BaseResponseModel<boolean>>(
        '/v3/Player/CheckRegisterVerifyBank',
        request,
      )
      .toPromise();
  }

  // lose rebate
  async GetLoseRebateAsync(
    model: GetLoseRebateModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.StartDate = ToolsService.calculateDateBetween(
          new Date(),
          false,
          30,
        );
        model.EndDate = ToolsService.calculateDateBetween(new Date(), true);

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan + model.Ip + this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_GET_LOSE_REBATE,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetLoseRebateAsync(model);
      }
    }

    return response;
  }

  async ClaimLoseRebateAsync(
    model: ClaimLoseRebateModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.PlayerName = this.baseServ.playerInfo.playerName;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.TurnOverId +
            model.PlayerName +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_CLAIM_LOSE_REBATE,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.ClaimLoseRebateAsync(model);
      }
    }

    return response;
  }
  // lose rebate

  async SubscribeFirsTimePromotion(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const model = new SubscribeFirsTimePromotionModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.PlayerName +
            model.PlayerId +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PROMOTION_SUBSCRIBE_FIRST_TIME,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.JSON_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(
          ResponseCode.Error,
          'Error:HttpPostRequest; service:SubscribeFirsTimePromotion',
        );
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.SubscribeFirsTimePromotion();
      }
    }

    return response;
  }

  async CallSms(model: SmsModel): Promise<ResponseMessage> {
    let response: any;

    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

      model.Signature = ToolsService.signature(
        this.baseServ.timeSpan +
          model.Ip +
          model.RequestID +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_PLAYER_SMS,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }

    return response;
  }

  async VerifyRegisterOtp(
    model: VerifyRegisterOtpModel,
  ): Promise<ResponseMessage> {
    let response: any;

    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

      model.Signature = ToolsService.signature(
        this.baseServ.timeSpan +
          model.Ip +
          model.PhoneNumber +
          model.OTPCode +
          model.SessionToken +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_PLAYER_VERIFY_REGIS_OTP,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }

    return response;
  }

  async SMSResetPlayerPassword(
    model: SMSResetPlayerPasswordModel,
  ): Promise<ResponseMessage> {
    let response: any;

    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

      model.Signature = ToolsService.signature(
        this.baseServ.timeSpan +
          model.Ip +
          model.PlayerName +
          model.OTPCode +
          model.NewPassword +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_PLAYER_SMS_RESET_PASS,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }

    return response;
  }

  async PinCode(model: PinCodeModel): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.PlayerName = this.baseServ.playerInfo.playerName;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.PlayerName +
            model.PinCode +
            model.Action +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_PIN_CODE,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.PinCode(model);
      }
    }

    return response;
  }

  // player login sso
  async PlayerLoginSSO(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const model = new PlayerLoginSSO();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.PlayerId = this.baseServ.playerInfo.playerId;
        model.PlayerName = this.baseServ.playerInfo.playerName;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.PlayerName +
            model.PlayerId +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_LOGIN_SSO,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.PlayerLoginSSO();
      }
    }

    return response;
  }

  async GetPlayerTurnOverAsync(
    model: GetPlayerTurnOverModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.StartDate = ToolsService.calculateDateBetween(
          new Date(),
          false,
          30,
        );
        model.EndDate = ToolsService.calculateDateBetween(new Date(), true);

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan + model.Ip + this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_GET_TURNOVER,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetPlayerTurnOverAsync(model);
      }
    }

    return response;
  }

  async ClaimTurnOverAsync(
    model: ClaimTurnOverModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.TurnOverId +
            model.PlayerName +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_CLAIM_TURNOVER,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.ClaimTurnOverAsync(model);
      }
    }

    return response;
  }

  async CheckPhoneNumberAsync(
    model: CheckPhoneNumberModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.PhoneNumber +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_PLAYER_CHECK_PHONE_NUMBER,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  // payduty bank verify
  async getPaydutyBankVerifyAsync(
    model: PaydutyBankVerifyModel,
  ): Promise<BankVerifyResponse> {
    let response: any;
    try {
      model.appid = this.baseServ.e('2').join('');
      model.timestamp = new Date().getTime();
      model.signcode = ToolsService.md5(
        model.appid +
          model.timestamp.toString() +
          this.baseServ.e('3').join(''),
      ).toUpperCase();

      let params = new HttpParams();
      params = params.set('appid', model.appid);
      params = params.set('timestamp', model.timestamp.toString());
      params = params.set('signcode', model.signcode);
      params = params.set('bank', model.bank);
      params = params.set('bank_no', model.bank_no);

      response = await this.baseServ.http
        .post<any>(RequestApiLink.PAYDUTY_BANK_VERIFY, params, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .toPromise();
    } catch (error) {
      return new BankVerifyResponse(false, 'SystemBusy');
    }

    return response;
  }
  // payduty bank verify

  // payduty bank list
  async getPaydutyBankListAsync(): Promise<BankListResponse> {
    let response: any;
    try {
      const model = new PaydutyBankListModel();
      model.appid = this.baseServ.e('2').join('');
      model.timestamp = new Date().getTime();
      model.signcode = ToolsService.md5(
        model.appid +
          model.timestamp.toString() +
          this.baseServ.e('3').join(''),
      ).toUpperCase();

      let params = new HttpParams();
      params = params.set('appid', model.appid);
      params = params.set('timestamp', model.timestamp.toString());
      params = params.set('signcode', model.signcode);

      response = await this.baseServ.http
        .post<any>(RequestApiLink.PAYDUTY_BANK_LIST, params, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .toPromise();
    } catch (error) {
      return new BankListResponse(false, 'SystemBusy');
    }
    return response;
  }
  // payduty bank list

  // start otp
  async getOtpAsync(model: OtpModel): Promise<OtpResponse> {
    let response: any;
    try {
      model.appid = this.baseServ.e('2').join('');
      model.timestamp = new Date().getTime();
      model.signcode = ToolsService.md5(
        model.appid +
          model.timestamp.toString() +
          this.baseServ.e('3').join(''),
      ).toUpperCase();

      let params = new HttpParams();
      params = params.set('phone', model.phone);
      params = params.set('appid', model.appid);
      params = params.set('timestamp', model.timestamp.toString());
      params = params.set('signcode', model.signcode);

      response = await this.baseServ.http
        .post<any>(RequestApiLink.SKYSPORT_OTP, params, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .toPromise();
    } catch (error) {
      return new OtpResponse(false, 'SystemBusy');
    }
    return response;
  }

  async getOtpVerifyAsync(model: OtpVerifyModel): Promise<OtpResponse> {
    let response: any;
    try {
      model.appid = this.baseServ.e('2').join('');
      model.timestamp = new Date().getTime();
      model.signcode = ToolsService.md5(
        model.appid +
          model.timestamp.toString() +
          this.baseServ.e('3').join(''),
      ).toUpperCase();

      let params = new HttpParams();
      params = params.set('phone', model.phone);
      params = params.set('otpkey', model.otpkey);
      params = params.set('otptoken', model.otptoken);
      params = params.set('appid', model.appid);
      params = params.set('timestamp', model.timestamp.toString());
      params = params.set('signcode', model.signcode);

      response = await this.baseServ.http
        .post<any>(RequestApiLink.SKYSPORT_OTP_VERIFY, params, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        })
        .toPromise();
    } catch (error) {
      return new OtpResponse(false, 'SystemBusy');
    }
    return response;
  }
  // end otp

  // Start Affiliate
  // Affiliate and Member
  async GetCurrentAffLevelCommisionAndRanking(): Promise<
    BaseResponseModel<NewAffiliateSummary>
  > {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);
    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetCurrentAffLevelCommisionAndRanking();
      }
    }

    const model = new CurrentCommisionAndRankingModel();
    model.PlayerId = this.baseServ.playerInfo.playerId;
    model.PlayerName = this.baseServ.playerInfo.playerName;

    return await this._http
      .sendGet<
        CurrentCommisionAndRankingModel,
        BaseResponseModel<NewAffiliateSummary>
      >('/v3/NewAffliate/GetCurrentAffLevelCommisionAndRanking', model)
      .toPromise();
  }

  // Affiliate and Member
  async GetAffCommissionLevelSettlementList(
    model: AffLevelSettlementList,
  ): Promise<BaseResponseModel<AffiliateList>> {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);
    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetAffCommissionLevelSettlementList(model);
      }
    }

    return await this._http
      .sendGet<AffLevelSettlementList, BaseResponseModel<AffiliateList>>(
        '/v3/NewAffliate/GetAffCommissionLevelSettlementList',
        model,
      )
      .toPromise();
  }

  // Affiliate and Member
  async GetAffliateLevelComDetail(
    model: AffiliateLevelDetailModel,
  ): Promise<BaseResponseModel<CurrentNewAffDetail>> {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);

    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetAffliateLevelComDetail(model);
      }
    }

    return await this._http
      .sendGet<
        AffiliateLevelDetailModel,
        BaseResponseModel<CurrentNewAffDetail>
      >('/v3/NewAffliate/GetAffliateLevelComDetail', model)
      .toPromise();
  }

  // Affiliate and Member
  async GetAffPlayerSummaryDetailList(
    model: AffiliatePlayerListModel,
  ): Promise<BaseResponseModel<AffPlayerList>> {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);

    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.GetAffPlayerSummaryDetailList(model);
      }
    }

    return await this._http
      .sendGet<AffiliatePlayerListModel, BaseResponseModel<AffPlayerList>>(
        '/v3/NewAffliate/GetAffPlayerSummaryDetailList',
        model,
      )
      .toPromise();
  }

  // Affiliate and Member
  async ClaimAffCommission(
    id: string,
  ): Promise<BaseResponseModel<CurrentNewAffDetail>> {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);

    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.ClaimAffCommission(id);
      }
    }

    const model = new AffCommissionItemModel();
    model.AffCommissionId = id;

    return await this._http
      .sendPost<AffCommissionItemModel, BaseResponseModel<CurrentNewAffDetail>>(
        '/v3/NewAffliate/ClaimAffCommission',
        model,
      )
      .toPromise();
  }
  // End affiliate

  async getLatestSettlementAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('page', '1');
        // params = params.set('StartDate', model.startDate.toString());
        // params = params.append('EndDate', model.endDate.toString());
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_LATEST_SETTLEMENT,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getLatestSettlementAsync();
      }
    }

    return response;
  }

  async getRankListAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .get<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.GET_RANK_LIST,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                false,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getRankListAsync();
      }
    }

    return response;
  }

  async getDownLineListAsync(
    model: DownLineListModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('StartDate', model.startDate.toString());
        params = params.append('EndDate', model.endDate.toString());
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_DOWNLINE_LIST,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getDownLineListAsync(model);
      }
    }

    return response;
  }

  async getCommissionListAsync(
    model: CommissionListModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('startDate', model.startDate.toString());
        params = params.append('endDate', model.endDate.toString());
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_COMMISSION_LIST,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getCommissionListAsync(model);
      }
    }

    return response;
  }

  async getCommissionAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .get<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.GET_COMMISSION,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getCommissionAsync();
      }
    }

    return response;
  }

  async getMonthCommissionAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .get<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_MONTH_COMMISSION,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getMonthCommissionAsync();
      }
    }

    return response;
  }

  async commissionClaimAsync(
    model: CommissionClaimModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('RewardID', model.RewardID);
        params = params.set('RewardNo', model.RewardNo);
        params = params.set('DetailNo', model.DetailNo);
        params = params.set('Currency', model.Currency);
        params = params.set('Rate', model.Rate.toString());
        params = params.set('Ip', model.Ip);
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.COMMISSION_CLAIM,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.commissionClaimAsync(model);
      }
    }

    return response;
  }

  async getTurnOverAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const params = new HttpParams();
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.GET_TURNOVER,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getTurnOverAsync();
      }
    }

    return response;
  }

  async getPlayerDetailAsync(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const model = new PlayerDetailModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.PlayerId = this.baseServ.playerInfo.playerId;
        model.PlayerName = this.baseServ.playerInfo.playerName;

        model.Signature = ToolsService.signature(
          this.baseServ.timeSpan +
            model.Ip +
            model.PlayerName +
            model.PlayerId +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.V2_PLAYER_DETAIL,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getPlayerDetailAsync();
      }
    }
    return response;
  }

  getDetail() {
    const model = new TokenRequestModel();
    model.PlayerId = this.baseServ.playerInfo.playerId || '';
    model.PlayerName = this.baseServ.playerInfo.playerName || '';

    return this._http
      .sendGet<TokenRequestModel, BaseResponseModel<V3PlayerInfo>>(
        '/v3/Player/GetDetail',
        model,
      )
      .toPromise();
  }

  async loginAsync(model: LoginRequestModel): Promise<ResponseMessage> {
    let response: any;
    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.PlayerName +
          model.Password +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_PLAYER_LOGIN,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();

      if (response.status === ResponseCode.Normal) {
        const tmpUserInfo = new PlayerInfo();
        tmpUserInfo.playerId = response.content.id;
        tmpUserInfo.playerName = response.content.playerName;
        tmpUserInfo.mobile = response.content.mobile;
        tmpUserInfo.realName = response.content.realName;
        tmpUserInfo.sex = response.content.sex;
        tmpUserInfo.birth = response.content.birth;
        tmpUserInfo.currency = response.content.currency;
        tmpUserInfo.rate = response.content.rate;
        tmpUserInfo.amount = response.content.amount;
        tmpUserInfo.authToken = response.content.authToken;
        tmpUserInfo.bankAccountNo = response.content.bankAccountNo;
        tmpUserInfo.bankCode = response.content.bankCode;
        tmpUserInfo.bankName = response.content.bankName;
        tmpUserInfo.accountName = response.content.accountName;
        tmpUserInfo.pinCodeSet = response.content.pinCodeSet;

        this.baseServ.setOrRemoveItemStorage(
          true,
          StorageType.LOCAL_STORAGE,
          StorageKey.USER_INFO,
          tmpUserInfo,
        );
        this.baseServ.playerInfo = tmpUserInfo;
        this.baseServ.isLogin = true;
      }
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async registerAsync(
    model: RegisterReqModel,
  ): Promise<BaseResponseModel<V3PlayerInfo>> {
    return this._http
      .sendPost<RegisterReqModel, BaseResponseModel<V3PlayerInfo>>(
        '/v3/Player/Register',
        model,
      )
      .toPromise();
  }

  async changePasswordAsync(model: PasswordReqModel): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.Password +
            model.NewPassword +
            model.ConfirmPassword +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PLAYER_RESET_PASSWORD,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.changePasswordAsync(model);
      }
    }
    return response;
  }

  async editInfoAsync(model: EditUserInfoReqModel): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('RealName', model.realName);
        params = params.append('Mobile', model.mobile);
        params = params.append('Email', model.email);
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.UPDATE_PLAYER_DETAIL,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.editInfoAsync(model);
      }
    }
    return response;
  }

  async bindPlayerBankAccount(
    model: BindBankRequest,
  ): Promise<BaseResponseModel<V3PlayerInfo>> {
    const isValid: boolean = this.baseServ.CheckTokenTimeIsValid(20);
    if (!isValid) {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.bindPlayerBankAccount(model);
      }
    }

    return this._http
      .sendPost<BindBankRequest, BaseResponseModel<V3PlayerInfo>>(
        '/v3/Player/BindBankAccount',
        model,
      )
      .toPromise();
  }

  async generateDepositManualAsync(
    model: DepositRequestModel,
    id: string,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Id = model.bankId;
        model.Amount = model.Amount;
        model.SumRateAmount = model.SumRateAmount;
        model.PromotionId = id;

        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.Id +
            model.Amount +
            model.TransPassword +
            model.SumRateAmount +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_TRANSACTION_DEPOSIT,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.generateDepositManualAsync(model, id);
      }
    }

    return response;
  }

  async generateWithdrawProposalAsync(
    model: WithdrawProposalModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Amount = model.Amount;
        model.SumRateAmount = model.SumRateAmount;

        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.Amount +
            model.TransPassword +
            model.SumRateAmount +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_TRANSACTION_WITHDRAW,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.generateWithdrawProposalAsync(model);
      }
    }

    return response;
  }

  async getTransactionRecordAsync(
    model: TransactionRecordRequestModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.StartDate = ToolsService.calculateDateBetween(
          new Date(),
          false,
          model.SelectedDate,
        );
        model.EndDate = ToolsService.calculateDateBetween(new Date(), true);
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.ProposalTypeString +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_TRANSACTION_HISTORY,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getTransactionRecordAsync(model);
      }
    }

    return response;
  }

  async subscribePromotionAsync(
    model: SubscribePromotionModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        params = params.set('PromotionId', model.promotionId);
        params = params.append('ReferNo', model.referNo);
        params = params.append(
          'IsManualPromotionSubscribe',
          model.isManualPromotionSubscribe.toString(),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.SUBSCRIBE_PROMOTION,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.subscribePromotionAsync(model);
      }
    }

    return response;
  }

  async getSubscribePromotionListAsync(
    model: SubscribePromotionListModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.StartDate = ToolsService.calculateDateBetween(
          new Date(),
          false,
          model.DaySelected,
        );
        model.EndDate = ToolsService.calculateDateBetween(new Date(), true);

        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.PageNum +
            model.PageSize +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PROMOTION_SUBSCRIBE_LIST,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getSubscribePromotionListAsync(model);
      }
    }

    return response;
  }

  async makeOrderPaymentGateWayAsync(
    model: PaymentOrderModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        let params = new HttpParams();
        const tmpAmount = model.Amount * 100;

        params = params.set('PlayerBankAccountNo', model.PlayerBankAccountNo);
        params = params.append('Amount', tmpAmount.toString());
        params = params.append(
          'PageUrl',
          this.defineSiteHostName(model.PageUrl),
        );
        params = params.append(
          'ThirdPartyPaymentType',
          model.ThirdPartyPaymentType.toString(),
        );
        params = params.append(
          'IsMobile',
          this.baseServ.getDeviceType().toString(),
        );
        params = params.append(
          'Ip',
          this.baseServ.currentSiteConfig.clientIp.ip,
        );
        params = params.append('Rate', '1.0');
        params = params.append('Currency', 'CNY');
        params = params.append('SumRateAmount', tmpAmount.toString());
        params = params.append('BankCode', model.BankCode);
        params = params.append('CardHolderName', model.CardHolderName);
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.MAKE_ORDER_PAYMENT_GATEWAY,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.makeOrderPaymentGateWayAsync(model);
      }
    }

    return response;
  }

  async SubscribeNoneDepositPromotion(
    model: RebateSubscribeReqModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.PromotionId +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PROMOTION_SUBSCRIBE_NONE_DEPOSIT,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.JSON_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.rebateSubsribe(model);
      }
    }

    return response;
  }

  async rebateSubsribe(
    model: RebateSubscribeReqModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain + RequestApiLink.REBATE_SUBSCRIBE,
            JSON.stringify(model),
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.JSON_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.rebateSubsribe(model);
      }
    }

    return response;
  }

  async creditSubsribe(
    model: RebateSubscribeReqModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.FREE_CREDIT_SUBSCRIBE,
            JSON.stringify(model),
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.JSON_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.creditSubsribe(model);
      }
    }

    return response;
  }

  // async EeziePayDeposit(model: EeizePayDepositModel) {
  //   let response: any;
  //   const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
  //   if (isValid) {
  //     try {
  //       model.Amount = Math.trunc(model.ShowAmount * 100);
  //       model.SumRateAmount = Math.trunc(model.ShowSumRateAmount * 100);
  //       model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
  //       if (this.baseServ.currentSiteConfig.deviceType === 1) {
  //         model.PageUrl = this.baseServ.redirectPage('web/paymentNotification');
  //       } else {
  //         model.PageUrl = this.baseServ.redirectPage(
  //           'phone/paymentNotification',
  //         );
  //       }
  //       model.PageUrl = this.baseServ.redirectPage('paymentnotification');
  //       response = await this.baseServ.http
  //         .post<any>(
  //           this.baseServ.appConfig.apiDomain + RequestApiLink.EEZiEPAY_DEPOSIT,
  //           JSON.stringify(model),
  //           {
  //             headers: this.baseServ.postBuildHeader(
  //               HttpHeaderConfig.JSON_POST_HEADER,
  //               true,
  //             ),
  //           },
  //         )
  //         .toPromise();
  //     } catch (error) {
  //       return new ResponseMessage(
  //         ResponseCode.Error,
  //         'SystemBusy',
  //       );
  //     }
  //   } else {
  //     const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
  //     if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
  //       return await this.EeziePayDeposit(model);
  //     }
  //   }
  //   return response;
  // }

  defineSiteHostName = (pageUrl): string => {
    const urlpage =
      location.protocol + '//' + location.hostname + '/' + pageUrl;
    return urlpage;
    // tslint:disable-next-line: semicolon
  };
}
