import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/services/base.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  LoginRequestModel,
  BalanceTransferReqModel,
} from 'src/app/model/request';
import { PlayerService } from 'src/app/services/player.service';
import { GeneralService } from 'src/app/services/general.service';
import { AlertService } from 'src/app/services/alert.service';
import { ForgotPasswordModalComponent } from 'src/app/components/forgot-password-modal/forgot-password-modal.component';
import { ChangePasswordModalComponent } from 'src/app/components/change-password-modal/change-password-modal.component';
import { ProfileModalComponent } from 'src/app/components/profile-modal/profile-modal.component';
import { EditUserInfoReqModel, PasswordReqModel } from 'src/app/model/request';
import { GameService } from 'src/app/services/game.service';
import { AuthModalComponent } from '../auth-modal/auth-modal.component';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { DepositModalComponent } from '../deposit-modal/deposit-modal.component';
import { WithdrawModalComponent } from '../withdraw-modal/withdraw-modal.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [DatePipe],
})
export class NavbarComponent implements OnInit {
  public currentTime: string;
  public loginModal = new LoginRequestModel();
  public playerDetail = new EditUserInfoReqModel();
  public passwordModel = new PasswordReqModel();
  public balanceTransferReqModel = new BalanceTransferReqModel();
  public scrolled = false;
  public faRedo = faRedo;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 0;
  }

  constructor(
    public datePipe: DatePipe,
    public router: Router,
    public baseServ: BaseService,
    public modalServ: BsModalService,
    public playerServ: PlayerService,
    public generalServ: GeneralService,
    public alertServ: AlertService,
    public gameServ: GameService,
  ) {
    this.currentTime = this.datePipe.transform(new Date(), 'HH:mm:ss');
    setInterval(() => {
      this.currentTime = this.datePipe.transform(new Date(), 'HH:mm:ss ');
    }, 1000);
  }

  async ngOnInit() {}

  async getallbalance() {
    this.baseServ.isLoading = true;

    this.balanceTransferReqModel.Amount = 0;
    this.balanceTransferReqModel.TransferAll = true;

    const response = await this.gameServ.gameTransactionAsync(
      this.balanceTransferReqModel,
    );

    if (this.baseServ.checkTokenResponseStatus(response)) {
      this.baseServ.setAllDetail(response);
      this.getPlayerDetail();
      this.alertServ.showAlertMsg('msg.' + response.msg);
      this.baseServ.isLoading = false;
    } else {
      this.getPlayerDetail();
      this.alertServ.showAlertMsg('msg.' + response.msg);
      this.baseServ.isLoading = false;
    }
  }

  async getPlayerDetail() {
    const data = await this.playerServ.getPlayerDetailAsync();
    if (this.baseServ.checkTokenResponseStatus(data)) {
      this.baseServ.setAllDetail(data);
    }
  }

  async login() {
    this.generalServ.baseServ.isLoading = true;
    const response = await this.playerServ.loginAsync(this.loginModal);
    if (this.playerServ.baseServ.checkTokenResponseStatus(response)) {
      // this.alertServ.showAlertMsg('msg.PlayerLoginSuccess');
      this.loginModal = new LoginRequestModel();
      this.router.navigateByUrl('member-center');
    } else {
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }

    this.generalServ.baseServ.isLoading = false;
  }

  handleAuth(type: string) {
    const objectMsg = { type };
    this.modalServ.show(AuthModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-auth modal-dialog-centered',
      initialState: objectMsg,
    });
  }

  handleForgot(type: string) {
    const objectMsg = { type };
    this.modalServ.show(ForgotPasswordModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-forgot modal-dialog-centered',
      initialState: objectMsg,
    });
  }

  handleChangePass(type: string) {
    const objectMsg = { type };
    this.modalServ.show(ChangePasswordModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-change-password modal-dialog-centered',
      initialState: objectMsg,
    });
  }

  handleProfile(type: string) {
    const objectMsg = { type };
    this.modalServ.show(ProfileModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-profile modal-dialog-centered',
      initialState: objectMsg,
    });
  }

  handleDeposit(type: string) {
    const objectMsg = { type };
    this.modalServ.show(DepositModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-deposit modal-dialog-centered',
      initialState: objectMsg,
    });
  }

  handleWithdraw(type: string) {
    const objectMsg = { type };
    this.modalServ.show(WithdrawModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-withdraw modal-dialog-centered',
      initialState: objectMsg,
    });
  }
}
