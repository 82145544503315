import { Injectable } from '@angular/core';
import { AES, MD5, HmacSHA256, enc, lib } from 'crypto-js';
import { environment } from '../../environments/environment';
import { log } from '../lib/util';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  get _key() {
    return enc.Base64.parse(environment.encKey);
  }

  get _randomIV() {
    return lib.WordArray.random(16);
  }

  public get paydutyId() {
    return this._e('2').join('');
  }

  public get paydutySignature() {
    return this._e('3').join('');
  }

  public AESEncrypt(data: string): string {
    const iv = this._randomIV;
    const encrypted = AES.encrypt(data, this._key, { iv });
    const model = { value: encrypted.toString(), iv: iv.toString(enc.Base64) };
    return enc.Utf8.parse(JSON.stringify(model)).toString(enc.Base64);
  }

  public md5(plaintext: string): string {
    return MD5(plaintext).toString(enc.Hex);
  }

  public generatePaydutySignCode(timespamp: number): string {
    return this.md5(
      this.paydutyId + String(timespamp) + this.paydutySignature,
    ).toUpperCase();
  }

  public generateSignature<T>(model: T): string {
    const sign = Object.entries(model)
      .filter(
        ([key, value]) =>
          value !== '' &&
          value !== undefined &&
          key !== 'Signature'
      )
      .sort(([k1, _], [k2, __]) => this._sortModel(k1, k2))
      .map(([key, value]) => `${key}=${value}&`)
      .concat([`Key=${this._e('0').join('')}`])
      .join('');
    log('sign', sign);

    return this._HmacSHA256Encrypt(sign);
  }

  public generateV2Signature(plaintext: string): string {
    const encryptText = HmacSHA256(
      plaintext + this._e('1').join(''),
      this._e('0').join(''),
    );
    return enc.Base64.stringify(encryptText).replace(' ', '+');
  }

  private _e(key: '0' | '1' | '2' | '3'): string[] {
    const z: Record<'0' | '1' | '2' | '3', string[]> = {
      '0': [
        // dev
        // '6', '8', 'n', 'X', 'R', '5', 'z', 'E', 'j', 'G', 'V', 'n', 't', 'R', 'l', 'z', 'E', 'm', '=', 'C', 'D', '8', 'A', 'p', 'c', 'u', 'T', 'x', 'b', '7', 'n', 'A',

        //tko
        // 'n','H','R','Y','G','F','2','l','S','D','a','F','s','G','p','a','E','g','m','o','n','4','r','9','L','g','2','C','r','D','2','1',

        //prod
        'p','y','j','4','o','U','J','6','Z','Q','X','z','S','u','1','r','g','e','M','P','2','B','r','e','S','k','S','e','S','D','H','B',
      ],
      '1': [
        //dev
        // '@','j','U','M','s','6','5','1','d','3','C','v','Q','!','i','M',

        //tko
        // 'f','7','E','G','h','u','e','m','Y','5','U','x','a','D','x','X',

        //prod
        'C','5','J','t','k','y','y','m','z','f','B','2','V','g','w','x',
      ],
      '2': ['p', 'a', 'r', 'i', 's', 'i', 'a', 'n'],
      '3': [
        '1','6','c','1','8','9','c','6','9','b','0','7','8','3','c','4','3','e','2','3','3','3','3','3','0','c','7','6','c','8','3','f',
      ],
    };
    return z[key] ? z[key] : [];
  }

  private _HmacSHA256Encrypt(data: string): string {
    return HmacSHA256(data, this._e('1').join(''))
      .toString(enc.Base64)
      .toLowerCase();
  }

  private _sortModel(k1: string, k2: string): 0 | 1 | -1 {
    if (k1 < k2) return -1;
    if (k1 > k2) return 1;
    return 0;
  }
}
