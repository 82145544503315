import { ChangeDetectorRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';
import { BaseRequestModel } from '../model/request';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseResponseModel } from '../model/response.v3';
import { ResponseStatus } from '../model/enums';

@Injectable({
  providedIn: 'root',
})
export class TableService<
  T extends BaseRequestModel & { PageNum: number },
  Response,
> {
  private total$ = new BehaviorSubject<number>(0);
  private req$ = new BehaviorSubject<T | null>(null);

  private readonly _baseServ = inject(BaseService);

  constructor() {}

  initialize(request: T) {
    this.req$.next(request);
  }

  getList(
    apiCallback: (req: T) => Observable<BaseResponseModel<Response> | null>,
  ): Observable<Response | null> {
    return this.req$.pipe(
      switchMap((request) => apiCallback(request)),
      filter((response) => response.Status === ResponseStatus.success),
      take(1),
      
      map((response) => response.Content),
    );
  }

  changeRequest(update: Partial<T>) {
    const currentReq = this.req$.value;
    if (currentReq) this.req$.next({ ...this.req$.value, ...update });
  }

  changePageNum(pageNum: number) {
    const currentReq = this.req$.value;
    if (currentReq && currentReq.PageNum !== pageNum)
      this.req$.next({ ...this.req$.value, PageNum: pageNum });
  }
}
