import { AffiliateTypeEnum, CommissionStatusEnum, GameCategoryEnum, PaymentGatewayEnum, PlayerStatusEnum, PopupBannerContentTypeEnum, PromotionCategoryEnum, PromotionStatusEnum, ProposalTypeEnum, ResponseStatus, UserTypeEnum } from "./enums";

export class BaseResponseModel<T = unknown> {
  Status: ResponseStatus;
  Msg: string = '';
  Content?: T;

  constructor(status: ResponseStatus, msg: string, content?: any) {
    this.Status = status;
    this.Msg = msg;
    this.Content = content;
  }
}

export interface NewAffiliateSummary {
  AllDetail: AffiliateSummaryDetail;
  CurrentAffDetail: CurrentNewAffDetail;
  RankingList: RankingSet[];
  TodayDetail: AffiliateSummaryDetail;
  TotalCommission: number;
}

export interface AffiliateSummaryDetail {
  ActivePlayer: number;
  TotalDeposit: number;
  TotalDepositCount: number;
  TotalDownline: number;
  TotalRegisterDeposit: number;
  TotalRegisterDepositCount: number;
}

export interface RankingSet {
  Id: string;
  FixCalculationType: string;
  FixRanking: RankingDetail;
  GeneralCalulationType: string;
  GeneralRankingList: RankingDetail[];
  LevelRankingList: FixRankingSetDetail[];
  RankSetType: number;
  RankTypeStr: string;
  RankingSetName: string;
}

export interface RankingDetail {
  CreateDate: string;
  GameCategory: number;
  GameCategoryStr: string;
  GameType: number;
  GameTypeStr: string;
  Percent: number;
  Ranking: number;
  RankingName: string;
  TargetWinLoseMax: number;
  TargetWinLoseMin: number;
  TotalActiveDownlines: number;
  TurnOverType: number;
  TurnOverTypeStr: string;
}

export interface FixRankingSetDetail {
  FixRankingId: string;
  CreateDate: string;
  IsShow: boolean;
  LastModifiedDate: string;
  Percent: number;
  RankingName: string;
}

export interface CurrentNewAffDetail {
  Id: string;
  SettlementId: string;
  ReferNo: string;
  AffPlayerName: string;
  DownlineCount: number;
  ValidBetAmount: number;
  Winlose: number;
  WinLoseBonus: number;
  TurnOverBonus: number;
  TotalBonus: number;
  PayoutWL: number;
  PayoutTO: number;
  TotalPayoutBonus: number;
  WLRankLevel: number;
  TORankLevel: number;
  WLPercent: number;
  TOPercent: number;
  OwnTOBonus: number;
  OwnWLBonus: number;
  Status: CommissionStatusEnum;
  AffType: AffiliateTypeEnum;
  DateRange: string;
  SumDownlineDetailList: DownlineDetail[];
}

export interface DownlineDetail {
  Level: number;
  WLPercent: number;
  TOPercent: number;
  DepositAmount: number;
  WithdrawAmount: number;
  BetAmount: number;
  ValidBetAmount: number;
  Winlose: number;
  WinLoseBonus: number;
  TurnOverBonus: number;
}

export interface AffiliateList {
  SettleAffComList: CurrentNewAffDetail[];
  TotalCount: number;
}

export interface AffPlayerList {
  List: AffPlayer[];
  TotalCount: number;
}

export interface AffPlayer {
  PlayerName: string;
  RegisterDate: string;
  RealName: string;
  Topup: boolean;
  Bet: number;
  WinLose: number;
  Deposit: number;
  Withdraw: number;
}

export interface V3PlayerInfo {
  Id: string;
  PlayerName: string;
  LastLoginDate: string;
  Status: PlayerStatusEnum;
  RealName: string;
  Email: string;
  Mobile: string;
  Wechat: string;
  Qq: string;
  Sex: string;
  Birth: string;
  CurrencyCode: string;
  WithdrawalMinimum: number;
  WithdrawalMaximum: number;
  BankName: string;
  BankCode: string;
  ICardNO: string;
  BankAccountNo: string;
  AccountName: string;
  Currency: string;
  City: string;
  Branch: string;
  CountryCode: string;
  Province: string;
  Amount: number;
  LockAmount: number;
  LockDateTime: string;
  AuthToken: string;
  LastLoginIp: string;
  PinCodeSet: boolean;
  LoginGameTime: string;
  ImgUrl: string;
  TurnOverAffExist: boolean;
  UserTypeList: UserTypeEnum[];
}

export interface PaymentGateway {
  id: string;
  gateWayCode: PaymentGatewayEnum;
  gateWayName: string;
  currencyCode: string;
  bankList: BankSupport[];
  paymentType: 0 | 1;
  minDeposit: number;
  maxDeposit: number;
  minWithdraw: number;
  maxWithdraw: number;
  depositShow: boolean;
  withdrawShow: boolean;
}

export interface BankSupport {
  bankName: string;
  bankCode: string;
  status: boolean;
  paymentType: ProposalTypeEnum;
  methodPaymentType: string;
  bankLogoUrl: string;
}

export interface PromotionAndCategory {
  GameCategoryList: GameCategory[];
  PromotionList: Promotion[];
}

export interface GameCategory {
  Name: string;
  CategoryType: GameCategoryEnum;
  GameTypeCodeList: GameTypeCode[];
}

export interface GameTypeCode {
  GameName: string;
  GameType: number;
  Status: boolean;
}

export interface Promotion {
  PromotionId: string;
  PromotionCategory: PromotionCategoryEnum;
  StartDate: string;
  EndDate: string;
  LanguageCode: string;
  LanguageName: string;
  PromotionName: string;
  ImageLink: string;
  Description: string;
  GameCategory: GameCategoryEnum;
  MinimumAmount: number;
  MaximumAmount: number;
  CanClaim: boolean;
  CanSubscribe: boolean;
}

export interface PromotionSubscribeList {
  Total: number;
  List: PromotionSubscribed[];
  CurrentTurnOver: CurrentTurnOver;
}

export interface PromotionSubscribed {
  PromotionId: string;
  PromotionName: string;
  SubscribedDateTime: string;
  ReferNo: string;
  CurrentTurnOver: number;
  TurnOver: number;
  BonusAmount: number;
  DepositAmount: number;
  PromotionStatus: PromotionStatusEnum
  StatusString: string;
}

export interface CurrentTurnOver {
  CurrentTurnOver: number;
  TargetTurnOver: number;
  PromotionName: string;
  PromotionType: PromotionCategoryEnum;
}

export interface PromotionSubscribe {
  Success: boolean;
  isFirstTimeMode: boolean;
  RebateAmount: number;
  ReferNo: string;
  Msg: string;
  IsDirectPayout: boolean;
}

export interface ManualWithdraw {
  Id: string;
  Status: ProposalTypeEnum;
  ReferNumber: string;
  TotalWithdraw: number;
  Remark: string;
}

export interface PopUpBanner {
  Id: string;
  MainTitle: string;
  SubType: PopupBannerContentTypeEnum;
  SubTypeStr: string;
  OneTimeDisplay: boolean;
  Details: PopupDetail[];
}

export interface PopupDetail {
  Language: number;
  LanguageStr: string;
  Title: string;
  Slug: string;
  KeyName: string;
  Description: string;
  UrlLink: string;
  WebImageUrl: string;
}
