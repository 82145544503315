import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { EmptyListComponent } from '../components/empty-list/empty-list.component';
import { SortDirective } from '../directive/sort-column.directive';
import { BanklistPipe } from '../pipes/banklist.pipe';
import { GamesNameFilterPipe } from '../pipes/custom-name.pipe';
import { Categorypipe } from '../pipes/gamecategory.pipe';
import { HiddenPipe } from '../pipes/hidden.pipe';
import { HtmlfilterPipe } from '../pipes/htmlfilter.pipe';
import { PlayerPipe } from '../pipes/player.pipe';
import { PromotionPipe } from '../pipes/promotion.pipe';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { PageSizeFilterComponent } from '../components/page-size-filter/page-size-filter.component';
import { DateRangeFilterComponent } from '../components/date-range-filter/date-range-filter.component';
import { MatchPasswordDirective } from '../directive/match-password.directive';
import { PaymentMethodHeaderComponent } from '../components/payment-method-header/payment-method-header.component';
import { PgWithdrawComponent } from '../components/withdraw-modal/pg-withdraw/pg-withdraw.component';
import { HermesWithdrawComponent } from '../components/withdraw-modal/hermes-withdraw/hermes-withdraw.component';
import { ManualWithdrawComponent } from '../components/withdraw-modal/manual-withdraw/manual-withdraw.component';
import { MinMaxDirective } from '../directive/min-max.directive';
import { BindPlayerBankModalComponent } from '../components/bind-player-bank-modal/bind-player-bank-modal.component';
import { NoScrollDirective } from '../directive/no-scroll.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    BanklistPipe,
    HiddenPipe,
    PromotionPipe,
    HtmlfilterPipe,
    PlayerPipe,
    GamesNameFilterPipe,
    Categorypipe,
    SortDirective,
    EmptyListComponent,
    PaginationComponent,
    PageSizeFilterComponent,
    DateRangeFilterComponent,
    MatchPasswordDirective,
    MinMaxDirective,
    NoScrollDirective,
    PaymentMethodHeaderComponent,
    HermesWithdrawComponent,
    PgWithdrawComponent,
    ManualWithdrawComponent,
    BindPlayerBankModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    CarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FontAwesomeModule,
  ],

  exports: [
    FormsModule,
    TranslateModule,
    CarouselModule,
    BanklistPipe,
    HiddenPipe,
    PromotionPipe,
    HtmlfilterPipe,
    PlayerPipe,
    GamesNameFilterPipe,
    Categorypipe,
    SortDirective,
    EmptyListComponent,
    PaginationComponent,
    PageSizeFilterComponent,
    DateRangeFilterComponent,
    MatchPasswordDirective,
    MinMaxDirective,
    NoScrollDirective,
    PaymentMethodHeaderComponent,
    PgWithdrawComponent,
    HermesWithdrawComponent,
    PgWithdrawComponent,
    ManualWithdrawComponent,
    BindPlayerBankModalComponent,
  ],
  entryComponents: [BindPlayerBankModalComponent],
})
export class ShareModule {}
