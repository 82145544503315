import { HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpHeaderConfig, ResponseCode } from '../model/enums';
import {
  BankListModel,
  CreateAndUpdateReviewsModel,
  GetBankAccountModel,
  HermesAccountModel,
  HermesRegisterModel,
  HermesUpdateAccountModel,
  HermesVerifyBankAccountModel,
  HermesWithdrawModel,
  PaydutyCheckTmpProposalModel,
  PaydutyRegisterModel,
  PaydutySubscribePromotionModel,
  PaydutyUpdateModel,
  PaydutyWithdrawModel,
  PromotionListReqModel,
  ReviewListModel,
} from '../model/request';
import { BaseResponseModelV2, HermesVerify, ResponseMessage } from '../model/response';
import { RequestApiLink } from '../model/static';
import { BaseService } from './base.service';
import { EncryptionService } from './encryption.service';
import { HttpService } from './http.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public baseServ = inject(BaseService);
  public _http = inject(HttpService);
  public encryptServ = inject(EncryptionService);

  // start hermes payment gateway
  async HermesBankListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_HERMES_BANKLIST,
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpGetRequest; service:HermesBankListAsync',
      );
    }
    return response;
  }

  async HermesDepositAccAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_HERMES_DEPOSIT_ACC,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpGetRequest; service:HermesDepositAccAsync',
      );
    }
    return response;
  }

  async HermesRegisterAsync(request: { bankId: number, bankAccountNumber: string, bankAccountName: string }): Promise<BaseResponseModelV2> {
    const model = new HermesRegisterModel();
    model.BankId = request.bankId;
    model.BankAccountNumber = request.bankAccountNumber;
    model.BankAccountName = request.bankAccountName;
    model.TimeSpan = this.baseServ.timeSpan;
    model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
    model.Signature = this.encryptServ.generateV2Signature(
      model.TimeSpan + model.Ip
    );

    return this._http
      .sendPostV2<HermesRegisterModel, BaseResponseModelV2<unknown>>(
        '/v2/Hermes/Register',
        model,
      )
      .toPromise();
  }

  async HermesVerifyBankAccAsync(
    bankId: number,
    bankAccountNumber: string,
  ): Promise<BaseResponseModelV2<HermesVerify>> {
    const model = new HermesVerifyBankAccountModel();
    model.BankId = bankId;
    model.BankAccountNumber = bankAccountNumber;
    model.TimeSpan = this.baseServ.timeSpan;
    model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
    model.Signature = this.encryptServ.generateV2Signature(
      model.TimeSpan + model.Ip + model.BankAccountNumber
    );

    return this._http
      .sendPostV2<HermesVerifyBankAccountModel, BaseResponseModelV2<HermesVerify>>(
        '/v2/Hermes/VerifyBankAccount',
        model,
      )
      .toPromise();
  }

  async HermesUpdateAccAsync(
    request: HermesAccountModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      const model = new HermesUpdateAccountModel();
      model.BankId = request.BankId;
      model.BankAccountNumber = request.BankAccountNumber;
      model.BankAccountName = request.BankAccountName;
      model.TelephoneNumber = request.TelephoneNumber;
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan + model.Ip + this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_HERMES_UPDATEACC,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:HermesUpdateAccAsync',
      );
    }
    return response;
  }

  async HermesWithdrawAsync(
    model: HermesWithdrawModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.Amount +
          model.TransPassword +
          model.SumRateAmount +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_HERMES_WITHDRAW,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:HermesWithdrawAsync',
      );
    }
    return response;
  }
  // stop  hermes payment gateway

  // testimonial
  async createTestimonial(
    model?: CreateAndUpdateReviewsModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan + model.Ip + model.Rate + this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_CREATE_REVIEWS,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:createTestimonial',
      );
    }
    return response;
  }

  async getTestimonialList(model?: ReviewListModel): Promise<ResponseMessage> {
    let response: any;
    try {
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.PageNum +
          model.PageSize +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_GET_REVIEWS_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:createTestimonial',
      );
    }
    return response;
  }
  // testimonial

  async getRankingList(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_MAIN_PAGE_CONTENT_RANKING +
            '/' +
            this.baseServ.timeSpan,
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getBannerListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_BANNER_LIST +
            this.baseServ.currentSiteConfig.languange.value +
            '/0',
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getAnnoucementListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_ANNOUCEMENT_LIST +
            this.baseServ.currentSiteConfig.languange.value,
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getPromotionCategoryAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      let params = new HttpParams();
      params = params.set(
        'Lang',
        this.baseServ.currentSiteConfig.languange.value,
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_PROMOTION_CATEGORY,
          params,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getPromotionListAsync(type): Promise<ResponseMessage> {
    let response: any;
    try {
      let params = new HttpParams();
      params = params.set('Type', type);
      params = params.append(
        'Langauge',
        this.baseServ.currentSiteConfig.languange.value,
      );
      params = params.append('PageNum ', '1');

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.GET_PROMOTION_LIST,
          params,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  // Game Promotion
  async getGamePromotionListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      let params = new HttpParams();
      params = params.set('Type', '');
      params = params.append(
        'Lang',
        this.baseServ.currentSiteConfig.languange.value,
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_PROMOTION_LIST_V1,
          params,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  // Promotion V2
  async getPromotionCategoryV2Async(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_PROMOTION_CATEGORY_V2,
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getPromotionListV2Async(
    model?: PromotionListReqModel,
  ): Promise<ResponseMessage> {
    let response: any;
    try {
      model.Language = this.baseServ.languagesTypeEnum(
        this.baseServ.currentSiteConfig.languange.value.toUpperCase(),
      );
      model.LanguageStr = this.baseServ.currentSiteConfig.languange.value;
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.LanguageStr +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.V2_PROMOTION_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getDepositBankListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      response = await this.baseServ.http
        .get<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.GET_DEPOSIT_BANK_LIST,
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getDepositAccountAsync(bankId): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        const model = new GetBankAccountModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.BankId = bankId;
        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.BankId +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_TRANSACTION_BANK_ACCOUNT,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getDepositBankListAsync();
      }
    }

    return response;
  }

  async getContentDetailAsync(type: string): Promise<ResponseMessage> {
    let response: any;
    try {
      let params = new HttpParams();
      params = params.set('Type', type);
      params = params.append(
        'Lang',
        this.baseServ.currentSiteConfig.languange.value,
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.GET_CONTENT_DETAIL,
          params,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getFAQListAsync(): Promise<ResponseMessage> {
    let response: any;
    try {
      let params = new HttpParams();
      params = params.append(
        'Lang',
        this.baseServ.currentSiteConfig.languange.value,
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain + RequestApiLink.GET_FAQ_LIST,
          params,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              false,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async uploadWithFormDataAsync(
    file: File,
    fileName: string,
    withDomain: any,
  ): Promise<any> {
    let response: any;

    try {
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, fileName);
      formData.append('withdomain', withDomain);
      formData.append('platForm', 'c4gllgame');
      formData.append('category', 'receipt');

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');

      response = await this.baseServ.http
        .post<any>(
          'https://1.qkun.site/api/UploadImageWithFile/ReceiptUpload',
          formData,
          // tslint:disable-next-line: object-literal-shorthand
          { headers: headers },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:uploadWithFormDataAsync',
      );
    }
    return response;
  }

  async uploadWithFormDataWithdrawAsync(
    file: File,
    fileName: string,
    withDomain: any,
  ): Promise<any> {
    let response: any;

    try {
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, fileName);
      formData.append('withdomain', withDomain);
      formData.append('platForm', 'c4gllgame');
      formData.append('category', 'withdraw');

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');

      response = await this.baseServ.http
        .post<any>(
          'https://1.qkun.site/api/UploadImageWithFile/ReceiptUpload',
          formData,
          // tslint:disable-next-line: object-literal-shorthand
          { headers: headers },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(
        ResponseCode.Error,
        'Error:HttpPostRequest; service:uploadWithFormDataWithdrawAsync',
      );
    }
    return response;
  }

  async getOptionBankList(): Promise<ResponseMessage> {
    let response: any;
    try {
      const model = new BankListModel();
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.PlayerId = this.baseServ.playerInfo.playerId;
      model.PlayerName = this.baseServ.playerInfo.playerName;

      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.PlayerName +
          model.PlayerId +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_TRANSACTION_OPTION_BANK_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getBankCodeList(): Promise<ResponseMessage> {
    let response: any;
    try {
      const model = new BankListModel();
      model.TimeSpan = this.baseServ.timeSpan;
      model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
      model.PlayerId = this.baseServ.playerInfo.playerId;
      model.PlayerName = this.baseServ.playerInfo.playerName;

      model.Signature = ToolsService.signature(
        model.TimeSpan +
          model.Ip +
          model.PlayerName +
          model.PlayerId +
          this.baseServ.e('1').join(''),
        this.baseServ.e('0').join(''),
      );

      response = await this.baseServ.http
        .post<any>(
          this.baseServ.appConfig.apiDomain +
            RequestApiLink.V2_TRANSACTION_BANK_LIST,
          model,
          {
            headers: this.baseServ.postBuildHeader(
              HttpHeaderConfig.FORM_POST_HEADER,
              true,
            ),
          },
        )
        .toPromise();
    } catch (error) {
      return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
    }
    return response;
  }

  async getRedeemPromotionListAsync(): Promise<ResponseMessage> {
    let response: any;
    const tmpLanguage = this.baseServ.currentSiteConfig.languange.value;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .get<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.GET_REDEEM_PROMOTION_LIST +
              tmpLanguage,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.getRedeemPromotionListAsync();
      }
    }

    return response;
  }

  async ClaimLoseRebateAsync(): Promise<ResponseMessage> {
    let response: any;
    const params = new HttpParams();
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);
    if (isValid) {
      try {
        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.CLAIM_LOSE_REBATE,
            params,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.ClaimLoseRebateAsync();
      }
    }

    return response;
  }

  async paydutyWithdraw(model: PaydutyWithdrawModel): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);

    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            model.Amount.toString() +
            model.TransPassword +
            model.SumRateAmount.toString() +
            this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PAYDUTY_WITHDRAW,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.paydutyWithdraw(model);
      }
    }

    return response;
  }

  async paydutyRegister(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);

    if (isValid) {
      try {
        const model = new PaydutyRegisterModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          model.TimeSpan + model.Ip + this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PAYDUTY_REGISTER,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.paydutyRegister();
      }
    }

    return response;
  }

  async paydutyUpdate(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);

    if (isValid) {
      try {
        const model = new PaydutyUpdateModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          model.TimeSpan + model.Ip + this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PAYDUTY_UPDATE,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.paydutyUpdate();
      }
    }

    return response;
  }

  async PaydutyCheckTmpProposal(): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);

    if (isValid) {
      try {
        const model = new PaydutyCheckTmpProposalModel();
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;
        model.Language = this.baseServ.languagesTypeEnum(
          this.baseServ.currentSiteConfig.languange.value.toUpperCase(),
        );
        model.LanguageStr = this.baseServ.currentSiteConfig.languange.value;

        model.Signature = ToolsService.signature(
          model.TimeSpan + model.Ip + this.baseServ.e('1').join(''),
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PAYDUTY_CHECK_TMP_PROPOSAL,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.PaydutyCheckTmpProposal();
      }
    }

    return response;
  }

  async PaydutySubscribePromotion(
    model: PaydutySubscribePromotionModel,
  ): Promise<ResponseMessage> {
    let response: any;
    const isValid: boolean = await this.baseServ.CheckTokenTimeIsValid(20);

    if (isValid) {
      try {
        model.TimeSpan = this.baseServ.timeSpan;
        model.Ip = this.baseServ.currentSiteConfig.clientIp.ip;

        model.Signature = ToolsService.signature(
          model.TimeSpan +
            model.Ip +
            this.baseServ.e('1').join('') +
            model.PCode +
            model.PromotionId,
          this.baseServ.e('0').join(''),
        );

        response = await this.baseServ.http
          .post<any>(
            this.baseServ.appConfig.apiDomain +
              RequestApiLink.V2_PAYDUTY_SUBSCRIBE_PROMOTION,
            model,
            {
              headers: this.baseServ.postBuildHeader(
                HttpHeaderConfig.FORM_POST_HEADER,
                true,
              ),
            },
          )
          .toPromise();
      } catch (error) {
        return new ResponseMessage(ResponseCode.Error, 'SystemBusy');
      }
    } else {
      const tokenRefreshResp = await this.baseServ.RefreshJWTToken();
      if (this.baseServ.checkTokenResponseStatus(tokenRefreshResp)) {
        return await this.PaydutySubscribePromotion(model);
      }
    }

    return response;
  }
}
