import { inject, Injectable } from '@angular/core';
import { TokenRequestModel } from '../model/request';
import { BaseResponseModelV2, TransactionMethod } from '../model/response';
import { BaseResponseModel, ManualWithdraw, PaymentGateway } from '../model/response.v3';
import { BaseService } from './base.service';
import { HttpService } from './http.service';
import { EncryptionService } from './encryption.service';
import { ManualWithdrawRequest } from '../model/request.v3';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _http = inject(HttpService);
  private baseServ = inject(BaseService);
  private encryptServ = inject(EncryptionService);

  constructor() { }

  public CheckManualTransactionActive() {
    const request = new TokenRequestModel();
    request.PlayerId = this.baseServ.playerInfo.playerId || '';
    request.PlayerName = this.baseServ.playerInfo.playerName || '';

    return this._http.sendGet<TokenRequestModel, BaseResponseModel<TransactionMethod>>('/v3/Transaction/CheckManualTransaction', request);
  }

  public GetPaymentGatewayList() {
    const timeSpan = Math.round(new Date().getTime() / 1000);
    return this._http.sendGetWithoutEncrypt<BaseResponseModelV2<PaymentGateway[]>>(`/v2/PaymentGateway/List/`, timeSpan.toString());
  }

  public SubmitManualWithdraw(model: ManualWithdrawRequest) {
    return this._http.sendPost<ManualWithdrawRequest, BaseResponseModel<ManualWithdraw>>('/v3/Transaction/Withdraw', model);
  }
}
