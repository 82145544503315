import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { i } from '../model/enums';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  constructor() {}

  static tryParseJson(strJson: string): boolean {
    let isValid = false;
    if (strJson) {
      try {
        const result = JSON.parse(strJson);
        isValid = true;
      } catch {
        return isValid;
      }
    }

    return isValid;
  }

  static RSAEncryption = (value: string, publicKey: i) => {
    try {
      return CryptoJS.AES.encrypt(value, publicKey).toString();
    } catch (e) {}
    // tslint:disable-next-line: semicolon
  };

  RSAEncryption = (value: string, publicKey: i) => {
    try {
      return CryptoJS.AES.encrypt(value, publicKey).toString();
    } catch (e) {}
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static RSADecryption = (encrypted: string, publicKey: i): string => {
    try {
      return CryptoJS.AES.decrypt(encrypted, publicKey).toString(
        CryptoJS.enc.Utf8,
      );
    } catch (e) {
      return;
    }
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static convertDateTimeToString = (
    date: Date,
    isEnd: boolean,
    count?: number,
  ) => {
    let result = '';
    if (isEnd) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    } else {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      if (count > 0) {
        date.setDate(date.getDate() - count);
      }
    }
    date.setDate(date.getDate() + 1);
    result = date.toISOString();

    return result;
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static convertDateTimeToLocalDateFormat = (date: Date) => {
    let result = '';
    result =
      date.getFullYear() +
      '-' +
      ToolsService.formatDateString(date.getMonth() + 1) +
      '-' +
      ToolsService.formatDateString(date.getDate()) +
      ' ' +
      ToolsService.formatDateString(date.getHours()) +
      ':' +
      ToolsService.formatDateString(date.getMinutes()) +
      ':00';
    return result;
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static formatDateString = (num) => {
    return (num < 10 ? '0' : '') + num;
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static calculateDateBetween = (
    date: Date,
    isEnd: boolean,
    count?: number,
  ) => {
    const result = '';
    if (isEnd) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    } else {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      if (count > 0) {
        date.setDate(date.getDate() - count);
      }
    }

    return ToolsService.convertDateTimeToLocalDateFormat(date);
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static getTimeSpan() {
    return Math.round(new Date().getTime() / 1000);
  }

  // tslint:disable-next-line: member-ordering
  static signature(plainText: string, secureKey: string): string {
    const encrypted3 = CryptoJS.HmacSHA256(plainText, secureKey);
    return CryptoJS.enc.Base64.stringify(encrypted3).replace(' ', '+');
  }

  // tslint:disable-next-line: member-ordering
  static md5(plainText: string): string {
    const encrypted3 = CryptoJS.MD5(plainText).toString(CryptoJS.enc.Hex);
    return encrypted3;
  }

  // tslint:disable-next-line: member-ordering
  static AESEncryption = (value: string, publicKey: i): string => {
    try {
      return CryptoJS.AES.encrypt(value, publicKey).toString();
    } catch (e) {
      return;
    }
    // tslint:disable-next-line: semicolon
  };

  // tslint:disable-next-line: member-ordering
  static AESDecryption = (encrypted: string, publicKey: i): string => {
    try {
      return CryptoJS.AES.decrypt(encrypted, publicKey).toString(
        CryptoJS.enc.Utf8,
      );
    } catch (e) {
      return;
    }
    // tslint:disable-next-line: semicolon
  };
}
