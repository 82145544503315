import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PaymentGatewayEnum } from 'src/app/model/enums';
import { PaymentGateway } from 'src/app/model/response.v3';
import { BaseService } from 'src/app/services/base.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-method-header',
  templateUrl: './payment-method-header.component.html',
  styleUrls: ['./payment-method-header.component.scss'],
})
export class PaymentMethodHeaderComponent {
  @Input() paymentAction: 'DEPOSIT' | 'WITHDRAW' = 'DEPOSIT';
  @Output() onChangePaymentMethod = new EventEmitter<{
    code: 'MANUAL' | PaymentGatewayEnum;
    pgDetail?: PaymentGateway | undefined;
  }>();

  isManualActive = false;
  paymentGatewayList: PaymentGateway[] = [];
  selectedPaymentMethod = new BehaviorSubject<
    'MANUAL' | PaymentGatewayEnum | undefined
  >(undefined);

  constructor(
    private baseServ: BaseService,
    private paymentServ: PaymentService,
  ) {
    this.baseServ.isLoading = true;
    combineLatest([
      this.paymentServ.CheckManualTransactionActive(),
      this.paymentServ.GetPaymentGatewayList(),
    ])
      .pipe(finalize(() => (this.baseServ.isLoading = false)))
      .subscribe(([checkBanResp, pgListResp]) => {
        this.isManualActive = true;

        if (pgListResp && pgListResp.content.length > 0) {
          this.paymentGatewayList = pgListResp.content.filter((x) =>
            this.paymentMethodValidator(x),
          );

          // for test purpose
          // this.paymentGatewayList = pgListResp.content;
        }

        if (this.paymentGatewayList.length > 0) {
          // default selected payment
          this.selectedPaymentMethod.next(
            this.paymentGatewayList[0].gateWayCode,
          );

          this.onChangePaymentMethod.emit({
            code: this.paymentGatewayList[0].gateWayCode,
            pgDetail: this.paymentGatewayList[0],
          });
        } else {
          if (this.isManualActive) {
            this.selectedPaymentMethod.next(PaymentGatewayEnum.Manual);
            this.onChangePaymentMethod.emit({ code: 'MANUAL' });
          }
        }
      });
  }

  public changePaymentMethod(type: 'MANUAL' | PaymentGatewayEnum | undefined) {
    this.selectedPaymentMethod.next(type);
    this.onChangePaymentMethod.emit({
      code: type!,
      pgDetail: this.paymentGatewayList.find((x) => x.gateWayCode === type),
    });
  }

  public paymentMethodValidator(pg: PaymentGateway) {
    if (this.paymentAction === 'DEPOSIT' && pg.gateWayCode === 28) {
      return false;
    }

    return this.paymentAction === 'DEPOSIT' ? pg.depositShow : pg.withdrawShow;
  }
}
