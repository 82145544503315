import { defaultLanguage } from '../app-config-model';
import { Devices, DevicesPlatform, ResponseCode, ResponseStatus, UserTypeEnum } from './enums';

export interface Language {
  code: string;
  name: string;
  native: string;
}

export interface Location {
  geoname_id?: any;
  capital: string;
  languages: Language[];
  country_flag: string;
  country_flag_emoji: string;
  country_flag_emoji_unicode: string;
  calling_code: string;
  is_eu: boolean;
}

export interface TimeZone {
  id: string;
  current_time: Date;
  gmt_offset: number;
  code: string;
  is_daylight_saving: boolean;
}

export interface Currency {
  code: string;
  name: string;
  plural: string;
  symbol: string;
  symbol_native: string;
}

export interface Connection {
  asn: number;
  isp: string;
}

export interface ClientIP {
  ip: string;
  type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: Location;
  time_zone: TimeZone;
  currency: Currency;
  connection: Connection;
}

// api response
export class ResponseMessage {
  status: ResponseCode = ResponseCode.Normal;
  msg: string = '';
  content: any;

  constructor(status: ResponseCode, msg: string, content?: any) {
    this.status = status;
    this.msg = msg;
    this.content = content;
  }
}

export class BaseResponseModelV2<T = unknown> {
  status: ResponseStatus;
  msg: string = '';
  content?: T;

  constructor(status: ResponseStatus, msg: string, content?: any) {
    this.status = status;
    this.msg = msg;
    this.content = content;
  }
}

export class PlayerInfo {
  id: string;
  playerId: string;
  playerName: string;
  mobile: string;
  email: string;
  realName: string;
  sex?: string;
  birth?: string;
  currency: string;
  rate: number;
  amount = 0;
  authToken: string;
  getTokenTime: Date;
  currentTurnOver: number;
  targetTurnOver: number;
  ownReferCode: string;
  referralUrl: string;
  bankName: string;
  bankCode: string;
  bankAccountNo: string;
  accountName: string;
  pinCodeSet: boolean;
  userTypeList: UserTypeEnum[];
  withdrawalMaximum: number;
  withdrawalMinimum: number;
}

export class CurrentSiteConfig {
  deviceType: Devices;
  platform: DevicesPlatform;
  version: string;
  previousWidth = 0;
  switchDevice = false;
  languange: defaultLanguage;
  clientIp: ClientIP;
}

export class DeviceProperty {
  deviceType: Devices;
  platform: DevicesPlatform;
}

export class OtpResponse {
  success: boolean;
  refcode: string;
  msg: string;
  otptoken: string;

  constructor(success: boolean, msg: string) {
    this.success = success;
    this.msg = msg;
  }
}

export class BankListResponse {
  code: boolean;
  list: any;
  success: string;

  constructor(code: boolean, success: string) {
    this.code = code;
    this.success = success;
  }
}

export class OtpVerifyResponse {
  success: boolean;
  msg: string;

  constructor(success: boolean, msg: string) {
    this.success = success;
    this.msg = msg;
  }
}

export class BankVerifyResponse {
  success: boolean;
  msg: string;
  bank: string;
  // tslint:disable-next-line: variable-name
  bank_no: string;
  // tslint:disable-next-line: variable-name
  account_name: string;
  code: number;

  constructor(success: boolean, msg: string) {
    this.success = success;
    this.msg = msg;
  }
}

export class HermesVerify {
  accountName = '';
  accountNumber = '';
  bankId = '';
  bankCode = '';
}

export class BankDetail {
  id: string;
  altBankCode: string;
  bankId: string;
  bankCode: string;
  bankName: string;
  imageUrl: string;
}

export class TransactionMethod {
  enableDeposit: boolean;
  enableWithdraw: boolean;
}
