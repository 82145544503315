import { isDevMode } from '@angular/core';
import { StorageKey, StorageType } from '../model/enums';

export function log(...params: any[]) {
  isDevMode() && console.log(...params);
}

export function getDetailFromStorage(
  storageType: StorageType,
  storageKey: StorageKey,
): any | null {
  let response: string;
  switch (storageType) {
    case StorageType.LOCAL_STORAGE:
      response = localStorage.getItem(storageKey);
      break;
    case StorageType.SESSION_STORAGE:
      response = sessionStorage.getItem(storageKey);
      break;
  }

  if (response) return JSON.parse(response);
  return null;
}

export function thailandDateStringConvertor(
  date: Date,
  options?: { formatDate: boolean; startOrEndOfDay: 'start' | 'end' },
) {
  //{ timeZone: 'Asia/Bangkok' }
  if (options && options.formatDate && options.startOrEndOfDay) {
    if (options.startOrEndOfDay === 'start') {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 59);
    }
  }

  if (date.getFullYear() > 2500) {
    const gregorianYear = date.getFullYear() - 543;
    return date
      .toLocaleString('zh-CN')
      .replace(String(date.getFullYear()), String(gregorianYear));
  } else {
    return date.toLocaleString('zh-CN');
  }
}
