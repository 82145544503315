import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import {
  faHeadset,
  faUsers,
  faHistory,
  faStar,
  faBoxes,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public year: any;

  public faHeadset = faHeadset;
  public faUsers = faUsers;
  public faHistory = faHistory;
  public faStar = faStar;
  public faBoxes = faBoxes;

  constructor(public baseServ: BaseService) {
    const now = new Date();
    this.year = now.getFullYear();
  }

  ngOnInit() {}
}
