import { Directive, Input } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

function matchPassword(password: string, confirmPassword: string): ValidatorFn {
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[password];
    const confirmPasswordControl = formGroup.controls[confirmPassword];

    if (!passwordControl || !confirmPasswordControl) return null;

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) return null;

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  };
}

@Directive({
  selector: '[matchPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MatchPasswordDirective,
      multi: true,
    },
  ],
})
export class MatchPasswordDirective implements Validator {
  @Input('matchPassword') matchPasswords: string[] = [];

  validate(formGroup: FormGroup): ValidationErrors {
    return matchPassword(this.matchPasswords[0], this.matchPasswords[1])(formGroup);
  }
}
