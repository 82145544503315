import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ResponseStatus } from 'src/app/model/enums';
import { BindBankRequest } from 'src/app/model/request.v3';
import { AlertService } from 'src/app/services/alert.service';
import { BaseService } from 'src/app/services/base.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-bind-player-bank-modal',
  templateUrl: './bind-player-bank-modal.component.html',
  styleUrls: ['./bind-player-bank-modal.component.scss'],
})
export class BindPlayerBankModalComponent {
  public bindBankModel = new BindBankRequest();
  public onClose: Subject<boolean>;

  public faTimes = faTimes;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _baseService: BaseService,
    private readonly _playerService: PlayerService,
    private readonly _alertService: AlertService,
    public bsRef: BsModalRef,
  ) {
    this.getPlayerDetail();
    this.onClose = new Subject();
  }

  public async onSubmit(form: NgForm) {
    if (form.valid) {
      this._baseService.isLoading = true;
      this.bindBankModel.BankName = this.bindBankModel.BankCode;
      this.bindBankModel.CountryCode = 'THB';

      const data = await this._playerService.bindPlayerBankAccount(
        this.bindBankModel,
      );

      if (data.Status === ResponseStatus.success) {
        await this.getPlayerDetail();
        this.onClose.next(true);
        this.bsRef.hide();
        this._alertService.showAlertMsg('msg.SuccessfulBindBankAccount', {
          timer: 3000,
        });
      } else {
        this._alertService.showAlertMsg(`msg.${data.Msg}`);
      }

      this._baseService.isLoading = false;
    }
  }

  onCancel() {
    this.onClose.next(false);
    this.bsRef.hide();
  }

  async getPlayerDetail() {
    const data = await this._playerService.getDetail();
    if (data.Status === ResponseStatus.success) {
      this._baseService.setAllDetail(data);
    }
  }
}
