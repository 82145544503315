import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentGatewayEnum } from 'src/app/model/enums';
import { PaymentGateway } from 'src/app/model/response.v3';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.scss'],
})
export class WithdrawModalComponent {
  selectedPaymentCode = new BehaviorSubject<
    'MANUAL' | PaymentGatewayEnum | undefined
  >(undefined);
  selectedPaymentDetail = new BehaviorSubject<PaymentGateway | undefined>(
    undefined,
  );

  public faTimes = faTimes;

  constructor(public bsRef: BsModalRef) {}

  handleChange(event: {
    code: 'MANUAL' | PaymentGatewayEnum;
    pgDetail?: PaymentGateway | undefined;
  }) {
    this.selectedPaymentCode.next(event.code);
    this.selectedPaymentDetail.next(event.pgDetail);
  }
}
