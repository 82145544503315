import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'referral',
})
export class PlayerPipe implements PipeTransform {
  transform(value: any, type: any): any {
    let result = '';
    switch (type) {
      case 'playerStatus':
        result = this.definePlayerStatus(value);
        break;
      case 'comissionStatus':
        result = this.LatestComissionStatus(value);
        break;
    }

    return result;
  }

  definePlayerStatus(value: number) {
    let result = '';
    switch (value) {
      case -1:
        result = 'label.freeze';
        break;
      case 1:
        result = 'label.normal';
        break;
    }

    return result;
  }

  LatestComissionStatus(value: number) {
    let result = '';
    switch (value) {
      case -1:
        result = 'label.reject';
        break;
      case 1:
        result = 'label.complete';
        break;
      case 2:
        result = 'label.approve';
        break;
      case 3:
        result = 'label.pending';
        break;
      case 4:
        result = 'label.bringForward';
        break;
      case 5:
        result = 'label.verified';
        break;
      case 6:
        result = 'label.redeemed';
        break;
      case 99:
        result = 'label.success';
        break;
    }

    return result;
  }
}
