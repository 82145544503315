import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
})
export class EmptyListComponent implements OnInit {
  @Input() viewHeight: string = '28vh';
  @Input() uniqueId: string;

  ngOnInit(): void {
      if (!this.uniqueId) {
        this.uniqueId = `svg-${Math.random().toString(36).substring(2, 9)}`;
      }
  }
}
