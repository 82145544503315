import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  faAngleLeft,
  faHandshake,
  faKey,
  faMobileAlt,
  faPiggyBank,
  faTimes,
  faUniversity,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { RegisterTabEnum, ResponseStatus } from 'src/app/model/enums';
import {
  HermesAccountModel,
  LoginRequestModel,
  PaydutyBankVerifyModel,
  RegisterReqModel,
} from 'src/app/model/request';
import { BankDetail } from 'src/app/model/response';
import { AlertService } from 'src/app/services/alert.service';
import { BaseService } from 'src/app/services/base.service';
import { GeneralService } from 'src/app/services/general.service';
import { PlayerService } from 'src/app/services/player.service';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit, OnDestroy {
  @Input() type: 'login' | 'register';
  public loginModal = new LoginRequestModel();
  public registerModel = new RegisterReqModel();
  public isSubmit: boolean = false;
  public registerTab: RegisterTabEnum = RegisterTabEnum.PhoneNumber;
  public RegisterTabEnum = RegisterTabEnum;
  public typeAuth = new BehaviorSubject<'login' | 'register'>('login');
  public ConfirmPassword = '';

  public statusRegister: string;
  public titleBoxRegister = 'label.plsMobile';
  public isBankVerified = false;
  public useHermesBank = false;

  public paydutyBankVerifyModel = new PaydutyBankVerifyModel();

  public faAngleLeft = faAngleLeft;
  public faMobileAlt = faMobileAlt;
  public faKey = faKey;
  public faTimes = faTimes;
  public faUniversity = faUniversity;
  public faUser = faUser;
  public faPiggyBank = faPiggyBank;
  public faHandshake = faHandshake;

  // start hermes payment
  public bankListData: any[] = [];
  public selectBankData = '';
  public selectedBankDetail: BankDetail;
  public hermesAccModel = new HermesAccountModel();
  // stop  hermes payment

  private unsubscribe = new Subject<void>();

  constructor(
    public bsRef: BsModalRef,
    public modalServ: BsModalService,
    public playerServ: PlayerService,
    public generalServ: GeneralService,
    public alertServ: AlertService,
    public router: Router,
    public baseServ: BaseService,
    public generalService: GeneralService,
  ) {}

  ngOnInit() {
    this.typeAuth.next(this.type);
    this.typeAuth.pipe(takeUntil(this.unsubscribe)).subscribe(async (type) => {
      this.isSubmit = false;
      if (type === 'register') {
        if (localStorage.getItem('affiliate')) {
          this.registerModel.ReferCode = localStorage.getItem('affiliate');
        }

        if (localStorage.getItem('affiliateType')) {
          this.registerModel.AffliateType =
            localStorage.getItem('affiliateType');
        }

        await this.handleRegister();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async handleRegister() {
    const response = await this.playerServ.CheckRegisterVerifyBank();
    this.useHermesBank = response.Content;

    if (this.bankListData.length < 1) this.HermesBankList();
    this.titleBoxRegister = 'label.plsMobile';
    this.registerTab = RegisterTabEnum.PhoneNumber;
  }

  // start hermes payment
  HermesSelectBank() {
    const tmpBankSplit = this.selectBankData.split('/');
    this.selectedBankDetail = this.bankListData.find(
      (x) => x.bankCode === tmpBankSplit[1],
    );

    this.registerModel.BankCode = this.selectedBankDetail.bankCode;
    this.registerModel.BankName = this.selectedBankDetail.bankName;
  }

  async HermesBankList() {
    this.baseServ.isLoading = true;
    const res = await this.generalService.HermesBankListAsync();
    if (this.baseServ.checkTokenResponseStatus(res)) {
      this.bankListData = res.content;
    } else {
      this.alertServ.showAlertMsg('msg.' + res.msg);
    }

    this.baseServ.isLoading = false;
  }

  async HermesRegister() {
    this.baseServ.isLoading = true;
    const res = await this.generalService.HermesRegisterAsync({
      bankId: this.hermesAccModel.BankId,
      bankAccountNumber: this.hermesAccModel.BankAccountNumber,
      bankAccountName: this.hermesAccModel.BankAccountName,
    });

    if (res.status === ResponseStatus.success) {
      this.alertServ.showAlertMsg('msg.PlayerLoginSuccess');
      this.router.navigateByUrl('member-center');
    } else {
      this.alertServ.showAlertMsg('msg.' + res.msg);
    }

    this.baseServ.isLoading = false;
  }

  async HermesUpdate() {
    this.baseServ.isLoading = true;
    const res = await this.generalService.HermesUpdateAccAsync(
      this.hermesAccModel,
    );

    if (this.baseServ.checkTokenResponseStatus(res)) {
      this.alertServ.showAlertMsg('msg.PlayerLoginSuccess');
      this.router.navigateByUrl('member-center');
    } else {
      this.alertServ.showAlertMsg('msg.' + res.msg);
    }

    this.baseServ.isLoading = false;
  }

  async hermesDepositAccDeposit() {
    const res = await this.generalServ.HermesDepositAccAsync();
  }
  // stop hermes payment

  handleTypeAuth(type: 'login' | 'register') {
    this.typeAuth.next(type);
  }

  handleHide() {
    this.bsRef.hide();
  }

  openPopupModel() {
    this.modalServ.show(PopupComponent, {
      ignoreBackdropClick: false,
      class: 'popup-modal modal-lg modal-dialog-centered',
    });
  }

  async login(form: NgForm) {
    this.isSubmit = true;
    if (form.valid) {
      this.bsRef.hide();
      this.baseServ.isLoading = true;
      const response = await this.playerServ.loginAsync(this.loginModal);
      this.baseServ.isLoading = false;
      if (this.playerServ.baseServ.checkTokenResponseStatus(response)) {
        await this.hermesDepositAccDeposit();
        this.router.navigateByUrl('member-center');
        this.loginModal = new LoginRequestModel();
        // this.openPopupModel();
      } else {
        this.alertServ.showAlertMsg('msg.' + response.msg);
      }

      this.isSubmit = false;
    }
  }

  async handleCheckPhoneNumber(form: NgForm) {
    this.isSubmit = true;

    if (form.valid) {
      this.baseServ.isLoading = true;
      combineLatest([
        this.playerServ.CheckPlayerNameExist(this.registerModel.Mobile),
        this.playerServ.CheckPhoneNumberExist(this.registerModel.Mobile),
      ])
        .pipe(finalize(() => (this.baseServ.isLoading = false)))
        .subscribe(([playerResp, phoneResp]) => {
          if (
            playerResp === null ||
            playerResp.Status !== ResponseStatus.success
          ) {
            this.alertServ.showAlertMsg(`msg.${playerResp.Msg}`);
          } else if (
            phoneResp === null ||
            phoneResp.Status !== ResponseStatus.success
          ) {
            this.alertServ.showAlertMsg(`msg.${phoneResp.Msg}`);
          } else {
            // phone number can be use to register
            this.hermesAccModel.TelephoneNumber = this.registerModel.Mobile;
            this.titleBoxRegister = 'label.paymentAccount_1';
            this.registerTab = RegisterTabEnum.BankDetail;
            this.isSubmit = false;
          }
        });
    }
  }

  async handleBankDetail(form: NgForm, event: KeyboardEvent) {
    event.preventDefault();

    this.isSubmit = true;
    if (form.valid) {
      this.baseServ.isLoading = true;
      if (this.useHermesBank) {
        // verify again if user changes bank detail input
        if (
          this.hermesAccModel.BankAccountNumber !==
            this.registerModel.BankAccountNo ||
          this.hermesAccModel.BankId !== Number(this.selectedBankDetail.bankId)
        ) {
          this.hermesAccModel.BankId = Number(this.selectedBankDetail.bankId);
          this.hermesAccModel.BankAccountNumber =
            this.registerModel.BankAccountNo;

          this.registerModel.AccountName = '';
          const res = await this.generalService.HermesVerifyBankAccAsync(
            this.hermesAccModel.BankId,
            this.hermesAccModel.BankAccountNumber,
          );

          this.isBankVerified = true;
          if (res.status === ResponseStatus.success) {
            // auto assign account name from hermes account
            this.registerModel.AccountName = res.content.accountName;
            this.registerModel.RealName = res.content.accountName;
            this.hermesAccModel.BankAccountName = res.content.accountName;

            this.titleBoxRegister = 'label.accountInfo';
            this.registerTab = RegisterTabEnum.AccountAndPassword;
          } else {
            // this.alertServ.showAlertMsg(res.msg);
            this.alertServ.showAlertMsg('label.specifyAccountName');
            this.useHermesBank = false;
          }
        } else {
          this.useHermesBank = false;
        }
      } else {
        this.registerModel.RealName = this.registerModel.AccountName;

        this.hermesAccModel.BankId = Number(this.selectedBankDetail.bankId);
        this.hermesAccModel.BankAccountName = this.registerModel.AccountName;
        this.hermesAccModel.BankAccountNumber =
          this.registerModel.BankAccountNo;

        this.titleBoxRegister = 'label.accountInfo';
        this.registerTab = RegisterTabEnum.AccountAndPassword;
      }

      this.baseServ.isLoading = false;
      this.isSubmit = false;
    }
  }

  async register(event: KeyboardEvent, form: NgForm) {
    event.preventDefault();
    switch (this.registerTab) {
      case RegisterTabEnum.PhoneNumber:
        this.handleCheckPhoneNumber(form);
        break;
      case RegisterTabEnum.BankDetail:
        this.handleBankDetail(form, event);
        break;
      default:
        this.isSubmit = true;
        if (form.valid) {
          this.baseServ.isLoading = true;
          this.registerModel.PlayerName = this.registerModel.Mobile;
          this.registerModel.TransPassword = '121231';
          this.registerModel.CurrencyCode = 'THB';

          const response = await this.playerServ.registerAsync(
            this.registerModel,
          );
          if (response.Status === ResponseStatus.success) {
            localStorage.removeItem('affiliate');
            localStorage.removeItem('affiliateType');
            this.titleBoxRegister = 'label.registerSuccess';
            setTimeout(() => {
              this.loginAfterRegister();
            }, 5000);
            this.statusRegister = response.Msg;
          } else {
            this.alertServ.showAlertMsg('msg.' + response.Msg);
            this.baseServ.isLoading = false;
          }

          this.isSubmit = false;
        }
        break;
    }
  }

  async loginAfterRegister() {
    this.bsRef.hide();
    this.baseServ.isLoading = true;

    this.loginModal.PlayerName = this.registerModel.PlayerName;
    this.loginModal.Password = this.registerModel.Password;

    const response = await this.playerServ.loginAsync(this.loginModal);
    this.baseServ.isLoading = false;
    if (this.baseServ.checkTokenResponseStatus(response)) {
      if (this.statusRegister === 'ReregisterSuccess') {
        // this.paydutyUpdate();
        this.HermesUpdate();
      } else {
        // this.paydutyRegister();
        this.HermesRegister();
      }

      this.loginModal = new LoginRequestModel();
      this.registerModel = new RegisterReqModel();
    } else {
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }
  }

  async paydutyRegister() {
    const response = await this.generalService.paydutyRegister();
    if (this.baseServ.checkTokenResponseStatus(response)) {
      this.alertServ.showAlertMsg('msg.PlayerLoginSuccess');
      this.router.navigateByUrl('member-center');
    } else {
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }
  }

  async handleBankVerify() {
    if (!this.registerModel.BankCode) {
      this.alertServ.showAlertMsg('label.deposit_1');
    } else if (!this.registerModel.BankAccountNo) {
      this.alertServ.showAlertMsg('label.specifyAccountNumber');
    } else {
      this.baseServ.isLoading = true;

      this.paydutyBankVerifyModel.bank = this.registerModel.BankCode;
      this.paydutyBankVerifyModel.bank_no = this.registerModel.BankAccountNo;

      const response = await this.playerServ.getPaydutyBankVerifyAsync(
        this.paydutyBankVerifyModel,
      );

      if (response.success) {
        this.registerModel.RealName = response.account_name;
        this.registerModel.AccountName = response.account_name;
        this.registerTab = RegisterTabEnum.AccountAndPassword;
        this.titleBoxRegister = 'label.accountInfo';
      } else {
        if (response.msg === 'ไม่สามารถทำรายการทางการเงินได้ในขณะนี้') {
          this.alertServ.showAlertMsg('label.specifyAccountName');
        } else if (response.msg === 'เข้าระบบไม่สำเร็จ') {
          this.alertServ.showAlertMsg('label.specifyAccountName');
        } else {
          this.alertServ.showAlertMsg(response.msg);
        }
      }

      this.useHermesBank = false;
      this.baseServ.isLoading = false;
    }
  }

  async paydutyUpdate() {
    const response = await this.generalService.paydutyUpdate();
    if (this.baseServ.checkTokenResponseStatus(response)) {
      this.alertServ.showAlertMsg('msg.PlayerLoginSuccess');
      this.router.navigateByUrl('member-center');
    } else {
      this.alertServ.showAlertMsg('msg.' + response.msg);
    }
  }

  handleForgot(type: string) {
    this.bsRef.hide();
    const objectMsg = { type };
    this.modalServ.show(ForgotPasswordModalComponent, {
      ignoreBackdropClick: false,
      class: 'modal-forgot modal-dialog-centered',
      initialState: objectMsg,
    });
  }
}
