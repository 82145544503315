import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';

@Directive({
  selector: '[appMinMax]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinMaxDirective, multi: true },
  ],
})
export class MinMaxDirective {
  @Input() min: number | undefined;
  @Input() max: number | undefined;

  validate(control: AbstractControl): ValidationErrors {
    const value = control.value;
    if (value !== null && value !== undefined) {
      if (this.min !== undefined && value < this.min) {
        return { min: { requiredMin: this.min, actual: value } };
      }
      if (this.max !== undefined && value > this.max) {
        return { max: { requiredMax: this.max, actual: value } };
      }
    }

    return null;
  }
}
