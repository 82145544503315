import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidden'
})
export class HiddenPipe implements PipeTransform {

  transform(value: any): any {
    let hiddenText = "";
    if (value) {
      let length = value.length;

      let first = value.substring(0, 3);
      let last = value.substring(value.length - 3, value.length);
      if (length > 0) {
        hiddenText = first + "*******" + last;
      }

    }
    return hiddenText;

  }

}
