import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category',
})
export class Categorypipe implements PipeTransform {
  transform(value: any): any {
    let actualGameName = value;
    if (value.includes('CockFight')) {
      actualGameName = 'label.cock';
    }
    if (value.includes('ChessGame')) {
      actualGameName = 'label.CardGame';
    }
    if (value.includes('LiveCasino')) {
      actualGameName = 'label.LiveCasino';
    }
    if (value.includes('Racing')) {
      actualGameName = 'label.horse';
    }
    if (value.includes('Slot')) {
      actualGameName = 'label.Eletronic';
    }
    if (value.includes('SportBooks')) {
      actualGameName = 'label.SportsBook';
    }
    if (value.includes('BullFight')) {
      actualGameName = 'label.tournament';
    }
    if (value.includes('Bingo')) {
      actualGameName = 'label.Lottery';
    }

    return actualGameName;
  }
}
