import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';

interface modalConfig {
  timer: number
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(public modalServ: BsModalService) {}

  showAlertMsg(message: string, config?: modalConfig) {
    const objectMsg = { message };
    const modalRef = this.modalServ.show(MessageModalComponent, {
      ignoreBackdropClick: true,
      initialState: objectMsg,
    });

    if (config.timer) {
      setTimeout(() => {
        modalRef.hide()
      }, config.timer)
    }
  }

  showAlertMsgCenter(message: string) {
    const objectMsg = { message };
    this.modalServ.show(MessageModalComponent, {
      ignoreBackdropClick: true,
      initialState: objectMsg,
      class: 'modal-alert-center modal-dialog-centered',
    });
  }
}
